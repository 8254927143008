import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Popconfirm } from "antd";
import { Alert, AlertTitle, IconButton, Snackbar } from "@mui/material";
import {
  getAllCandidates,
  getPositionCandidates,
} from "../../../redux/candidate/candidateRedux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { removeToken } from "../../../redux/admin/adminRedux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import FastForwardIcon from "@mui/icons-material/FastForward";
import DataNotFound from "../../../components/DataNotFound";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Document, Page } from "react-pdf";
import { Grid, TextField, CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

function CandidateDetail() {
  const { id } = useParams();
  const [showRemark, setShowRemark] = useState(false);
  const [remarkLoading, setRemarkLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const { allCandidates, positionCandidates } = useSelector(
    (state) => state.candidate
  );

  const { allQuestions } = useSelector((state) => state.position);
  const bearerToken = localStorage.getItem("token");
  const candidate = useMemo(() => {
    return allCandidates
      ? allCandidates.find((candidate) => candidate.id === id)
        ? allCandidates.find((candidate) => candidate.id === id)
        : null
      : {};
  }, [allCandidates, id]);

  const questions = useMemo(() => {
    return allQuestions
      ? allQuestions.filter(
          (question) =>
            question.position.id ===
            (candidate && candidate.position.id ? candidate.position.id : "")
        )
      : null;
  }, [allQuestions, id, allCandidates, candidate]);

  const date = candidate ? new Date(candidate.createdOn) : new Date();
  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "long",
  });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteResponse, setDeleteResponse] = useState({
    error: "",
    success: false,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleFilter = () => {
    var result;

    if (true) {
    }

    if (
      searchParams.has("candidates") &&
      searchParams.get("candidates") === "all"
    ) {
      result = allCandidates ? allCandidates : [];
    } else if (searchParams.has("stage")) {
      if (searchParams.get("stage") === "all") {
        result = positionCandidates ? positionCandidates : [];
      } else {
        result = positionCandidates
          ? positionCandidates.filter((row) => {
              var stage = searchParams.get("stage");
              const list = ["exam", "interview", "demonstration"];
              if (list.includes(stage)) {
                stage = `passed-for-${stage}`;
              }
              return row.applicationStatus === stage;
            })
          : [];
      }
    }

    if (searchParams.has("age")) {
      result = result.filter((row) => row.age === searchParams.get("age"));
    }

    if (searchParams.has("experience")) {
      result = result.filter(
        (row) => row.yearsOfExperience === searchParams.get("experience")
      );
    }
    if (searchParams.has("education")) {
      result = result.filter(
        (row) => row.educationLevel === searchParams.get("education")
      );
    }

    if (searchParams.has("sex")) {
      result = result.filter((row) => row.sex === searchParams.get("sex"));
    }

    if (searchParams.has("email")) {
      result = result.filter((row) =>
        row.email
          .toLowerCase()
          .includes(searchParams.get("email").toLowerCase())
      );
    }

    return result;
  };
  const collections = useMemo(() => {
    return handleFilter();
  }, [allCandidates, id, positionCandidates, handleFilter]);

  const handleDelete = async () => {
    if (!candidate) {
      return;
    }

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/candidates/${candidate.id}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 200) {
        setDeleteResponse({
          error: "",
          success: true,
        });
        dispatch(getPositionCandidates(candidate.position.id));
        dispatch(getAllCandidates());
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
        setDeleteResponse({
          error: err.response.data.message,
          success: false,
        });
      }
    }
  };

  useEffect(() => {
    candidate &&
      candidate.position &&
      dispatch(getPositionCandidates(candidate.position.id));
  }, [candidate, dispatch, id]);

  useEffect(() => {
    candidate && candidate.remark && setRemark(candidate.remark);
  }, [candidate]);

  const handleNextCandidate = () => {
    const index = collections.findIndex((item) => item.id === id);

    navigate(
      `/admin/candidate/${
        collections[index + 1].id
      }?${searchParams.toString()}`,
      { replace: true }
    );
  };

  const handlePreviousCandidate = () => {
    const index = collections.findIndex((item) => item.id === id);

    navigate(
      `/admin/candidate/${
        collections[index - 1].id
      }?${searchParams.toString()}`,
      { replace: true }
    );
  };

  const handleVisibilityOn = async (id) => {
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/candidates/change-visibility/${id}`,
        { visibility: "visible" },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      dispatch(getPositionCandidates(candidate.position.id));
      dispatch(getAllCandidates());
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      }
    }
  };
  const handleVisibilityOff = async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_URL}/candidates/change-visibility/${id}`,
        { visibility: "potential" },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      dispatch(getPositionCandidates(candidate.position.id));
      dispatch(getAllCandidates());
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      }
    }
  };

  const saveRemark = async () => {
    setRemarkLoading(true);
    try {
      await axios.patch(
        `${process.env.REACT_APP_URL}/candidates/update-remark/${candidate.id}`,
        { remark: remark.trim() },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      dispatch(getAllCandidates());
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      }
    }
    setShowRemark(false);
    setRemarkLoading(false);
  };

  return (
    <div>
      <Helmet>
        <title>Details</title>
      </Helmet>
      <>
        {deleteResponse.success && (
          <div
            style={{
              marginTop: "130px",

              paddingTop: "60px",
              paddingBottom: "60px",
              minWidth: "280px",
              height: "400px",
              gap: "20px",
            }}
            className=" d-flex flex-column align-items-center justify-content-center"
          >
            <Alert
              severity="success"
              style={{
                fontSize: "16px",
              }}
              className="d-flex justify-content-center p-2"
            >
              <section>
                <AlertTitle>Success</AlertTitle>
                You have successfully deleted
              </section>
              <section className="d-flex justify-content-center mt-4">
                <button
                  className="rounded pointer back-btn"
                  onClick={() => {
                    var path = localStorage.getItem("path");
                    if (path === "candidatelist") {
                      path = "/admin/candidatelist";
                    }
                    navigate(-1);
                  }}
                >
                  Back
                </button>
              </section>
            </Alert>
          </div>
        )}
        {candidate === null && !deleteResponse.success && (
          <>
            <DataNotFound path={localStorage.getItem("path")} />
          </>
        )}
        {candidate !== null && !deleteResponse.success && (
          <div
            style={{ marginTop: "64px", marginBottom: "30px", height: "100%" }}
          >
            <div style={{ marginTop: "100px" }} className="container">
              <div
                className="row justify-content-between align-items-end"
                // style={{ backgroundColor: "orange" }}
              >
                <section
                  style={{
                    fontSize: "25px",
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    // backgroundColor: "red",
                  }}
                  onClick={() => handleFilter()}
                  className="d-flex align-items-end p-0"
                >
                  {candidate && candidate.position && candidate.position.title}
                </section>

                <section
                  className="d-flex justify-content-between align-items-center"
                  // style={{ backgroundColor: "red" }}
                >
                  <Tooltip
                    title={`${
                      collections &&
                      (collections.length === 0 ||
                        collections.findIndex((item) => item.id === id) === 0)
                        ? ""
                        : "Previous"
                    }`}
                  >
                    <button
                      onClick={handlePreviousCandidate}
                      style={{
                        backgroundColor: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              0)
                            ? ""
                            : "white"
                        }`,

                        letterSpacing: "1px",
                        fontSize: "18px",
                        width: "120px",
                        border: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              0)
                            ? ""
                            : "1px solid rgb(22, 140, 158)"
                        }`,
                        color: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              0)
                            ? ""
                            : "rgb(22, 140, 158)"
                        }`,
                        cursor: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              0)
                            ? "not-allowed"
                            : "pointer"
                        }`,
                      }}
                      disabled={
                        collections
                          ? collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              0
                            ? true
                            : false
                          : false
                      }
                      className="d-flex py-2 px-4 rounded justify-content-center align-items-center"
                    >
                      <ArrowBackIcon />
                      Previous
                    </button>
                  </Tooltip>
                  <Tooltip
                    title={`${
                      collections &&
                      (collections.length === 0 ||
                        collections.findIndex((item) => item.id === id) ===
                          collections.length - 1)
                        ? ""
                        : "Next"
                    }`}
                  >
                    <button
                      onClick={handleNextCandidate}
                      style={{
                        backgroundColor: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              collections.length - 1)
                            ? ""
                            : "white"
                        }`,

                        letterSpacing: "1px",
                        fontSize: "18px",
                        width: "120px",
                        border: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              collections.length - 1)
                            ? ""
                            : "1px solid rgb(22, 140, 158)"
                        }`,
                        color: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              collections.length - 1)
                            ? ""
                            : "rgb(22, 140, 158)"
                        }`,
                        cursor: `${
                          collections &&
                          (collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              collections.length - 1)
                            ? "not-allowed"
                            : "pointer"
                        }`,
                      }}
                      disabled={
                        collections
                          ? collections.length === 0 ||
                            collections.findIndex((item) => item.id === id) ===
                              collections.length - 1
                            ? true
                            : false
                          : false
                      }
                      className="d-flex py-2 px-4 rounded pointer ml-4 justify-content-center align-items-center"
                    >
                      Next
                      <ArrowForwardIcon />
                    </button>
                  </Tooltip>
                </section>
              </div>

              <div className="row my-5">
                <div
                  className="col-md-12 p-3 rounded"
                  style={{
                    boxShadow: "rgba(1, 12, 14, 0.2) 0px 2px 20px",
                  }}
                >
                  <section
                    style={{
                      fontSize: "24px",
                      fontWeight: "500",
                      fontFamily: "sans-serif",
                    }}
                    className="d-flex justify-content-between"
                  >
                    <section>Candidate Profile</section>
                    <section>
                      {candidate.visibility === "visible" ? (
                        <>
                          Hide
                          <Popconfirm
                            placement="left"
                            title={
                              "Are you sure want to hide the selected candidates?"
                            }
                            onConfirm={() => handleVisibilityOff(candidate.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip title="hide">
                              <IconButton size="large" className="ml-2">
                                <VisibilityOffIcon />
                              </IconButton>
                            </Tooltip>
                          </Popconfirm>
                        </>
                      ) : (
                        <>
                          Show
                          <Popconfirm
                            placement="left"
                            title={
                              "Are you sure want to reverse the selected candidates?"
                            }
                            onConfirm={() => handleVisibilityOn(candidate.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Tooltip title="Visible">
                              <IconButton size="large" className="ml-2">
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          </Popconfirm>
                        </>
                      )}
                    </section>
                  </section>
                  <hr />
                  <div className="row mb-2">
                    <div className="col-sm-5 d-flex align-items-center  ">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Name:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate ? (
                          <>
                            {candidate.firstName} {candidate.lastName}
                          </>
                        ) : null}
                      </section>
                    </div>
                    <div className="col-sm-4   d-flex align-items-center ">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Age:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.age}
                      </section>
                    </div>
                    <div className="col-sm-3   d-flex align-items-center  ">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Sex:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.sex}
                      </section>
                    </div>
                  </div>
                  <div className="row mb-2" style={{ backgroundColor: "" }}>
                    <div className="col-sm-5 d-flex align-items-center">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Email:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.email}
                      </section>
                    </div>
                    <div className="col-6 d-flex align-items-center">
                      <section
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Phone:
                      </section>
                      <section
                        style={{ fontSize: "20px" }}
                        className="d-flex align-items-center pt-1"
                      >
                        {candidate && candidate.phoneNumber}
                      </section>
                    </div>
                  </div>
                  <div className="row mb-2" style={{ backgroundColor: "" }}>
                    <div className="col-sm-5 d-flex align-items-center ">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Living:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.address}
                      </section>
                    </div>
                    <div className="col-sm-4 d-flex align-items-center ">
                      <section
                        style={{
                          fontSize: "20px",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Education:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.educationLevel}
                      </section>
                    </div>
                    <div className="col-sm-3 d-flex align-items-center ">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Experience:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.yearsOfExperience}
                      </section>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-sm-5 d-flex align-items-center">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Application Date:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {formattedDate}
                      </section>
                    </div>
                    <div className=" col-sm-7 d-flex align-items-center">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        Status:
                      </section>
                      <section
                        style={{ fontSize: "19px" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.applicationStatus}
                      </section>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-5 d-flex align-items-center">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mr-2"
                      >
                        CV:
                      </section>
                      <section
                        style={{ fontSize: "19px", fontFamily: "sans-serif" }}
                        className="d-flex align-items-center"
                      >
                        {candidate && (
                          <>
                            <a
                              href={`${candidate.cv}`}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Here
                            </a>
                          </>
                        )}
                      </section>
                    </div>
                    {candidate &&
                      candidate.otherRelevantDocuments &&
                      candidate.otherRelevantDocuments[0] && (
                        <div className="col-sm-7 d-flex align-items-center">
                          <section
                            style={{
                              fontSize: "20px",
                              // fontWeight: "500",
                              fontFamily: "sans-serif",
                            }}
                            className="d-flex align-items-center mr-2"
                          >
                            Related file:
                          </section>
                          <section
                            style={{
                              fontSize: "19px",
                              fontFamily: "sans-serif",
                            }}
                            className="d-flex align-items-center"
                          >
                            {candidate && (
                              <>
                                <a
                                  href={`${candidate.otherRelevantDocuments[0]}`}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Here
                                </a>
                              </>
                            )}
                          </section>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row my-5">
                <div
                  className="col-md-12 p-3 rounded"
                  style={{
                    boxShadow: "rgba(1, 12, 14, 0.2) 0px 2px 20px",
                  }}
                >
                  <section
                    style={{
                      fontSize: "24px",
                      fontWeight: "500",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Questions
                  </section>
                  <hr />
                  <div className="row">
                    <div className="col mb-5">
                      <section
                        style={{
                          fontSize: "20px",
                          // fontWeight: "500",
                          fontWeight: "600",

                          color: "#007bff",

                          fontFamily: "sans-serif",
                        }}
                        className="d-flex align-items-center mb-2"
                      >
                        Why should we consider you for this job?
                      </section>
                      <section
                        style={{
                          fontSize: "19px",
                          color: "black",
                          textAlign: "justify",
                          lineHeight: "1.6",
                        }}
                        className="d-flex align-items-center"
                      >
                        {candidate && candidate.applicationDescription}
                      </section>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col mb-2">
                      {candidate &&
                        questions &&
                        questions.map((question, index) => {
                          return (
                            <div key={question.id}>
                              <section
                                style={{
                                  fontSize: "20px",
                                  color: "#007bff",
                                  fontWeight: "600",

                                  fontFamily: "sans-serif",
                                }}
                                className="d-flex align-items-center mb-2"
                              >
                                {question.question}
                              </section>
                              <section
                                style={{
                                  fontSize: "19px",
                                  color: "black",
                                  textAlign: "justify",
                                  lineHeight: "1.6",

                                  // fontFamily: "sans-serif",
                                }}
                                className="d-flex align-items-center text-justify mb-5"
                              >
                                {candidate.answers.find(
                                  (answer) => answer.questionId === question.id
                                ) ? (
                                  candidate.answers.find(
                                    (answer) =>
                                      answer.questionId === question.id
                                  ).answer
                                ) : (
                                  <span className="font-weight-bold">
                                    No Answer Found
                                  </span>
                                )}
                              </section>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row my-5">
                <div
                  className="col-md-12 p-3 rounded"
                  style={{
                    boxShadow: "rgba(1, 12, 14, 0.2) 0px 2px 20px",
                  }}
                >
                  <section
                    style={{
                      fontSize: "24px",
                      fontWeight: "500",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Remark
                  </section>
                  <hr />
                  <div className="row">
                    <div className="col " style={{ minHeight: "210px" }}>
                      {candidate && !remark && !showRemark && (
                        <div
                          className="d-flex justify-content-center align-items-center h-100"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowRemark(true)}
                        >
                          <h4>Write your remark here.</h4>
                        </div>
                      )}
                      {!showRemark && remark && (
                        <div
                          onClick={() => setShowRemark(true)}
                          style={{
                            fontSize: "19px",
                            color: "black",
                            textAlign: "justify",
                            lineHeight: "1.6",
                            cursor: "pointer",
                            height: "100%",
                          }}
                        >
                          {remark}
                        </div>
                      )}
                      {showRemark && (
                        <div style={{ width: "100%" }}>
                          <TextField
                            label="Remark"
                            multiline
                            rows={10}
                            variant="outlined"
                            fullWidth
                            required
                            // error={error.applicationDescription}
                            onChange={(e) => setRemark(e.target.value)}
                            name="remark"
                            inputProps={{ maxLength: 1500 }}
                            sx={{
                              "& textarea": {
                                backgroundColor: "#f8f9fa",

                                borderRadius: "4px",
                                padding: "8px",
                              },
                            }}
                            value={remark}
                          />
                          <section
                            style={{ gap: "20px" }}
                            className="d-flex justify-content-end my-3"
                          >
                            {remarkLoading && <CircularProgress />}
                            {!remarkLoading && (
                              <>
                                <div
                                  className="py-2 px-4 rounded"
                                  style={{
                                    background: "rgb(179, 12, 12)",
                                    color: "white",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setShowRemark(false);
                                    setRemark(candidate.remark);
                                  }}
                                >
                                  Cancel
                                </div>
                                <div
                                  className="py-2 px-4 rounded"
                                  style={{
                                    background: "green",
                                    color: "white",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={saveRemark}
                                >
                                  Save
                                </div>
                              </>
                            )}
                          </section>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-4">
                <div className="col">
                  <section className="d-flex align-items-center justify-content-start">
                    <Tooltip title="Back">
                      <button
                        style={{
                          border: "1px solid rgb(22, 140, 158)",
                          color: "rgb(22, 140, 158)",

                          backgroundColor: "white",
                          letterSpacing: "1px",
                          fontSize: "18px",
                          width: "120px",
                        }}
                        onClick={() => {
                          navigate(-1);
                        }}
                        className="rounded text-center py-2 px-4 d-flex justify-content-center align-items-center pointer mr-4"
                      >
                        Back
                      </button>
                    </Tooltip>
                    <Popconfirm
                      title={`Are you sure want to reject candidate ${
                        candidate &&
                        `(${candidate.firstName} ${candidate.lastName})`
                      }?`}
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleDelete()}
                    >
                      <Tooltip title="Reject">
                        <button
                          style={{
                            border: `1px solid ${
                              candidate.rejectionStatus
                                ? "#d3d3d3"
                                : "rgb(179, 12, 12)"
                            }`,
                            backgroundColor: candidate.rejectionStatus
                              ? "#f5f5f5"
                              : "white",
                            color: candidate.rejectionStatus
                              ? "#d3d3d3"
                              : "rgb(179, 12, 12)",
                            fontSize: "18px",
                            width: "120px",
                            letterSpacing: "1px",
                            cursor: candidate.rejectionStatus
                              ? "not-allowed"
                              : "pointer",
                          }}
                          className="rounded py-2 px-4 text-center d-flex justify-content-center align-items-center"
                          disabled={candidate.rejectionStatus}
                        >
                          {candidate.rejectionStatus ? "Rejected" : "Reject"}
                        </button>
                      </Tooltip>
                    </Popconfirm>
                  </section>
                </div>
                <div className="col">
                  <section className="d-flex align-items-center justify-content-end">
                    {candidate && (
                      <AlertRollBackStage
                        candidate={candidate}
                        position={candidate.position}
                        dispatch={dispatch}
                        searchParams={searchParams}
                        collections={collections}
                        bearerToken={bearerToken}
                      />
                    )}

                    {candidate && (
                      <AlertNextStage
                        candidate={candidate}
                        dispatch={dispatch}
                        position={candidate.position}
                        searchParams={searchParams}
                        collections={collections}
                        bearerToken={bearerToken}
                      />
                    )}
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
}

export default CandidateDetail;

const NextStageTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertNextStage({
  candidate,
  dispatch,
  position,
  collections,
  searchParams,
  bearerToken,
}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const { allPositions } = useSelector((state) => state.position);
  const fullPosition = useMemo(() => {
    return allPositions && position
      ? allPositions.find((p) => p.id === position.id)
      : {};
  }, [allPositions, position]);

  const [response, setResponse] = useState({ completed: false, error: "" });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setResponse({ completed: false, error: "" });
    setOpen(false);
  };
  const handleAgree = async () => {
    if (!candidate) {
      setResponse({ completed: true, error: "Error ocurred" });
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/positions/keep-candidate`,
        { positionId: position.id, candidateIds: [candidate.id] },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 201) {
        setResponse({ completed: true, error: "" });
        dispatch(getAllCandidates());
        dispatch(getPositionCandidates(position.id));

        if (!searchParams.has("candidates")) {
          const index = collections.findIndex(
            (item) => item.id === candidate.id
          );

          if (index === collections.length - 1) {
            if (collections.length > 1) {
              navigate(
                `/admin/candidate/${
                  collections[index - 1].id
                }?${searchParams.toString()}`
              );
            } else {
              navigate(
                `/admin/candidate/${
                  collections[index].id
                }?${searchParams.toString()}`
              );
            }
          } else {
            navigate(
              `/admin/candidate/${
                collections[index + 1].id
              }?${searchParams.toString()}`
            );
          }
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
        // setResponse({ completed: true, error: err.response.data.message });
      }
    }
  };

  return (
    <div>
      <Tooltip
        title={`${
          candidate.applicationStatus !== "onboarding" ? "Next Stage" : ""
        }`}
      >
        <button
          style={{
            border: `${
              candidate.applicationStatus === "onboarding"
                ? ""
                : "1px solid rgb(22, 140, 158)"
            }`,

            color: `${
              candidate.applicationStatus === "onboarding"
                ? ""
                : "rgb(22, 140, 158)"
            }`,
            fontSize: "18px",
            backgroundColor: `${
              candidate.applicationStatus === "onboarding" ? "" : "white"
            }`,
            cursor: `${
              candidate.applicationStatus === "onboarding"
                ? "not-allowed"
                : "pointer"
            }`,
            letterSpacing: "1px",
          }}
          disabled={candidate.applicationStatus === "onboarding"}
          onClick={handleClickOpen}
          className="px-4 py-2 rounded pointer"
        >
          {candidate.applicationStatus === "just-applied" && (
            <span className="d-flex align-items-center">
              Exam <FastForwardIcon />
            </span>
          )}
          {candidate.applicationStatus === "passed-for-exam" && (
            <span className="d-flex align-items-center">
              Interview <FastForwardIcon />
            </span>
          )}

          {candidate.applicationStatus === "passed-for-interview" &&
            fullPosition.skipDemo && (
              <span className="d-flex align-items-center">
                Onboarding <FastForwardIcon />
              </span>
            )}
          {candidate.applicationStatus === "passed-for-interview" &&
            !fullPosition.skipDemo && (
              <span className="d-flex align-items-center">
                Demonstration <FastForwardIcon />
              </span>
            )}
          {candidate.applicationStatus === "passed-for-demonstration" && (
            <span className="d-flex align-items-center">
              Onboarding <FastForwardIcon />
            </span>
          )}
          {candidate.applicationStatus === "onboarding" && (
            <span className="d-flex align-items-center">
              Next Stage <FastForwardIcon />
            </span>
          )}
        </button>
      </Tooltip>

      {response.completed && (
        <>
          <Snackbar
            open={response.completed}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={response.error ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {response.error ? (
                <span style={{ fontFamily: "sans-serif" }}>
                  Failed to change Stage. Try again!
                </span>
              ) : (
                <span style={{ fontFamily: "sans-serif" }}>
                  Stage successfully changed
                </span>
              )}
            </Alert>
          </Snackbar>
        </>
      )}
      {!response.completed && (
        <Dialog
          open={open}
          TransitionComponent={NextStageTransition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <>
            <DialogTitle>
              <span className="mr-1 font-weight-bold">Candidate</span>
              {candidate && (
                <span>
                  ({candidate.firstName} {candidate.lastName})
                </span>
              )}
              <span className="ml-1 font-weight-bold">
                is going to next stage!
              </span>
            </DialogTitle>

            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleAgree}>Agree</Button>
            </DialogActions>
          </>
        </Dialog>
      )}
    </div>
  );
}

const RollBackTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AlertRollBackStage({
  candidate,
  dispatch,
  position,
  searchParams,
  collections,
  bearerToken,
}) {
  const { allPositions } = useSelector((state) => state.position);
  const navigate = useNavigate();
  const fullPosition = useMemo(() => {
    return allPositions && position
      ? allPositions.find((p) => p.id === position.id)
      : {};
  }, [allPositions, position]);

  const [open, setOpen] = React.useState(false);

  const [response, setResponse] = useState({ completed: false, error: "" });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setResponse({ completed: false, error: "" });
    setOpen(false);
  };
  const handleAgree = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/positions/rollback-candidate`,
        { positionId: position.id, candidateIds: [candidate.id] },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 201) {
        setResponse({ completed: true, error: "" });

        dispatch(getAllCandidates());
        dispatch(getPositionCandidates(position.id));

        if (!searchParams.has("candidates")) {
          const index = collections.findIndex(
            (item) => item.id === candidate.id
          );

          if (index === 0) {
            if (collections.length > 1) {
              navigate(
                `/admin/candidate/${
                  collections[1].id
                }?${searchParams.toString()}`
              );
            } else {
              navigate(
                `/admin/candidate/${
                  collections[0].id
                }?${searchParams.toString()}`
              );
            }
          } else {
            navigate(
              `/admin/candidate/${
                collections[index - 1].id
              }?${searchParams.toString()}`
            );
          }
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
        // setResponse({ completed: true, error: err.response.data.message });
      }
    }
  };

  return (
    <div className="mr-3">
      <Tooltip
        title={`${
          candidate.applicationStatus !== "just-applied" ? "Roll Back" : ""
        }`}
      >
        <button
          style={{
            border: `${
              candidate.applicationStatus === "just-applied"
                ? ""
                : "1px solid rgb(179, 12, 12)"
            }`,

            backgroundColor: `${
              candidate.applicationStatus === "just-applied" ? "" : "white"
            }`,
            color: `${
              candidate.applicationStatus === "just-applied"
                ? ""
                : "rgb(179, 12, 12)"
            }`,
            cursor: `${
              candidate.applicationStatus === "just-applied"
                ? "not-allowed"
                : "pointer"
            }`,

            fontSize: "18px",
            letterSpacing: "1px",
          }}
          disabled={candidate.applicationStatus === "just-applied"}
          onClick={handleClickOpen}
          className="px-4 py-2 rounded"
        >
          {candidate.applicationStatus === "just-applied" && (
            <span className="d-flex align-items-center">
              <FastRewindIcon /> Roll Back
            </span>
          )}
          {candidate.applicationStatus === "passed-for-exam" && (
            <span className="d-flex align-items-center">
              <FastRewindIcon /> Applied
            </span>
          )}
          {candidate.applicationStatus === "passed-for-interview" && (
            <span className="d-flex align-items-center">
              <FastRewindIcon /> Exam
            </span>
          )}
          {candidate.applicationStatus === "onboarding" &&
            fullPosition.skipDemo && (
              <span className="d-flex align-items-center">
                <FastRewindIcon /> Interview
              </span>
            )}
          {candidate.applicationStatus === "onboarding" &&
            !fullPosition.skipDemo && (
              <span className="d-flex align-items-center">
                <FastRewindIcon /> Demonstration
              </span>
            )}
          {candidate.applicationStatus === "passed-for-demonstration" && (
            <span className="d-flex align-items-center">
              <FastRewindIcon /> Interview
            </span>
          )}
        </button>
      </Tooltip>
      {response.completed && (
        <>
          <Snackbar
            open={response.completed}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={response.error ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {response.error ? (
                <span style={{ fontFamily: "sans-serif" }}>
                  Failed to change Stage. Try again!
                </span>
              ) : (
                <span style={{ fontFamily: "sans-serif" }}>
                  Stage successfully changed
                </span>
              )}
            </Alert>
          </Snackbar>
        </>
      )}

      {response.completed && (
        <>
          <Snackbar
            open={response.completed}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={response.error ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {response.error ? (
                <span style={{ fontFamily: "sans-serif" }}>
                  Failed to change Stage. Try again!
                </span>
              ) : (
                <span style={{ fontFamily: "sans-serif" }}>
                  Stage successfully changed
                </span>
              )}
            </Alert>
          </Snackbar>
        </>
      )}
      {!response.completed && (
        <>
          <Dialog
            open={open}
            TransitionComponent={RollBackTransition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>
              <span className="mr-1 font-weight-bold">Candidate</span>
              {candidate && (
                <span>
                  ({candidate.firstName} {candidate.lastName})
                </span>
              )}
              <span className="ml-1 font-weight-bold">
                is going to be reverted back to previous stage!
              </span>
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleAgree}>Agree</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
}
