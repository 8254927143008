import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { NavLink } from "react-router-dom";

function Stages({ position, searchParams }) {
  return (
    <>
      <section
        style={{
          marginTop: "100px",
          marginBottom: "10px",
        }}
        className="container"
      >
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <Tooltip title="All">
              <NavLink
                to={`.?${searchParams.toString()}`}
                end
                style={({ isActive }) => ({
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderRadius: "10px",
                  padding: "4px 10px",
                  textDecoration: "none",
                  border: isActive ? "" : "0.2px solid #168C9E",
                  color: isActive ? "white" : "#168C9E",
                  backgroundColor: isActive ? "#168C9E" : "white",
                })}
              >
                All
              </NavLink>
            </Tooltip>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <Tooltip title="Just Applied">
              <NavLink
                to={`just-applied?${searchParams.toString()}`}
                style={({ isActive }) => ({
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderRadius: "10px",
                  padding: "4px 10px",
                  textDecoration: "none",
                  border: isActive ? "" : "1px solid #168C9E",
                  color: isActive ? "white" : "#168C9E",
                  backgroundColor: isActive ? "#168C9E" : "white",
                })}
              >
                Applied
              </NavLink>
            </Tooltip>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <Tooltip title="Exam">
              <NavLink
                to={`exam?${searchParams.toString()}`}
                style={({ isActive }) => ({
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderRadius: "10px",
                  padding: "4px 10px",
                  textDecoration: "none",
                  border: isActive ? "" : "1px solid #168C9E",
                  color: isActive ? "white" : "#168C9E",
                  backgroundColor: isActive ? "#168C9E" : "white",
                })}
              >
                Exam
              </NavLink>
            </Tooltip>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <Tooltip title="Interview">
              <NavLink
                to={`interview?${searchParams.toString()}`}
                style={({ isActive }) => ({
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderRadius: "10px",
                  padding: "4px 10px",
                  textDecoration: "none",
                  border: isActive ? "" : "1px solid #168C9E",
                  color: isActive ? "white" : "#168C9E",
                  backgroundColor: isActive ? "#168C9E" : "white",
                })}
              >
                Interview
              </NavLink>
            </Tooltip>
          </div>

          {!position.skipDemo && (
            <div className="col d-flex align-items-center justify-content-center">
              <Tooltip title="Demonstration">
                <NavLink
                  to={`demonstration?${searchParams.toString()}`}
                  style={({ isActive }) => ({
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    borderRadius: "10px",
                    padding: "4px 10px",
                    textDecoration: "none",
                    border: isActive ? "" : "1px solid #168C9E",
                    color: isActive ? "white" : "#168C9E",
                    backgroundColor: isActive ? "#168C9E" : "white",
                  })}
                >
                  Demonstration
                </NavLink>
              </Tooltip>
            </div>
          )}
          <div className="col d-flex align-items-center justify-content-center">
            <Tooltip title="Onboarding">
              <NavLink
                to={`onboarding?${searchParams.toString()}`}
                style={({ isActive }) => ({
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderRadius: "10px",
                  padding: "4px 10px",
                  textDecoration: "none",
                  border: isActive ? "" : "1px solid #168C9E",
                  color: isActive ? "white" : "#168C9E",
                  backgroundColor: isActive ? "#168C9E" : "white",
                })}
              >
                Onboarding
              </NavLink>
            </Tooltip>
          </div>
          <div className="col d-flex align-items-center justify-content-center">
            <Tooltip title="Hidden">
              <NavLink
                to={`potential?${searchParams.toString()}`}
                end
                style={({ isActive }) => ({
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bold",
                  letterSpacing: "1px",
                  borderRadius: "10px",
                  padding: "4px 10px",
                  textDecoration: "none",
                  border: isActive ? "" : "0.2px solid #168C9E",
                  color: isActive ? "white" : "#168C9E",
                  backgroundColor: isActive ? "#168C9E" : "white",
                })}
              >
                Hidden
              </NavLink>
            </Tooltip>
          </div>
        </div>
      </section>
    </>
  );
}

export default Stages;
