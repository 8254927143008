import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import nodata from "../../../../src/assets/nodata.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdminPositions } from "../../../redux/position/positionRedux";
import { getAllCandidates } from "../../../redux/candidate/candidateRedux";
import { Popconfirm } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import {
  getAdmins,
  removeToken,
  setAdminList,
} from "../../../redux/admin/adminRedux";
import { notification } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import { returnPermissions } from "./HRjobDetail";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export function CustomizedDialogs({ imgUrl, position }) {
  const bearerToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [api, contextHolder] = notification.useNotification();

  const handleClickOpen = () => {
    setOpen(true);
    setFile(null);
    setError(null);
    setEdit(false);
    setHover(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImage = (e) => {
    const image = e.target.files[0];
    const fileType = ["image/png", "image/jpeg"];

    if (image) {
      if (!fileType.includes(image.type)) {
        setError("Only png and jpeg images are allowed");
        setFile(null);
      } else {
        setFile(image);
        setError(null);
      }
    } else {
      setFile(null);
      setError("This is a required filed");
    }
  };

  const handleImageUpdate = async () => {
    const formData = new FormData();
    formData.append("avatar", file);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}/positions/change-avatar/${position.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 200) {
        setOpen(false);
        setFile(null);
        setError(null);
        setEdit(false);
        setHover(false);
        api.open({
          message: "iCog-ACC",
          description: `Position image for ${position.title} is successfully changed.`,
          icon: (
            <CheckCircleIcon
              style={{
                color: "#168c9e",
              }}
            />
          ),
          placement: "bottomRight",
        });
        dispatch(getAllAdminPositions());
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      }
    }
  };

  return (
    <div>
      <section
        style={{ height: "260px" }}
        className="mt-2 pointer"
        onClick={handleClickOpen}
      >
        <img
          src={`${process.env.REACT_APP_URL}/candidates/documents?query=${imgUrl}`}
          alt=""
          className="hr-black-white"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
        {contextHolder}
      </section>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {!edit && (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              {position.title}
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <div style={{ width: "500px" }}>
                <div className="d-flex justify-content-center ">
                  <section
                    style={{
                      width: "220px",
                      height: "220px",
                    }}
                    className="img-container my-4"
                  >
                    <img
                      src={`${process.env.REACT_APP_URL}/candidates/documents?query=${imgUrl}`}
                      alt={imgUrl}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </section>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div
                className="d-flex flex-column align-items-center justify-content-center pointer h-100 p-2 rounded"
                onClick={() => {
                  setHover(false);
                  setEdit(true);
                  // handleClose();
                }}
                style={{
                  backgroundColor: `${hover ? "rgba(1, 12, 14, 0.2)" : ""}`,
                  fontSize: "20px",
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <FlipCameraIosIcon style={{ fontSize: "25px" }} />
              </div>
            </DialogActions>
          </>
        )}
        {edit && (
          <>
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={() => {
                setFile(null);
                setEdit(false);
                setError(null);
              }}
            >
              Update Image
            </BootstrapDialogTitle>
            <DialogContent dividers>
              <div style={{ width: "500px" }}>
                {!file && (
                  <section
                    className="d-flex flex-column align-items-center py-5"
                    // style={{ backgroundColor: "red" }}
                  >
                    <input
                      type="file"
                      name="imageFile"
                      id="imageFile"
                      style={{ display: "none" }}
                      onChange={handleImage}
                    />
                    <label
                      htmlFor="imageFile"
                      // style={{ border: `${uploadError.image && "1px solid red"}` }}
                      style={{
                        width: "220px",
                        height: "220px",
                        border: "1px dashed black",
                      }}
                      className="d-flex  justify-content-center align-items-center  pointer image-upload"
                    >
                      <AddIcon style={{ fontSize: "25px" }} />
                      <span style={{ color: "black", fontSize: "25px" }}>
                        Image
                      </span>
                    </label>
                    <section style={{ height: "30px", color: "red" }}>
                      {error}
                    </section>
                  </section>
                )}

                {file && (
                  <section className="d-flex flex-column align-items-center py-5">
                    <img
                      style={{ width: "220px", height: "220px" }}
                      className="pointer"
                      src={URL.createObjectURL(file)}
                      alt=""
                      onDoubleClick={() => setFile(null)}
                    />
                  </section>
                )}
              </div>
            </DialogContent>
            {file && (
              <DialogActions>
                <div
                  className="d-flex flex-column align-items-center justify-content-center pointer p-2 rounded"
                  onClick={handleImageUpdate}
                  style={{
                    backgroundColor: `${hover ? "rgba(1, 12, 14, 0.2)" : ""}`,
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                    // height: "45px",
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  Save
                </div>
              </DialogActions>
            )}
          </>
        )}
      </BootstrapDialog>
    </div>
  );
}

function HRJobs() {
  return (
    <>
      <Album />
    </>
  );
}

export default HRJobs;

const defaultTheme = createTheme();

function Album() {
  const { adminJobs, adminDetail } = useSelector((state) => state.position);

  let jobs = adminJobs;

  const [title, setTitle] = useState("");
  const [api, contextHolder] = notification.useNotification();

  localStorage.setItem("path", "/admin");

  const handleSearch = () => {
    const filteredJobs = jobs.filter((job) => {
      if (title) {
        if (job.title.toLowerCase().includes(title.toLowerCase())) {
          return job;
        }
        return null;
      } else {
        return job;
      }
    });

    return filteredJobs;
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bearerToken = localStorage.getItem("token");
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/positions/${id}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        dispatch(getAllAdminPositions());
        dispatch(getAllCandidates());
        dispatch(getAdmins());

        api.open({
          message: "iCog-ACC",
          description: `Position successfully deleted.`,
          icon: (
            <CheckCircleIcon
              style={{
                color: "#168c9e",
              }}
            />
          ),
          placement: "bottomRight",
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
        api.open({
          message: "iCog-ACC",
          description: `Unable to delete. try again!`,
          icon: (
            <ErrorIcon
              style={{
                color: "rgb(179, 12, 12)",
              }}
            />
          ),
          placement: "bottomRight",
        });
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Admin | Jobs</title>
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <main className="mt-4">
            <Container sx={{ py: 8 }} maxWidth="lg">
              <Box
                sx={{ "& > :not(style)": { m: 1 } }}
                className="d-flex justify-content-between mb-2"
              >
                {contextHolder}

                {adminDetail &&
                  (adminDetail.superAdmin ||
                    adminDetail.permissions.length !== 0) && (
                    <section>
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          // width: 350,
                        }}
                      >
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          size="large"
                        >
                          <SearchIcon />
                        </IconButton>
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Job Title"
                          inputProps={{ "aria-label": "Job Title" }}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </Paper>
                      <span>
                        {jobs && (
                          <span
                            className="font-weight-bold mr-1"
                            style={{ fontFamily: "sans-serif" }}
                          >
                            {handleSearch().length}
                          </span>
                        )}
                        <span style={{ fontFamily: "sans-serif" }}>found</span>
                      </span>
                    </section>
                  )}
                {adminDetail?.canPost && (
                  <Link to="/admin/posting">
                    <Fab
                      style={{
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#168c9e",
                        color: "white",
                      }}
                      aria-label="add"
                    >
                      <AddIcon />
                    </Fab>
                  </Link>
                )}
              </Box>

              {adminDetail &&
                (adminDetail.superAdmin ||
                  adminDetail.permissions.length !== 0) &&
                jobs &&
                handleSearch().length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",

                      alignItems: "center",
                      height: "600px",
                      backgroundColor: "white",
                    }}
                  >
                    <section style={{ width: "400px", height: "300px" }}>
                      <img
                        src={nodata}
                        style={{ width: "100%", height: "100%" }}
                        alt=""
                      />
                    </section>
                  </div>
                )}
              {adminDetail &&
                !adminDetail.superAdmin &&
                adminDetail.permissions.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",

                      alignItems: "center",
                      height: "600px",
                      backgroundColor: "white",
                    }}
                  >
                    <section
                      style={{
                        width: "400px",
                        height: "300px",
                        fontSize: "34px",
                        marginTop: "70px",
                      }}
                    >
                      No permissions Found.
                    </section>
                  </div>
                )}
              <Grid container spacing={2}>
                {jobs &&
                  handleSearch().map((job) => {
                    const index = job.title
                      .toLowerCase()
                      .indexOf(title.toLocaleLowerCase());

                    return (
                      <Grid
                        item
                        key={job.id}
                        xs={12}
                        sm={6}
                        md={4}
                        className="hr-position"
                      >
                        <Card
                          sx={{
                            height: "100%",

                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div>
                            <CustomizedDialogs
                              imgUrl={job.avatar}
                              position={job}
                            />
                          </div>
                          <CardContent sx={{ flexGrow: 1 }}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                              component="h2"
                            >
                              {index !== -1 && title.trim() ? (
                                <span>
                                  {job.title.slice(0, index)}
                                  <span
                                    style={{
                                      backgroundColor: "yellow",
                                      fontWeight: "bold",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {title}
                                  </span>
                                  {job.title.slice(index + title.length)}
                                </span>
                              ) : (
                                <span>{job.title}</span>
                              )}
                            </Typography>
                            <Typography
                              className="text-center px-3"
                              style={{
                                fontSize: "15px",

                                fontFamily: "Raleway, sans-serif",
                                lineHeight: "1.4",

                                color: "#333333",
                              }}
                            >
                              {job.overview}
                            </Typography>
                          </CardContent>
                          <CardActions className="d-flex justify-content-between align-items-center">
                            <section>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  marginRight: "19px",
                                  color: "#168c9e",
                                }}
                                to={`jobs/${job.id}`}
                              >
                                Detail
                              </Link>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  // marginRight: "10px",
                                  color: "#168c9e",
                                }}
                                to={`jobcandidates/${job.id}`}
                              >
                                Candidates{" "}
                                <span style={{ color: "black" }}>
                                  ({job.candidates.length})
                                </span>
                              </Link>
                            </section>

                            {adminDetail &&
                              returnPermissions(adminDetail, job.id).remove && (
                                <Popconfirm
                                  // placement="top"
                                  title={
                                    "Are you sure want to delete this position?"
                                  }
                                  onConfirm={() => handleDelete(job.id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <IconButton size="large">
                                    <DeleteIcon
                                      style={{ color: "rgb(179, 12, 12)" }}
                                    />
                                  </IconButton>
                                </Popconfirm>
                              )}
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
                {!jobs && (
                  <div
                    style={{
                      height: "300px",
                      width: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div className="d-flex flex-column align-items-center">
                      <CircularProgress />
                      <section className="mt-3" style={{ fontSize: "20px" }}>
                        Loading jobs...
                      </section>
                    </div>
                  </div>
                )}
              </Grid>
            </Container>
          </main>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
