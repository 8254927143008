import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import candidateSlice from "./redux/candidate/candidateRedux";
import positionSlice, { getAdminDetail } from "./redux/position/positionRedux";
import adminSlice from "./redux/admin/adminRedux";
import {
  applyPositionSaga,
  createPositionSaga,
  getAdminDetailSaga,
  getAllAdminPositionsSaga,
  getAllAdminsSaga,
  getAllCandidatesSaga,
  getAllPositionsSaga,
  getAllQuestionsSaga,
  getPositionCandidatesSaga,
  getPositionSaga,
} from "./saga/saga";

const saga = createSagaMiddleware();
const store = configureStore({
  reducer: {
    candidate: candidateSlice.reducer,
    position: positionSlice.reducer,
    admin: adminSlice.reducer,
  },
  middleware: [saga],
});

saga.run(getAllCandidatesSaga);
saga.run(getPositionCandidatesSaga);
saga.run(getAllPositionsSaga);
saga.run(getPositionSaga);
saga.run(createPositionSaga);
saga.run(applyPositionSaga);
saga.run(getAllQuestionsSaga);
saga.run(getAllAdminsSaga);
saga.run(getAllAdminPositionsSaga);
saga.run(getAdminDetailSaga);

export default store;
