import { TextField } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";

function HRbenefits() {
  const { data, setData, checked } = useOutletContext();

  return (
    <>
      <>
        {checked ? (
          <div style={{ marginTop: "30px" }}>
            <TextField
              value={data.benefit}
              onChange={(e) =>
                setData((prev) => {
                  return { ...prev, benefit: e.target.value };
                })
              }
              disabled={data.benefits ? data.benefits.length > 1500 : false}
              onKeyDown={(e) => {
                if (!e.target.value.trim()) {
                  return;
                }
                if (e.key === "Enter") {
                  const benefits = data.benefits.split("||");

                  const newBenefits = [e.target.value, ...benefits];

                  setData((prev) => {
                    return {
                      ...prev,
                      benefits: newBenefits.join("||"),
                      benefit: "",
                    };
                  });
                }
              }}
              inputProps={{ maxLength: 1500 }}
              label="Benefits"
              placeholder="Position Benefits"
              variant="outlined"
              fullWidth
              required
              sx={{
                "& textarea": {
                  borderRadius: "10px",
                  padding: "8px",
                },
              }}
            />

            <div
              className="h-auto p-4"
              style={{
                borderRadius: "10px",

                textAlign: "justify",
              }}
            >
              <ul className="px-4">
                {data.benefits &&
                  data.benefits.trim() &&
                  data.benefits.split("||").map((benefit, index) => {
                    if (benefit.trim()) {
                      return (
                        <li
                          key={index}
                          className="mb-3   pointer"
                          onDoubleClick={() => {
                            var currentBenefits = data.benefits.split("||");

                            currentBenefits = currentBenefits.filter(
                              (item) => item !== benefit
                            );

                            setData((prev) => {
                              return {
                                ...prev,
                                benefits: currentBenefits.join("||"),
                              };
                            });
                          }}
                          style={{
                            userSelect: "none",

                            fontWeight: "500",
                            color: "black",
                            fontSize: "18px",
                          }}
                        >
                          <section className="ml-1">{benefit}</section>
                        </li>
                      );
                    }
                    return null;
                  })}
              </ul>
            </div>
            {!data.benefits && (
              <div className="d-flex flex-column  align-items-center">
                <div
                  style={{ fontSize: "20px", color: "black" }}
                  className="mb-4 mt-4"
                >
                  No available Benefits Found.
                </div>
                <div>
                  To add benefits, write on the input provided above and press
                  Enter key.
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {data.benefits && data.benefits.trim() && (
              <>
                <div
                  className="h-auto  measures"
                  style={{
                    borderRadius: "10px",

                    textAlign: "justify",
                  }}
                >
                  <ul className="px-4">
                    {data.benefits.split("||").map((benefit, index) => {
                      if (benefit.trim()) {
                        return (
                          <li
                            key={index}
                            className="mb-3 mt-3  pointer"
                            style={{
                              userSelect: "none",
                              fontWeight: "500",
                              color: "black",
                              fontSize: "18px",
                            }}
                          >
                            <section className="ml-1">{benefit}</section>
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                </div>
              </>
            )}
            {!data.benefits && (
              <div className="d-flex flex-column  align-items-center">
                <div
                  style={{ fontSize: "20px", color: "black" }}
                  className="mb-4 mt-5"
                >
                  No available Benefits Found.
                </div>
                <div>
                  To add benefits, click the edit switch on the top right corner
                  of the screen.
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default HRbenefits;
