import { createSlice } from "@reduxjs/toolkit";

const candidateSlice = createSlice({
  name: "candidate",
  initialState: {
    allCandidates: null,
    allCandidatesError: "",
    positionCandidates: null,
    positionCandidatesError: "",
    positionID: "",
    serverError: false,
  },
  reducers: {
    getAllCandidates: (state, action) => {},
    getAllCandidatesSuccess: (state, action) => {
      state.allCandidates = action.payload;
    },
    getAllCandidatesFail: (state, action) => {
      state.allCandidatesError = action.payload;
    },

    getPositionCandidates: (state, action) => {
      state.positionID = action.payload;
    },
    getPositionCandidatesSuccess: (state, action) => {
      state.positionCandidates = action.payload;
    },
    getPositionCandidatesFail: (state, action) => {
      state.positionCandidatesError = action.payload;
    },
  },
});

export default candidateSlice;
export const {
  getAllCandidates,
  getAllCandidatesFail,
  getAllCandidatesSuccess,

  getPositionCandidates,
  getPositionCandidatesFail,
  getPositionCandidatesSuccess,
} = candidateSlice.actions;
