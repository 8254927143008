import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Backdrop, Checkbox, CircularProgress } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { Popconfirm } from "antd";
import axios from "axios";
import { removeAdmin, updateAdminList } from "../../../redux/admin/adminRedux";
import { convertDate } from "./AdminDetail";
import { Helmet } from "react-helmet";
function AdminsList() {
  const { adminsList } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { adminDetail } = useSelector((state) => state.position);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  var index = 0;
  if (!adminDetail?.superAdmin) {
    return <Navigate to="/admin" replace={true} />;
  }
  const deleteAdmin = async (id) => {
    setLoading(true);
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_URL}/auth/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(removeAdmin(id));
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
    setLoading(false);
  };

  const updateAdmin = async (e) => {
    e.preventDefault();
    try {
      const { id, superAdmin, canPost } = selected;
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/auth/${id}`,
        {
          superAdmin,
          canPost,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(updateAdminList({ ...res.data, permissions: [] }));
      setOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
  };

  return (
    <div style={{ margin: "100px 10px" }}>
      <Helmet>
        <title>Admins</title>
      </Helmet>
      <Backdrop
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <form
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
          className="py-4 rounded"
          onSubmit={updateAdmin}
        >
          <div className="d-flex justify-content-end align-items-center mb-2">
            <section
              style={{
                width: "30px",
                height: "30px",
                background: "#c2e7ff",
                borderRadius: "50%",
              }}
              className="d-flex justify-content-center align-items-center"
              onClick={() => setOpen(false)}
            >
              <ClearIcon style={{ cursor: "pointer" }} />
            </section>
          </div>
          <div>
            <div style={{}}>
              Admin: <span className="font-weight-bold">{selected?.email}</span>
            </div>

            <div className="d-flex align-items-center mt-2">
              <section>Can Post?</section>
              <Checkbox
                onChange={(e) =>
                  setSelected((prev) => {
                    return { ...prev, canPost: e.target.checked };
                  })
                }
                checked={selected.canPost ? selected.canPost : false}
              />
            </div>
            <div className="d-flex align-items-center ">
              <section>Super admin access?</section>
              <Checkbox
                onChange={(e) =>
                  setSelected((prev) => {
                    return { ...prev, superAdmin: e.target.checked };
                  })
                }
                checked={selected.superAdmin ? selected.superAdmin : false}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                style={{
                  background: "#168c9e",
                  width: "130px",
                  color: "white",
                  letterSpacing: "2px",
                  cursor: "pointer",
                  border: "none",
                }}
                className="text-center py-2 rounded"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Backdrop>
      {adminsList && !loading && (
        <table className="table table-striped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col" className="text-center">
                Email
              </th>
              <th scope="col" className="text-center">
                Super Admin
              </th>
              <th scope="col" className="text-center">
                Can Post
              </th>
              <th scope="col" className="text-center">
                Role Given By
              </th>
              <th scope="col" className="text-center">
                Given Permissions
              </th>
              <th scope="col" className="text-center">
                Date
              </th>
              <th scope="col" className="text-center">
                Options
              </th>
            </tr>
          </thead>
          <tbody>
            {adminsList?.map((admin) => {
              const { id, email, superAdmin, canPost, createdOn, givenBy } =
                admin;
              if (adminDetail?.id === id) {
                return null;
              }
              index += 1;
              return (
                <tr key={admin.id} style={{ cursor: "pointer" }}>
                  <th scope="row" onClick={() => navigate(`/admin/list/${id}`)}>
                    {index}
                  </th>
                  <td
                    className="text-center font-weight-bold"
                    onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {email}
                  </td>
                  <td
                    className="text-center"
                    onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {superAdmin ? (
                      <CheckIcon style={{ color: "#168c9e" }} />
                    ) : (
                      <ClearIcon style={{ color: "#B30C0C" }} />
                    )}
                  </td>
                  <td
                    className="text-center"
                    onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {canPost ? (
                      <CheckIcon style={{ color: "#168c9e" }} />
                    ) : (
                      <ClearIcon style={{ color: "#B30C0C" }} />
                    )}
                  </td>
                  <td
                    className="text-center"
                    onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {givenBy}
                  </td>
                  <td
                    className="text-center font-weight-bold"
                    style={{ fontSize: "18px" }}
                    onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {superAdmin ? "Full Access" : admin.permissions.length}
                  </td>
                  <th
                    scope="row"
                    className="text-center"
                    onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {convertDate(createdOn)}
                  </th>
                  <td>
                    <div className="d-flex justify-content-center">
                      <section
                        style={{
                          background: "#168c9e",
                          color: "white",
                          letterSpacing: "2px",
                          fontSize: "17px",
                        }}
                        className="px-4 py-1 rounded mr-4"
                        onClick={() => {
                          setOpen(true);
                          setSelected(admin);
                        }}
                      >
                        Edit
                      </section>
                      <Popconfirm
                        title="Are you sure remove this admin?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => deleteAdmin(id)}
                      >
                        <section
                          style={{
                            background: "#B30C0C",
                            cursor: "pointer",
                            color: "white",
                            letterSpacing: "2px",
                            fontSize: "17px",
                          }}
                          className="px-4 py-1 rounded"
                        >
                          Remove
                        </section>
                      </Popconfirm>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {(!adminsList || loading) && (
        <div
          style={{ height: "400px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-column align-items-center">
            <CircularProgress />
            <section className="mt-3" style={{ fontSize: "20px" }}>
              Loading admin list...
            </section>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminsList;
