import React from "react";
import { Typography } from "@mui/material";

import Image404 from "../../../assets/page-not-found.png";
import "../../../styles/styles.css";

const handleBack = () => {
  window.open("/", "_self");
};

function ErrorPage() {
  return (
    <div className="container fuzz">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-md-6 col-sm-12">
          <Typography variant="h2">Page Not Found!</Typography>
          <Typography variant="h6">
            It looks like that page doesn't exist, please check the URL and try
            again.
          </Typography>
          <button
            size="lg"
            variant="secondary"
            onClick={handleBack}
            className="rounded login-btn my-2"
          >
            Home
          </button>
        </div>
        <div className="col-xs-12 col-md-6 col-sm-12 ">
          <img src={Image404} alt="" width={550} height={450} />
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
