import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { CircularProgress, IconButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import nodata from "../../../../src/assets/nodata.png";
import { useSelector } from "react-redux";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Helmet } from "react-helmet";
// import ReactHTMLTableToExcel from "react-html-table-to-excel";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  //   { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "age",
    label: "Age",
    minWidth: 100,
    // align: "right",
    format: (value) => value.toFixed(2),
  },
  {
    id: "sex",
    label: "Sex",
    minWidth: 100,
    // align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "email",
    label: "Email",
    minWidth: 100,

    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "phone",
    label: "Phone",
    minWidth: 150,
    // align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "living",
    label: "Address",
    minWidth: 150,
    format: (value) => value.toFixed(2),
  },

  {
    id: "Education",
    label: "Education",
    minWidth: 50,
    format: (value) => value.toFixed(2),
  },
  {
    id: "Experience",
    label: "Experience",
    minWidth: 50,
    format: (value) => value.toFixed(2),
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 100,
    format: (value) => value.toFixed(2),
  },

  {
    id: "Position",
    label: "Position",
    minWidth: 30,
    format: (value) => value.toFixed(2),
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 30,
    format: (value) => value.toFixed(2),
  },
];

export function StickyHeadTable() {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  localStorage.setItem("path", location.pathname.split("/")[2]);
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { allCandidates } = useSelector((state) => state.candidate);
  const { adminDetail } = useSelector((state) => state.position);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const handleSearch = () => {
  //   var result;
  //   if (searchParams.has("age")) {
  //     result = allCandidates.filter(
  //       (row) => row.age === searchParams.get("age")
  //     );
  //   }

  //   if (searchParams.has("experience")) {
  //     if (searchParams.has("age")) {
  //       result = result.filter(
  //         (row) => row.yearsOfExperience === searchParams.get("experience")
  //       );
  //     } else {
  //       result = allCandidates.filter(
  //         (row) => row.yearsOfExperience === searchParams.get("experience")
  //       );
  //     }
  //   }
  //   if (searchParams.has("education")) {
  //     if (searchParams.has("age") || searchParams.has("experience")) {
  //       result = result.filter(
  //         (row) => row.educationLevel === searchParams.get("education")
  //       );
  //     } else {
  //       result = allCandidates.filter(
  //         (row) => row.educationLevel === searchParams.get("education")
  //       );
  //     }
  //   }

  //   if (searchParams.has("sex")) {
  //     if (
  //       searchParams.has("age") ||
  //       searchParams.has("experience") ||
  //       searchParams.has("education")
  //     ) {
  //       result = result.filter((row) => row.sex === searchParams.get("sex"));
  //     } else {
  //       result = allCandidates.filter(
  //         (row) => row.sex === searchParams.get("sex")
  //       );
  //     }
  //   }
  //   if (searchParams.has("rejectionStatus")) {
  //     console.log(
  //       "rejection status filter is on ",
  //       searchParams.get("rejectionStatus")
  //     );
  //     if (
  //       searchParams.has("age") ||
  //       searchParams.has("experience") ||
  //       searchParams.has("education") ||
  //       searchParams.has("sex")
  //     ) {
  //       result = result.filter(
  //         (row) => row.rejectionStatus === searchParams.get("rejectionStatus")
  //       );
  //     } else {
  //       result = allCandidates.filter(
  //         (row) => row.rejectionStatus === searchParams.get("rejectionStatus")
  //       );
  //     }
  //   }

  //   if (searchParams.has("email")) {
  //     if (
  //       searchParams.has("age") ||
  //       searchParams.has("education") ||
  //       searchParams.has("experience")
  //     ) {
  //       result = result.filter((row) =>
  //         row.email
  //           .toLowerCase()
  //           .includes(searchParams.get("email").toLowerCase())
  //       );
  //     } else {
  //       result = allCandidates.filter((row) =>
  //         row.email
  //           .toLowerCase()
  //           .includes(searchParams.get("email").toLowerCase())
  //       );
  //     }
  //   }

  //   if (
  //     !searchParams.get("age") &&
  //     !searchParams.get("education") &&
  //     !searchParams.get("experience") &&
  //     !searchParams.get("email") &&
  //     !searchParams.get("sex")
  //   ) {
  //     result = allCandidates;
  //   }

  //   return result;
  // };
  const handleSearch = () => {
    let result = [...allCandidates]; // Start with all candidates

    // Apply filters
    if (searchParams.has("age")) {
      result = result.filter((row) => row.age === searchParams.get("age"));
    }
    if (searchParams.has("experience")) {
      result = result.filter(
        (row) => row.yearsOfExperience === searchParams.get("experience")
      );
    }
    if (searchParams.has("education")) {
      result = result.filter(
        (row) => row.educationLevel === searchParams.get("education")
      );
    }
    if (searchParams.has("sex")) {
      result = result.filter((row) => row.sex === searchParams.get("sex"));
    }
    if (searchParams.has("rejectionStatus")) {
      const rejectionStatus = searchParams.get("rejectionStatus") === "true"; // Assuming "true" string represents rejected status
      result = result.filter((row) => row.rejectionStatus === rejectionStatus);
    }
    if (searchParams.has("email")) {
      const emailFilter = searchParams.get("email").toLowerCase();
      result = result.filter((row) =>
        row.email.toLowerCase().includes(emailFilter)
      );
    }

    return result;
  };

  const columnsToExclude = [
    "id",
    "createdOn",
    "applicationDescription",
    "cv",
    "otherRelevantDocuments",
    "position",
    "answers",
  ];

  const modifiedData = allCandidates
    ? handleSearch().map((item) => {
        return Object.keys(item)
          .filter((key) => !columnsToExclude.includes(key))
          .reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
          }, {});
      })
    : [];
  console.log("allCandidates", allCandidates);

  return (
    <>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {adminDetail &&
          (adminDetail.superAdmin || adminDetail.permissions.length !== 0) && (
            <div
              className="d-flex align-items-center justify-content-between px-2"
              style={{ backgroundColor: "#dbdbdc" }}
            >
              <section>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    // width: 350,
                  }}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    size="large"
                  >
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Email"
                    inputProps={{ "aria-label": "Email" }}
                    value={
                      searchParams.get("email") ? searchParams.get("email") : ""
                    }
                    onChange={(e) => {
                      const sp = new URLSearchParams(searchParams);
                      if (sp.has("email")) {
                        sp.delete("email");
                        if (e.target.value) {
                          sp.append("email", e.target.value);
                        }
                      } else {
                        if (e.target.value) {
                          sp.append("email", e.target.value);
                        }
                      }
                      setSearchParams(sp);
                    }}
                  />
                </Paper>
                <span>
                  {allCandidates && (
                    <span
                      className="font-weight-bold"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      {handleSearch().length}
                    </span>
                  )}{" "}
                  <span style={{ fontFamily: "sans-serif" }}>found</span>
                </span>
                {searchParams.size ? (
                  <span
                    className="ml-4 pointer"
                    onClick={() => setSearchParams("")}
                    style={{ color: "blue" }}
                  >
                    Clear Filter
                  </span>
                ) : null}
              </section>
              <section>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <FormHelperText>Age</FormHelperText>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      searchParams.get("age") ? searchParams.get("age") : ""
                    }
                    onChange={(e) => {
                      const sp = new URLSearchParams(searchParams);
                      if (sp.has("age")) {
                        sp.delete("age");
                        if (e.target.value) {
                          sp.append("age", e.target.value);
                        }
                      } else {
                        if (e.target.value) {
                          sp.append("age", e.target.value);
                        }
                      }
                      setSearchParams(sp);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"(18-24)"}>(18-24)</MenuItem>
                    <MenuItem value={"(25-30)"}>(25-30)</MenuItem>
                    <MenuItem value={"(31-40)"}>(31-40)</MenuItem>
                    <MenuItem value={"(40+)"}>(40+)</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <FormHelperText>Status</FormHelperText>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      searchParams.get("rejectionStatus")
                        ? searchParams.get("rejectionStatus")
                        : ""
                    }
                    onChange={(e) => {
                      const sp = new URLSearchParams(searchParams);
                      if (sp.has("rejectionStatus")) {
                        sp.delete("rejectionStatus");
                        if (e.target.value) {
                          sp.append("rejectionStatus", e.target.value);
                        }
                      } else {
                        if (e.target.value) {
                          sp.append("rejectionStatus", e.target.value);
                        }
                      }
                      setSearchParams(sp);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={true}>Rejected</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <FormHelperText>Sex</FormHelperText>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    value={
                      searchParams.get("sex") ? searchParams.get("sex") : ""
                    }
                    onChange={(e) => {
                      const sp = new URLSearchParams(searchParams);
                      if (sp.has("sex")) {
                        sp.delete("sex");
                        if (e.target.value) {
                          sp.append("sex", e.target.value);
                        }
                      } else {
                        if (e.target.value) {
                          sp.append("sex", e.target.value);
                        }
                      }
                      setSearchParams(sp);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <FormHelperText>Years of Experience</FormHelperText>
                  <Select
                    value={
                      searchParams.get("experience")
                        ? searchParams.get("experience")
                        : ""
                    }
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => {
                      const sp = new URLSearchParams(searchParams);

                      if (sp.has("experience")) {
                        sp.delete("experience");
                        if (e.target.value) {
                          sp.append("experience", e.target.value);
                        }
                      } else {
                        if (e.target.value) {
                          sp.append("experience", e.target.value);
                        }
                      }
                      setSearchParams(sp);
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"(0-1)"}>(0-1)</MenuItem>
                    <MenuItem value={"(2-3)"}>(2-3)</MenuItem>
                    <MenuItem value={"(4-7)"}>(4-7)</MenuItem>
                    <MenuItem value={"(8+)"}>(8+)</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150 }}>
                  <FormHelperText>Education Level</FormHelperText>
                  <Select
                    value={
                      searchParams.get("education")
                        ? searchParams.get("education")
                        : ""
                    }
                    onChange={(e) => {
                      const sp = new URLSearchParams(searchParams);
                      if (sp.has("education")) {
                        sp.delete("education");
                        if (e.target.value) {
                          sp.append("education", e.target.value);
                        }
                      } else {
                        if (e.target.value) {
                          sp.append("education", e.target.value);
                        }
                      }
                      setSearchParams(sp);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"highschool diploma"}>
                      HighSchool Diploma
                    </MenuItem>

                    <MenuItem value={"under graduate"}>Undergraduate</MenuItem>
                    <MenuItem value={"bachlors degree"}>Bachelors</MenuItem>
                    <MenuItem value={"masters degree"}>Masters</MenuItem>
                  </Select>
                </FormControl>
              </section>
            </div>
          )}

        <TableContainer sx={{ maxHeight: 640 }}>
          {adminDetail &&
            (adminDetail.superAdmin ||
              adminDetail.permissions.length !== 0) && (
              <Table stickyHeader id="table-to-xls" aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allCandidates &&
                    handleSearch()
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        const { visibility } = row;
                        const index = searchParams.has("email")
                          ? row.email
                              .toLowerCase()
                              .indexOf(
                                searchParams.get("email").toLocaleLowerCase()
                              )
                          : -1;
                        return (
                          <TableRow
                            onClick={() =>
                              navigate(
                                `/admin/candidate/${
                                  row.id
                                }?candidates=all&${searchParams.toString()}`
                              )
                            }
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                            style={{
                              backgroundColor: `${
                                visibility === "potential"
                                  ? "rgb(253, 201, 201)"
                                  : ""
                              }`,
                            }}
                          >
                            <TableCell>
                              {row.firstName} {row.lastName}
                            </TableCell>
                            <TableCell>{row.age}</TableCell>
                            <TableCell>{row.sex}</TableCell>
                            <TableCell>
                              {index !== -1 && searchParams.has("email") ? (
                                <>
                                  {row.email.slice(0, index)}
                                  <span
                                    style={{
                                      backgroundColor: "yellow",
                                      // fontWeight: "bold",
                                      fontFamily: "sans-serif",
                                      // fontStyle: "italic",
                                    }}
                                  >
                                    {searchParams.get("email")}
                                  </span>
                                  {row.email.slice(
                                    index + searchParams.get("email").length
                                  )}
                                </>
                              ) : (
                                <>{row.email}</>
                              )}
                            </TableCell>
                            <TableCell>{row.phoneNumber}</TableCell>
                            <TableCell>{row.address}</TableCell>

                            {row.educationLevel === "highschool diploma" && (
                              <TableCell>Diploma</TableCell>
                            )}

                            {row.educationLevel === "bachlors degree" && (
                              <TableCell>Bachelors</TableCell>
                            )}
                            {row.educationLevel === "masters degree" && (
                              <TableCell>Masters</TableCell>
                            )}

                            <TableCell>{row.yearsOfExperience}</TableCell>
                            {row.applicationStatus === "just-applied" && (
                              <TableCell>Applied</TableCell>
                            )}

                            {row.applicationStatus === "passed-for-exam" && (
                              <TableCell>Exam</TableCell>
                            )}
                            {row.applicationStatus ===
                              "passed-for-interview" && (
                              <TableCell>Interview</TableCell>
                            )}
                            {row.applicationStatus ===
                              "passed-for-demonstration" && (
                              <TableCell>Demonstration</TableCell>
                            )}

                            {row.applicationStatus === "onboarding" && (
                              <TableCell>Onboarding</TableCell>
                            )}

                            <TableCell>{row.position.title}</TableCell>
                            <TableCell>
                              {row.rejectionStatus === true
                                ? "rejected"
                                : "active"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            )}
          {!allCandidates && (
            <div
              style={{
                height: "300px",
                width: "100%",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="d-flex flex-column align-items-center">
                <CircularProgress />
                <section className="mt-3" style={{ fontSize: "20px" }}>
                  Loading candidates...
                </section>
              </div>
            </div>
          )}

          {adminDetail &&
            (adminDetail.superAdmin || adminDetail.permissions.length !== 0) &&
            allCandidates &&
            handleSearch().length === 0 && (
              <section
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={nodata}
                  style={{ width: "50%", height: "40%" }}
                  alt=""
                />
              </section>
            )}
          {adminDetail &&
            !adminDetail.superAdmin &&
            adminDetail.permissions.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "600px",
                  backgroundColor: "white",
                }}
              >
                <section
                  style={{
                    width: "400px",
                    height: "300px",
                    fontSize: "34px",
                    marginTop: "70px",
                  }}
                >
                  No permissions Found.
                </section>
              </div>
            )}
        </TableContainer>
        <section className="container-fluid">
          <div className="row p-3">
            <div className="col d-flex justify-content-start align-items-center ">
              <section>
                {allCandidates && modifiedData.length !== 0 && (
                  <div>
                    <CSVLink
                      className="download-excel py-2 pointer px-3 rounded"
                      data={modifiedData}
                      filename={"Candidates.csv"}
                      style={{ textDecoration: "none", fontWeight: "bold" }}
                    >
                      Export to Excel
                    </CSVLink>
                  </div>
                )}
              </section>
            </div>
            {
              adminDetail && adminDetail.superAdmin && (
                //adminDetail.permissions.length !== 0 && (
                <div className="col d-flex justify-content-end p-0 text-black">
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={allCandidates ? handleSearch().length : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              )
              //      )
            }
          </div>
        </section>
      </Paper>
    </>
  );
}
function CandidatesList() {
  return (
    <div style={{ margin: "100px 10px", minWidth: "790px" }}>
      {/* <Toolbar></Toolbar> */}
      <StickyHeadTable />
    </div>
  );
}

export default CandidatesList;
