import React from "react";
import "../styles/intern.css";
import { Link } from "react-router-dom";

function SingleIntern({ id, title, overview, avatar }) {
  return (
    <div className="col-md-6   px-3  mb-3">
      <div className="shade py-4  h-100 ">
        <section
          className="d-flex flex-column align-items-center w-100 justify-content-between"
          style={{ gap: "8px" }}
        >
          <div style={{ width: "100px", height: "100px" }}>
            <img
              src={`${process.env.REACT_APP_URL}/candidates/documents?query=${avatar}`}
              alt={`${title}`}
              className="black-white"
            />
          </div>
          <h1
            className="w-100 text-center px-4"
            style={{
              color: "black",
              fontSize: "22px",
              lineHeight: "1",
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {title}
          </h1>
        </section>
        <section>
          <section
            className="text-center px-3"
            style={{
              fontSize: "15px",
              fontFamily: "Raleway, sans-serif",

              color: "#333333",
              lineHeight: "1.4",
            }}
          >
            {overview}
          </section>
          <Link
            to={`${id}`}
            className="pointer text-center"
            style={{
              fontSize: "15px",
              fontFamily: "sans-serif",
              color: "#087b8d",
              textDecoration: "none",
            }}
          >
            <section>Read More</section>
          </Link>
        </section>
      </div>
    </div>
  );
}

export default SingleIntern;
