import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "./Login";
import {
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { Alert } from "antd";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import axios from "axios";
import { notification } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function ForgotPassword() {
  const { data, setPage, setSuccess } = useContext(DataContext);
  const [showPassword, setShowPassword] = React.useState(false);
  const [count, setCount] = useState(60);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [response, setResponse] = useState({ error: false, msg: "" });
  const [api, contextHolder] = notification.useNotification();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const [input, setInput] = useState({
    code: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    code: false,
    password: false,
    confirmPassword: false,
    confirmPasswordError: "",
  });
  const validateInput = (code, password, secondPassword) => {
    var codeError = false;
    var passwordError = false;
    var confirmPassword = false;
    var confirmPasswordError = "";

    if (!code.trim()) {
      codeError = true;
    } else if (code.trim()) {
      codeError = false;
    }

    if (!password) {
      passwordError = true;
    } else if (password) {
      passwordError = false;
    }

    if (!secondPassword) {
      confirmPassword = true;
      confirmPasswordError = "";
      return {
        code: codeError,
        password: passwordError,
        confirmPassword: confirmPassword,
        confirmPasswordError: confirmPasswordError,
      };
    } else if (secondPassword) {
      confirmPassword = false;
      confirmPasswordError = "";
    }

    if (secondPassword !== password) {
      confirmPassword = true;
      confirmPasswordError = "Password does not match";
    } else if (secondPassword === password) {
      confirmPassword = false;
      confirmPasswordError = "";
    }

    return {
      code: codeError,
      password: passwordError,
      confirmPassword: confirmPassword,
      confirmPasswordError: confirmPasswordError,
    };
  };

  const handleResetRequest = async (email, newPassword, code) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/reset-password`,
        { email: email, password: newPassword, recoveryDigits: code }
      );

      if (response.status === 201) {
        setSuccess({
          success: true,
          msg: "Password successfully reset! Login to your account with your new password.",
        });
        setPage("login");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setResponse({ error: true, msg: "Invalid Code" });
      } else if (err.response && err.response.status === 400) {
        setResponse({ error: true, msg: "Weak password" });
      } else {
        setResponse({ error: true, msg: "Failed, try again!" });
      }
    }
    setLoading(false);
  };

  const handleReset = () => {
    setResponse({ error: false, msg: "" });
    setSuccess({ success: false, msg: "" });
    const { code, password, confirmPassword } = input;
    const result = validateInput(code, password, confirmPassword);
    setSuccess({ success: false, msg: "" });
    if (result.email || result.password || result.confirmPassword) {
      setError(result);
      return;
    }

    setError(result);
    handleResetRequest(data.email, password, code);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (count > 0) {
        setCount((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, [count]);

  const handleResendRequest = async (email) => {
    setLoading(true);
    setCount(0);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/forget-password`,
        { email: email }
      );

      if (response.status === 201) {
        api.open({
          message: "iCog-ACC",
          description: `Code successfully sent again! Check your email ${data.email}`,
          icon: (
            <CheckCircleIcon
              style={{
                color: "#168c9e",
              }}
            />
          ),
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setResponse({ error: true, msg: "User Not Found" });
      } else if (err.response && err.response.status === 400) {
        setResponse({ error: true, msg: "Invalid Email" });
      } else {
        setResponse({ error: true, msg: "Operation Failed" });
      }
    }
    setLoading(false);
    setCount(60);
  };

  const handleReSend = () => {
    setSuccess({ success: false, msg: "" });
    handleResendRequest(data.email);
  };
  return (
    <>
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex align-items-center justify-content-center"
      >
        {contextHolder}
        <div
          className="container py-5 mx-3 d-flex justify-content-center align-items-center  flex-column"
          style={{
            minWidth: "280px",
            boxShadow: "rgba(1, 12, 14, 0.2) 0px 2px 20px",
            borderRadius: "15px",
            // backgroundColor: "orange",
          }}
        >
          <div className="row">
            <div
              className="col-12 text-center mb-2"
              style={{
                fontFamily: "sans-serif",
                fontSize: "28px",
                fontWeight: "600",
              }}
            >
              Reset Password
            </div>
          </div>
          <div className="row">
            <div
              className="col-12 text-center "
              style={{
                fontFamily: "sans-serif",
                fontSize: "19px",
                color: "#6c757d",
              }}
            >
              We have sent a code to your email
              <span
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "19px",
                  color: "#6c757d",
                  fontWeight: "bold",
                }}
                className="ml-1"
              >
                {data.email}
              </span>
            </div>
          </div>
          <div className="row mt-4 mb-2" style={{ height: "20px" }}>
            {count > 0 && (
              <>
                <div
                  className="col-12 text-center "
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "19px",
                    color: "#6c757d",
                  }}
                >
                  Resend code in
                  <span
                    className="font-weight-bold ml-1"
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "16px",

                      // backgroundColor: "blue",
                    }}
                  >
                    {count}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className="row w-100 justify-content-center">
            <div className=" col-sm-8  col-md-6 col-lg-4   d-flex justify-content-center py-2">
              <FormControl
                variant="standard"
                style={{ backgroundColor: "", width: "100%" }}
              >
                <InputLabel
                  htmlFor="password"
                  style={{
                    color: `${error.code ? "rgb(179, 12, 12)" : ""}`,
                    width: "100%",
                  }}
                >
                  Code *
                </InputLabel>
                <Input
                  id="code"
                  variant="standard"
                  name="code"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setInput((prev) => {
                      return { ...prev, code: e.target.value };
                    })
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ visibility: "hidden" }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        size="large"
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={input.code}
                />
              </FormControl>
            </div>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-sm-8  col-md-6 col-lg-4   d-flex justify-content-start">
              <section
                style={{
                  height: "15px",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "rgb(179, 12, 12)",
                  width: "100%",
                }}
              >
                {error.code && "Invalid Code"}
              </section>
            </div>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-sm-8  col-md-6 col-lg-4 d-flex justify-content-center py-2">
              <FormControl
                variant="standard"
                style={{ backgroundColor: "", width: "100%" }}
              >
                <InputLabel
                  htmlFor="password"
                  style={{
                    color: `${error.password ? "rgb(179, 12, 12)" : ""}`,
                    width: "100%",
                  }}
                >
                  New Password *
                </InputLabel>
                <Input
                  id="password"
                  label="New password *"
                  variant="standard"
                  name="newPassword"
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    setInput((prev) => {
                      return { ...prev, password: e.target.value };
                    })
                  }
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        size="large"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={input.password}
                />
              </FormControl>
            </div>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-sm-8  col-md-6 col-lg-4 d-flex justify-content-start">
              <section
                style={{
                  height: "15px",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "rgb(179, 12, 12)",
                  minWidth: "35%",
                  // backgroundColor: "red",
                }}

                //   className="text-center"
              >
                {error.password && "Invalid Password"}
              </section>
            </div>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-sm-8  col-md-6 col-lg-4 d-flex justify-content-center py-2">
              <FormControl
                variant="standard"
                // className="mb-4"
                style={{ backgroundColor: "", width: "100%" }}
              >
                <InputLabel
                  htmlFor="confirmPassword "
                  style={{
                    color: `${error.confirmPassword ? "rgb(179, 12, 12)" : ""}`,
                    width: "100%",
                  }}
                >
                  Confirm New Password *
                </InputLabel>

                <Input
                  id="confirmPassword "
                  label="Confirm new password *"
                  variant="standard"
                  name="confirmPassword"
                  style={{ width: "100%" }}
                  error={error.confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(e) =>
                    setInput((prev) => {
                      return { ...prev, confirmPassword: e.target.value };
                    })
                  }
                  value={input.confirmPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        size="large"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="row w-100 justify-content-center">
            <div className="col-sm-8  col-md-6 col-lg-4 d-flex justify-content-start">
              <section
                style={{
                  height: "15px",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "rgb(179, 12, 12)",
                  minWidth: "35%",
                }}
              >
                {error.confirmPassword
                  ? error.confirmPasswordError
                    ? error.confirmPasswordError
                    : "Invalid Password"
                  : null}
              </section>
            </div>
          </div>
          <div
            className="row w-100 justify-content-center"
            // style={{ backgroundColor: "red" }}
          >
            <div
              className="col-sm-8  col-md-6 col-lg-4 d-flex justify-content-between align-items-center"
              // style={{ backgroundColor: "orange" }}
            >
              <section
                id="forgot-text"
                style={{
                  fontFamily: "sans-serif",
                  textDecoration: "none",
                  minWidth: "35%",
                  fontSize: "16px",
                  // backgroundColor: "white",
                }}
                className="small pointer  ms-5 my-2"
                onClick={() => setPage("login")}
                // style={{ backgroundColor: "white" }}
              >
                Back
              </section>
              <section
                // id="forgot-text"
                style={{
                  fontFamily: "sans-serif",
                  textDecoration: "none",
                  minWidth: "35%",
                  fontSize: "16px",

                  // backgroundColor: "blue",
                }}
                className="small pointer  ms-5 my-2 d-flex justify-content-end"

                //   style={{ backgroundColor: "red" }}
              >
                <button
                  style={{
                    border: "none",
                    // backgroundColor: "#2597a6",
                    letterSpacing: "1px",
                    // color: "white",
                    cursor: `${count > 0 ? "not-allowed" : "pointer"}`,
                  }}
                  className="px-3 py-1 rounded"
                  onClick={() => {
                    setError({
                      code: false,
                      password: false,
                      confirmPassword: false,
                      confirmPasswordError: "",
                    });
                    setResponse({ error: false, msg: "" });
                    handleReSend();
                  }}
                  disabled={(count > 0 || loading) && true}
                >
                  Resend code
                </button>
              </section>
            </div>
          </div>

          <div className="row w-100 justify-content-center mt-3">
            <div className="col-sm-8  col-md-6 col-lg-4">
              <section
                style={{
                  height: "47px",
                }}
                className=" text-center d-flex justify-content-center"
              >
                {response.error && (
                  <Alert
                    message={`${response.msg}`}
                    type="error"
                    style={{
                      color: "rgb(179, 12, 12)",
                      fontSize: "18px",
                      fontFamily: "sans-serif",

                      width: "100%",
                    }}
                  />
                )}
              </section>
            </div>
          </div>
          <div className="row w-100 justify-content-center mt-3">
            <div className="col-sm-8  col-md-6 col-lg-4 d-flex  justify-content-center">
              {loading ? (
                <section className="d-flex justify-content-center align-items-center">
                  <CircularProgress />
                </section>
              ) : (
                <button
                  onClick={handleReset}
                  className="w-100 login-btn rounded pointer"
                >
                  Reset
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
