// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internship-tab {
  width: 32%;
  height: 100%;
  font-size: 20px;
}
.internship-overview {
  text-align: justify;
  font-size: 18px;
}
@media (max-width: 420px) {
  .internship-overview {
    font-size: 15px;
  }
  .internship-tabs {
    justify-content: space-between;
  }

  .internship-tab {
    width: 50%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/internshipDetail/styles/internshipDetail.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,8BAA8B;EAChC;;EAEA;IACE,UAAU;EACZ;AACF","sourcesContent":[".internship-tab {\n  width: 32%;\n  height: 100%;\n  font-size: 20px;\n}\n.internship-overview {\n  text-align: justify;\n  font-size: 18px;\n}\n@media (max-width: 420px) {\n  .internship-overview {\n    font-size: 15px;\n  }\n  .internship-tabs {\n    justify-content: space-between;\n  }\n\n  .internship-tab {\n    width: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
