import React from "react";
import { useOutletContext } from "react-router-dom";

function Responsibility() {
  const { responsibility } = useOutletContext();

  return (
    <>
      {responsibility && (
        <div
          className="h-auto  mx-2 measures "
          style={{
            marginTop: "30px",
            borderRadius: "10px",
          }}
        >
          <ul style={{ backgroundColor: "" }} className="px-4">
            {responsibility.split("||").map((res, index) => {
              if (res.trim()) {
                return (
                  <li key={index} className="mb-2 tab-content">
                    {res}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default Responsibility;
