import { useEffect, useState } from "react";
import CollapsibleTable from "./CandidatesTable";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getPositionCandidates } from "../../../redux/candidate/candidateRedux";
import { Helmet } from "react-helmet";

function JobCandidates({ stage }) {
  const { positionCandidates } = useSelector((state) => state.candidate);
  const { adminJobs } = useSelector((state) => state.position);
  const jobs = adminJobs;
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ids, setIds] = useState([]);
  const [searchParams, setSearchParams] = useOutletContext();
  const location = useLocation();
  const path = location.pathname.split("/");

  localStorage.setItem("path", `/${path[1]}/${path[2]}/${path[3]}`);
  var candidates = [];
  if ((stage !== "all") & (stage !== "potential")) {
    candidates = positionCandidates
      ? positionCandidates.filter(
          (candidate) =>
            (candidate.applicationStatus === stage) &
            (candidate.visibility === "visible")
        )
      : [];
  } else {
    if (stage === "all") {
      candidates = positionCandidates ? positionCandidates : [];
    }
    if (stage === "potential") {
      candidates = positionCandidates
        ? positionCandidates.filter(
            (candidate) => candidate.visibility === "potential"
          )
        : [];
    }
  }

  const job = jobs ? jobs.find((job) => job.id === id) : {};
  const handleSearch = () => {
    var result;
    if (searchParams.has("age")) {
      result = candidates.filter((row) => row.age === searchParams.get("age"));
    }

    if (searchParams.has("experience")) {
      if (searchParams.has("age")) {
        result = result.filter(
          (row) => row.yearsOfExperience === searchParams.get("experience")
        );
      } else {
        result = candidates.filter(
          (row) => row.yearsOfExperience === searchParams.get("experience")
        );
      }
    }
    if (searchParams.has("education")) {
      if (searchParams.has("age") || searchParams.has("experience")) {
        result = result.filter(
          (row) => row.educationLevel === searchParams.get("education")
        );
      } else {
        result = candidates.filter(
          (row) => row.educationLevel === searchParams.get("education")
        );
      }
    }

    if (searchParams.has("sex")) {
      if (
        searchParams.has("age") ||
        searchParams.has("experience") ||
        searchParams.has("education")
      ) {
        result = result.filter((row) => row.sex === searchParams.get("sex"));
      } else {
        result = candidates.filter(
          (row) => row.sex === searchParams.get("sex")
        );
      }
    }

    if (searchParams.has("email")) {
      if (
        searchParams.has("age") ||
        searchParams.has("education") ||
        searchParams.has("experience")
      ) {
        result = result.filter((row) =>
          row.email
            .toLowerCase()
            .includes(searchParams.get("email").toLowerCase())
        );
      } else {
        result = candidates.filter((row) =>
          row.email
            .toLowerCase()
            .includes(searchParams.get("email").toLowerCase())
        );
      }
    }

    if (
      !searchParams.get("age") &&
      !searchParams.get("education") &&
      !searchParams.get("experience") &&
      !searchParams.get("email") &&
      !searchParams.get("sex")
    ) {
      result = candidates;
    }
    return result;
  };

  useEffect(() => {
    dispatch(getPositionCandidates(id));
  }, [dispatch, id]);

  return (
    <div style={{ minWidth: "790px" }}>
      <Helmet>
        <title>Candidates</title>
      </Helmet>

      <section style={{ margin: "0px 10px" }}>
        <SelectLabels
          searchParams={searchParams}
          handleSearch={handleSearch}
          candidates={candidates}
          setSearchParams={setSearchParams}
          ids={ids}
          setIds={setIds}
        />
        <CollapsibleTable
          searchParams={searchParams}
          candidates={candidates}
          handleSearch={handleSearch}
          ids={ids}
          setIds={setIds}
          position={job}
          dispatch={dispatch}
          stage={stage}
          positionCandidates={positionCandidates}
        />
      </section>
    </div>
  );
}

export default JobCandidates;

export function SelectLabels({
  candidates,
  searchParams,
  setSearchParams,
  handleSearch,
  setIds,
  ids,
}) {
  return (
    <div className="d-flex align-items-center justify-content-between px-2">
      <section>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 350,
          }}
        >
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            size="large"
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Email"
            value={searchParams.get("email") ? searchParams.get("email") : ""}
            inputProps={{ "aria-label": "Email" }}
            onChange={(e) => {
              const sp = new URLSearchParams(searchParams);
              if (sp.has("email")) {
                sp.delete("email");
                if (e.target.value) {
                  sp.append("email", e.target.value);
                }
              } else {
                if (e.target.value) {
                  sp.append("email", e.target.value);
                }
              }
              setIds([]);
              setSearchParams(sp);
            }}
          />
        </Paper>

        <span>
          {candidates && (
            <>
              <span
                style={{ fontFamily: "sans-serif" }}
                className="font-weight-bold "
              >
                {handleSearch().length}
              </span>
              <span style={{ fontFamily: "sans-serif" }} className="ml-1">
                found
              </span>
            </>
          )}
        </span>
        {searchParams.size ? (
          <span
            className="pointer ml-3"
            onClick={() => {
              setIds([]);
              setSearchParams("");
            }}
            style={{ color: "blue" }}
          >
            Clear Filter
          </span>
        ) : null}
      </section>
      <section>
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <FormHelperText>Age</FormHelperText>
          <Select
            value={searchParams.get("age") ? searchParams.get("age") : ""}
            onChange={(e) => {
              const sp = new URLSearchParams(searchParams);
              if (sp.has("age")) {
                sp.delete("age");
                if (e.target.value) {
                  sp.append("age", e.target.value);
                }
              } else {
                if (e.target.value) {
                  sp.append("age", e.target.value);
                }
              }
              setIds([]);

              setSearchParams(sp);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"(18-24)"}>(18-24)</MenuItem>
            <MenuItem value={"(25-30)"}>(25-30)</MenuItem>
            <MenuItem value={"(31-40)"}>(31-40)</MenuItem>
            <MenuItem value={"(40+)"}>(40+)</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <FormHelperText>Sex</FormHelperText>
          <Select
            value={searchParams.get("sex") ? searchParams.get("sex") : ""}
            onChange={(e) => {
              const sp = new URLSearchParams(searchParams);
              if (sp.has("sex")) {
                sp.delete("sex");
                if (e.target.value) {
                  sp.append("sex", e.target.value);
                }
              } else {
                if (e.target.value) {
                  sp.append("sex", e.target.value);
                }
              }
              setIds([]);

              setSearchParams(sp);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <FormHelperText>Years of Experience</FormHelperText>
          <Select
            value={
              searchParams.get("experience")
                ? searchParams.get("experience")
                : ""
            }
            onChange={(e) => {
              const sp = new URLSearchParams(searchParams);
              if (sp.has("experience")) {
                sp.delete("experience");
                if (e.target.value) {
                  sp.append("experience", e.target.value);
                }
              } else {
                if (e.target.value) {
                  sp.append("experience", e.target.value);
                }
              }
              setIds([]);

              setSearchParams(sp);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"(0-1)"}>(0-1)</MenuItem>
            <MenuItem value={"(2-3)"}>(2-3)</MenuItem>
            <MenuItem value={"(4-7)"}>(4-7)</MenuItem>
            <MenuItem value={"(8+)"}>(8+)</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <FormHelperText>Education Level</FormHelperText>
          <Select
            value={
              searchParams.get("education") ? searchParams.get("education") : ""
            }
            onChange={(e) => {
              const sp = new URLSearchParams(searchParams);
              if (sp.has("education")) {
                sp.delete("education");
                if (e.target.value) {
                  sp.append("education", e.target.value);
                }
              } else {
                if (e.target.value) {
                  sp.append("education", e.target.value);
                }
              }
              setIds([]);

              setSearchParams(sp);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"highschool diploma"}>Diploma</MenuItem>
            <MenuItem value={"under graduate"}>Undergraduate</MenuItem>
            <MenuItem value={"bachlors degree"}>Bachelors</MenuItem>
            <MenuItem value={"masters degree"}>Masters</MenuItem>
          </Select>
        </FormControl>
      </section>
    </div>
  );
}
