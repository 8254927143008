import React from "react";
import FormControl from "@mui/material/FormControl";
import { Grid, TextField, CircularProgress } from "@mui/material";
import "../styles/form.css";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useOutletContext } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { Select } from "antd";
import { Helmet } from "react-helmet";

function ApplyForm() {
  const {
    loading,

    error,
    data,
    setData,
    handleChange,
    files,
    setFiles,
    setFileError,

    id,
    errors,
    handleApply,
    applyResponse,
    fileError,
    handleNext,
    questions,
    validateInput,
  } = useOutletContext();

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    address,

    applicationDescription,
  } = data;
  const fileInputStyles = {
    display: "none",
  };
  const fileInputLabelStyles = {
    padding: "10px 15px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    backgroundColor: "#f3f3f3",
    color: "#333",
    cursor: "pointer",
    maxWidth: "205px",
  };

  const handleContinue = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      age,
      sex,
      yearsOfExperience,
      educationLevel,
      applicationDescription,
    } = data;

    validateInput();
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var isValid = emailPattern.test(email);

    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !isValid ||
      !phoneNumber.trim() ||
      !address.trim() ||
      !age.trim() ||
      !sex.trim() ||
      !yearsOfExperience.trim() ||
      !educationLevel.trim() ||
      !applicationDescription.trim() ||
      !files.cv
    ) {
      return;
    }
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("applicationDescription", data.applicationDescription);
    formData.append("age", data.age);
    formData.append("address", data.address);
    formData.append("email", data.email);
    formData.append("yearsOfExperience", data.yearsOfExperience);
    formData.append("educationLevel", data.educationLevel);
    formData.append("position", id);
    formData.append("cv", files.cv);
    formData.append("otherRelevantDocuments", files.other);
    formData.append("sex", data.sex);
    formData.append("phoneNumber", `${data.phoneNumber}`);

    handleApply(formData);
  };

  return (
    <>
      <Helmet>
        <title>Form</title>
      </Helmet>
      <form className="row" style={{ marginTop: "5px" }}>
        <Grid container spacing={0}>
          <section className="col-lg-5">
            <Grid className="mb-2" xs={12} item>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <TextField
                      placeholder="Enter First Name"
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      value={firstName}
                      error={error.firstName}
                      name="firstName"
                      onChange={handleChange}
                    />
                    <section
                      style={{
                        height: "19px",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {error.firstName === true && <span>Required</span>}
                    </section>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid className="mb-2" xs={12} item>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <TextField
                      placeholder="Enter Last name"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      required
                      name="lastName"
                      value={lastName}
                      error={error.lastName}
                      onChange={handleChange}
                    />
                    <section
                      style={{
                        height: "19px",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {error.lastName && <>Required</>}
                    </section>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid className="mb-2" item xs={12}>
              <TextField
                type="email"
                placeholder="Enter email"
                label="Email"
                variant="outlined"
                fullWidth
                required
                value={email}
                error={error.email}
                name="email"
                onChange={handleChange}
              />
              <section
                style={{
                  height: "19px",
                  color: "red",
                  fontSize: "13px",
                  gap: "5px",
                }}
                className="d-flex"
              >
                {error.email === "Invalid Email" && <span>{error.email}</span>}
                {error.email === true && <span>Required</span>}
                {errors.find(
                  (err) =>
                    err === "Email is not valid" ||
                    err === "email must be an email"
                ) && (
                  <span
                    style={{
                      color: "rgb(179, 12, 12)",
                    }}
                  >
                    Invalid Email
                  </span>
                )}
              </section>
            </Grid>
            <Grid className="mb-2" item xs={12}>
              <TextField
                placeholder="Enter phone number"
                label="Phone"
                variant="outlined"
                fullWidth
                required
                value={phoneNumber}
                name="phoneNumber"
                error={error.phoneNumber}
                onChange={handleChange}
              />
              <section
                style={{
                  height: "19px",
                  color: "red",
                  fontSize: "13px",
                }}
              >
                {error.phoneNumber && <>Required</>}
                {errors.find(
                  (err) => err === "phoneNumber must be a valid phone number"
                ) && (
                  <span
                    style={{
                      color: "rgb(179, 12, 12)",
                    }}
                  >
                    Invalid Phone Number ( Country code required )
                  </span>
                )}
              </section>
            </Grid>
            <Grid className="mb-2" item xs={12}>
              <TextField
                placeholder="Enter current living address"
                label="Living Address"
                variant="outlined"
                fullWidth
                required
                name="address"
                value={address}
                onChange={handleChange}
                error={error.address}
              />
              <section
                style={{
                  height: "19px",
                  color: "red",
                  fontSize: "13px",
                }}
              >
                {error.address && <>Required</>}
              </section>
            </Grid>
            <Grid className="mb-2" item xs={12}>
              <FormControl sx={{ m: 1, minWidth: 175 }}>
                <FormHelperText>Age *</FormHelperText>
                <Select
                  defaultValue=""
                  value={data.age}
                  style={{
                    width: 175,
                  }}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, age: e };
                    })
                  }
                  options={[
                    {
                      value: "(18-24)",
                      label: "(18-24)",
                    },
                    {
                      value: "(25-30)",
                      label: "(25-30)",
                    },
                    {
                      value: "(31-40)",
                      label: "(31-40)",
                    },
                    {
                      value: "(40+)",
                      label: "(40+)",
                    },
                  ]}
                />

                <section
                  style={{
                    height: "0px",
                    color: "red",
                    fontSize: "13px",
                  }}
                >
                  {error.age && <>Required</>}
                </section>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 175 }} className="experience">
                <FormHelperText>Gender *</FormHelperText>
                <Select
                  defaultValue=""
                  style={{
                    width: 175,
                  }}
                  value={data.sex}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, sex: e };
                    })
                  }
                  options={[
                    {
                      value: "male",
                      label: "Male",
                    },
                    {
                      value: "female",
                      label: "Female",
                    },
                  ]}
                />

                <section
                  style={{
                    height: "0px",
                    color: "red",
                    fontSize: "13px",
                  }}
                >
                  {error.sex && <>Required</>}
                </section>
              </FormControl>
            </Grid>
            <Grid className="mb-3" item xs={12}>
              <FormControl sx={{ m: 1, minWidth: 175 }} className="experience">
                <FormHelperText>Years of Experience *</FormHelperText>
                <Select
                  defaultValue=""
                  style={{
                    width: 175,
                  }}
                  value={data.yearsOfExperience}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, yearsOfExperience: e };
                    })
                  }
                  placement={"topRight"}
                  options={[
                    {
                      value: "(0-1)",
                      label: "(0-1)",
                    },
                    {
                      value: "(2-3)",
                      label: "(2-3)",
                    },
                    {
                      value: "(4-7)",
                      label: "(4-7)",
                    },
                    {
                      value: "(8+)",
                      label: "(8+)",
                    },
                  ]}
                />

                <section
                  style={{
                    height: "0px",
                    color: "red",
                    fontSize: "13px",
                  }}
                >
                  {error.yearsOfExperience && <>Required</>}
                </section>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 175 }} className="education">
                <FormHelperText>Education Level *</FormHelperText>
                <Select
                  defaultValue=""
                  style={{
                    width: 175,
                  }}
                  value={data.educationLevel}
                  onChange={(e) =>
                    setData((prev) => {
                      return { ...prev, educationLevel: e };
                    })
                  }
                  placement={"topRight"}
                  options={[
                    {
                      value: "highschool diploma",
                      label: "HighSchool Diploma",
                    },

                    {
                      value: "bachlors degree",
                      label: "Bachelors",
                    },
                    {
                      value: "masters degree",
                      label: "Masters",
                    },
                  ]}
                />

                <section
                  style={{
                    height: "0px",
                    color: "red",
                    fontSize: "13px",
                  }}
                >
                  {error.educationLevel && <>Required</>}
                </section>
              </FormControl>
            </Grid>
          </section>

          <section className="col-lg-7">
            <Grid item xs={12} className="mb-4">
              <TextField
                label="Message"
                multiline
                rows={18}
                placeholder="Why should we consider you for this position?"
                variant="outlined"
                fullWidth
                required
                error={error.applicationDescription}
                name="applicationDescription"
                onChange={handleChange}
                inputProps={{ maxLength: 1500 }}
                sx={{
                  "& textarea": {
                    backgroundColor: "#f8f9fa",

                    borderRadius: "4px",
                    padding: "8px",
                  },
                }}
                value={applicationDescription}
              />
              <section
                style={{
                  height: "0px",
                  color: "red",
                  fontSize: "13px",
                }}
                className="d-flex justify-content-between align-items-center"
              >
                <section
                  style={{
                    height: "0px",
                    color: "red",
                    fontSize: "13px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {error.applicationDescription && <>Required</>}
                </section>
                <section
                  style={{
                    height: "0px",
                    color: `${
                      data.applicationDescription.length === 1500
                        ? "red"
                        : "black"
                    }`,
                    fontSize: "13px",
                    fontFamily: "sans-serif",
                  }}
                >
                  {data.applicationDescription.length}/1500
                </section>
              </section>
            </Grid>
            <div className="container">
              <div
                className="row  "
                // style={{ gap: "1px" }}
              >
                <div className="col-12  col-sm-6  p-0 pr-2">
                  <Grid
                    xs={12}
                    item
                    className="d-flex align-items-start flex-column justify-content-center mb-3"
                  >
                    <section>
                      <input
                        type="file"
                        style={fileInputStyles}
                        onChange={(e) => {
                          const fileType = ["application/pdf"];
                          if (e.target.files[0]) {
                            const size = e.target.files[0].size / (1024 * 1024);

                            if (!fileType.includes(e.target.files[0].type)) {
                              setFileError((prev) => {
                                return {
                                  ...prev,
                                  cv: "Only pdf files are allowed",
                                };
                              });

                              setFiles((prev) => {
                                return {
                                  ...prev,
                                  cv: "",
                                };
                              });
                              return;
                            } else if (size > 10) {
                              setFileError((prev) => {
                                return {
                                  ...prev,
                                  cv: "File size limit 10MB",
                                };
                              });

                              setFiles((prev) => {
                                return {
                                  ...prev,
                                  cv: "",
                                };
                              });
                              return;
                            } else {
                              setFileError((prev) => {
                                return {
                                  ...prev,
                                  cv: null,
                                };
                              });
                              setFiles((prev) => {
                                return {
                                  ...prev,
                                  cv: e.target.files[0],
                                };
                              });
                            }
                          } else {
                            setFiles((prev) => {
                              return {
                                ...prev,
                                cv: "",
                              };
                            });
                            setFileError((prev) => {
                              return {
                                ...prev,
                                cv: "This is required filed",
                              };
                            });
                          }
                        }}
                        id="file"
                      />

                      <label htmlFor="file" style={fileInputLabelStyles}>
                        <AttachFileIcon />
                        {files.cv ? (
                          <span>{files.cv.name.slice(0, 16)}</span>
                        ) : (
                          <span className="upload">Upload CV *</span>
                        )}
                      </label>
                    </section>
                    <section
                      style={{
                        height: "0px",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {fileError.cv && fileError.cv}
                    </section>
                  </Grid>
                </div>
                <div className="col-12 col-sm-6  p-0">
                  <Grid
                    xs={12}
                    item
                    className="d-flex align-items-start flex-column justify-content-content mb-3"
                  >
                    <section>
                      <input
                        type="file"
                        style={fileInputStyles}
                        name="other"
                        id="other-upload"
                        onChange={(e) => {
                          const fileType = ["application/pdf"];
                          if (e.target.files[0]) {
                            const size = e.target.files[0].size / (1024 * 1024);
                            if (!fileType.includes(e.target.files[0].type)) {
                              setFileError((prev) => {
                                return {
                                  ...prev,
                                  other: "Only pdf files are allowed",
                                };
                              });
                              setFiles((prev) => {
                                return {
                                  ...prev,
                                  other: "",
                                };
                              });
                              return;
                            } else if (size > 10) {
                              setFileError((prev) => {
                                return {
                                  ...prev,
                                  cv: "File size limit 10MB",
                                };
                              });

                              setFiles((prev) => {
                                return {
                                  ...prev,
                                  cv: "",
                                };
                              });
                              return;
                            } else {
                              setFileError((prev) => {
                                return {
                                  ...prev,
                                  other: null,
                                };
                              });
                              setFiles((prev) => {
                                return {
                                  ...prev,
                                  other: e.target.files[0],
                                };
                              });
                            }
                          } else {
                            setFiles((prev) => {
                              return {
                                ...prev,
                                other: "",
                              };
                            });
                            setFileError((prev) => {
                              return {
                                ...prev,
                                other: "",
                              };
                            });
                          }
                        }}
                      />

                      <label
                        htmlFor="other-upload"
                        style={fileInputLabelStyles}
                      >
                        <AttachFileIcon />
                        {files.other ? (
                          <span>{files.other.name.slice(0, 16)}</span>
                        ) : (
                          <span className="upload">Related document</span>
                        )}
                      </label>
                    </section>
                    <section
                      style={{
                        height: "0px",
                        color: "red",
                        fontSize: "13px",
                      }}
                    >
                      {fileError.other && fileError.other}
                    </section>
                  </Grid>
                </div>
              </div>
            </div>
          </section>
        </Grid>
      </form>
      <div
        className="container"
        style={{
          marginTop: "15px",
          //   backgroundColor: "black",
        }}
      >
        <div className="row justify-content-center">
          <div className="col-12">
            <section
              style={{
                gap: "100px",
                height: "40px",
                marginLeft: "40px",
                marginRight: "40px",
                backgroundColor: "white",
              }}
              className="d-flex justify-content-center"
            >
              {questions.length === 0 && (
                <>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <button
                      style={{
                        width: "150px",
                        backgroundColor: "#168C9E",
                        color: "white",
                        fontSize: "18px",
                        border: "none",
                        outline: "none",
                      }}
                      type="submit"
                      className="rounded pointer"
                      onClick={() => {
                        handleContinue();
                      }}
                    >
                      Apply
                    </button>
                  )}
                </>
              )}
              {questions.length !== 0 && (
                <button
                  style={{
                    width: "150px",
                    backgroundColor: "#168C9E",
                    color: "white",
                    fontSize: "18px",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={handleNext}
                  className="rounded pointer"
                >
                  Next
                </button>
              )}
            </section>
          </div>
          <div
            className="col-12 d-flex my-2 justify-content-center"
            style={{ backgroundColor: "", height: "20px" }}
          >
            <section className="d-flex flex-column">
              {!applyResponse.success && errors.length !== 0 && (
                <>
                  {errors.map((err) => {
                    if (err === "Phone number and/or Email already used") {
                      return (
                        <section
                          key={err}
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "16px",

                            color: "rgb(179, 12, 12)",
                          }}
                        >
                          {err}
                        </section>
                      );
                    } else if (err === "Operation failed, try again.") {
                      return (
                        <section
                          key={err}
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: "16px",
                            color: "rgb(179, 12, 12)",
                          }}
                        >
                          {err}
                        </section>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplyForm;
