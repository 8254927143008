import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Input,
  TextField,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { CSVLink } from "react-csv";

import { removeToken } from "../../../redux/admin/adminRedux";
import "../styles/hr.css";
import nodata from "../../../../src/assets/nodata.png";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Popconfirm } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  getAllCandidates,
  getPositionCandidates,
} from "../../../redux/candidate/candidateRedux";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import Dialog from "@mui/material/Dialog";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import DialogContent from "@mui/material/DialogContent";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import AttachmentIcon from "@mui/icons-material/Attachment";
function Row({ row, position, ids, setIds, searchParams }) {
  const location = useLocation();
  const tabs = [
    "just-applied",
    "exam",
    "interview",
    "demonstration",
    "onboarding",
    "potential",
  ];

  var splitedPathName = location.pathname.split("/");
  const tab = splitedPathName[splitedPathName.length - 1];
  const stage = tabs.includes(tab) ? `stage=${tab}&` : "stage=all&";

  const date = new Date(row.createdOn);
  const navigate = useNavigate();

  const day = date.getDate();
  const month = date.toLocaleString("default", {
    month: "long",
  });
  const year = date.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;

  const truth = ids.find((item) => item.id === row.id) ? true : false;

  const index = searchParams.has("email")
    ? row.email
        .toLowerCase()
        .indexOf(searchParams.get("email").toLocaleLowerCase())
    : -1;
  // rgb(253, 201, 201)

  return (
    <>
      <TableRow
        style={{
          backgroundColor: `${
            truth
              ? "#c2e7ff"
              : row.visibility === "potential"
              ? "rgb(253, 201, 201)"
              : ""
          }`,
        }}
      >
        <TableCell>
          <input
            checked={truth}
            type="checkbox"
            name={`${row.firstName}`}
            onChange={(e) => {
              var currentIDs = [...ids];
              if (!truth) {
                currentIDs.push(row);
                setIds(currentIDs);
              } else {
                setIds(currentIDs.filter((item) => item.id !== row.id));
              }
            }}
            id="demo"
            style={{
              width: "15px",
              height: "15px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              marginRight: "8px",
              outline: "none",
              cursor: "pointer",
            }}
          />
        </TableCell>
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {row.age}
        </TableCell>
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {row.sex}
        </TableCell>

        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {index !== -1 && searchParams.has("email") ? (
            <>
              {row.email.slice(0, index)}
              <span
                style={{
                  backgroundColor: "yellow",
                  // fontWeight: "bold",
                  fontFamily: "sans-serif",
                  // fontStyle: "italic",
                }}
              >
                {searchParams.get("email")}
              </span>
              {row.email.slice(index + searchParams.get("email").length)}
            </>
          ) : (
            <>{row.email}</>
          )}
        </TableCell>
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {row.phoneNumber}
        </TableCell>
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {row.address}
        </TableCell>

        {row.educationLevel === "highschool diploma" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
              )
            }
          >
            Diploma
          </TableCell>
        )}

        {row.educationLevel === "bachlors degree" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
              )
            }
          >
            Bachelors
          </TableCell>
        )}
        {row.educationLevel === "masters degree" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}&${searchParams.toString()}`
              )
            }
          >
            Masters
          </TableCell>
        )}

        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {row.yearsOfExperience}
        </TableCell>
        {row.applicationStatus === "just-applied" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
              )
            }
          >
            Applied
          </TableCell>
        )}
        {row.applicationStatus === "passed-for-exam" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
              )
            }
          >
            Exam
          </TableCell>
        )}
        {row.applicationStatus === "passed-for-interview" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
              )
            }
          >
            Interview
          </TableCell>
        )}
        {row.applicationStatus === "passed-for-demonstration" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
              )
            }
          >
            Demonstration
          </TableCell>
        )}

        {row.applicationStatus === "onboarding" && (
          <TableCell
            className="pointer"
            onClick={() =>
              navigate(
                `/admin/candidate/${row.id}?${stage}&${searchParams.toString()}`
              )
            }
          >
            Onboarding
          </TableCell>
        )}
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          <a
            href={`${row.cv}`}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noreferrer"
          >
            Here
          </a>
        </TableCell>
        <TableCell
          className="pointer"
          onClick={() =>
            navigate(
              `/admin/candidate/${row.id}?${stage}${searchParams.toString()}`
            )
          }
        >
          {formattedDate}
        </TableCell>
      </TableRow>
    </>
  );
}

function CollapsibleTable({
  searchParams,
  candidates,
  handleSearch,
  position,
  ids,
  setIds,
  dispatch,
  stage,
  positionCandidates,
}) {
  useEffect(() => {
    setIds([]);
  }, [stage, setIds]);

  const [loading, setLoading] = useState(false);

  const bearerToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleDelete = async () => {
    setLoading(true);

    for (var i = 0; i < ids.length; i++) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_URL}/candidates/${ids[i].id}`,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(removeToken());
          localStorage.removeItem("token");
          localStorage.removeItem("email");

          navigate("/login");
        }
      }
    }
    setLoading(false);
    dispatch(getPositionCandidates(position.id));
    dispatch(getAllCandidates());
    setIds([]);
  };
  const handleVisibilityOn = async () => {
    setLoading(true);

    for (var i = 0; i < ids.length; i++) {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_URL}/candidates/change-visibility/${ids[i].id}`,
          { visibility: "visible" },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(removeToken());
          localStorage.removeItem("token");
          localStorage.removeItem("email");

          navigate("/login");
        }
      }
    }
    setLoading(false);
    dispatch(getPositionCandidates(position.id));
    dispatch(getAllCandidates());
    setIds([]);
  };
  const handleVisibilityOff = async () => {
    setLoading(true);

    for (var i = 0; i < ids.length; i++) {
      try {
        await axios.patch(
          `${process.env.REACT_APP_URL}/candidates/change-visibility/${ids[i].id}`,
          { visibility: "potential" },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          }
        );
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch(removeToken());
          localStorage.removeItem("token");
          localStorage.removeItem("email");

          navigate("/login");
        }
      }
    }
    setLoading(false);
    dispatch(getPositionCandidates(position.id));
    dispatch(getAllCandidates());
    setIds([]);
  };

  const columnsToExclude = [
    "id",
    "createdOn",
    "applicationDescription",
    "cv",
    "otherRelevantDocuments",
  ];

  const modifiedData = candidates
    ? handleSearch().map((item) => {
        return Object.keys(item)
          .filter((key) => !columnsToExclude.includes(key))
          .reduce((obj, key) => {
            obj[key] = item[key];
            return obj;
          }, {});
      })
    : [];

  return (
    <>
      <>
        <section
          style={{
            borderBottom: "1px solid black",
            backgroundColor: `${ids.length > 0 ? "#c2e7ff" : "white"}`,
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
          className="d-flex align-items-center justify-content-between px-4 py-2"
        >
          {ids.length === 0 && (
            <>
              <h3
                style={{
                  fontWeight: "600",
                  color: "black",
                  fontFamily: "sans-serif",
                  letterSpacing: "1px",
                }}
              >
                {position && position.title}
              </h3>
              {candidates && modifiedData.length !== 0 && (
                <div>
                  <CSVLink
                    className="download-excel py-2 pointer px-3 rounded"
                    data={modifiedData}
                    filename={"Candidates.csv"}
                    style={{ textDecoration: "none", fontWeight: "bold" }}
                  >
                    Export to Excel
                  </CSVLink>
                </div>
              )}
            </>
          )}
          {ids.length > 0 && (
            <>
              <section className="mr-2" style={{ fontSize: "20px" }}>
                <span className="font-weight-bold">{ids.length}</span> selected
              </section>
              <section className="d-flex align-items-center">
                {stage === "potential" && (
                  <Popconfirm
                    placement="left"
                    title={
                      "Are you sure want to reverse the selected candidates?"
                    }
                    onConfirm={handleVisibilityOn}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Visible">
                      <IconButton size="large">
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </Popconfirm>
                )}
                {(stage !== "potential") & (stage !== "all") ? (
                  <Popconfirm
                    placement="left"
                    title={"Are you sure want to hide the selected candidates?"}
                    onConfirm={handleVisibilityOff}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="hide">
                      <IconButton size="large">
                        <VisibilityOffIcon />
                      </IconButton>
                    </Tooltip>
                  </Popconfirm>
                ) : null}
                {(stage !== "all") & (stage !== "potential") ? (
                  <>
                    <RollBackDialog
                      stage={stage}
                      ids={ids}
                      position={position}
                      dispatch={dispatch}
                      setIds={setIds}
                      bearerToken={bearerToken}
                    />

                    <NextStageDialog
                      stage={stage}
                      ids={ids}
                      position={position}
                      dispatch={dispatch}
                      setIds={setIds}
                      bearerToken={bearerToken}
                    />
                  </>
                ) : null}
                <EmailDialog
                  ids={ids}
                  setIds={setIds}
                  bearerToken={bearerToken}
                />

                <Popconfirm
                  placement="left"
                  title={"Are you sure want to delete the selected candidates?"}
                  onConfirm={handleDelete}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Delete">
                    <IconButton size="large">
                      <DeleteIcon style={{ color: "rgb(179, 12, 12)" }} />
                    </IconButton>
                  </Tooltip>
                </Popconfirm>
              </section>
            </>
          )}
        </section>
        {loading ? (
          <div
            style={{ height: "300px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="d-flex flex-column align-items-center">
              <CircularProgress />
              <section className="mt-3" style={{ fontSize: "20px" }}>
                Loading. Please wait.
              </section>
            </div>
          </div>
        ) : (
          <>
            {positionCandidates ? (
              <TableContainer component={Paper}>
                <Table id="table-to-xls" aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <input
                          checked={ids.length !== 0 ? true : false}
                          type="checkbox"
                          name="checkedAll"
                          disabled={handleSearch().length === 0}
                          onChange={(e) => {
                            if (ids.length === handleSearch().length) {
                              setIds([]);
                            } else {
                              const currentIDs = handleSearch().map(
                                (item) => item
                              );

                              setIds(currentIDs);
                            }
                          }}
                          id="demo"
                          style={{
                            width: "15px",
                            height: "15px",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            marginRight: "8px",
                            outline: "none",
                            cursor: "pointer",
                          }}
                        />
                      </TableCell>
                      <TableCell style={{ width: "140px", fontWeight: "bold" }}>
                        Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Age</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Sex</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Email
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Phone Number
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Address
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Education
                      </TableCell>
                      <TableCell style={{ width: "141px", fontWeight: "bold" }}>
                        Experience
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Status
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>CV</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Application Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates &&
                      handleSearch().map((row) => (
                        <Row
                          key={row.id}
                          position={position}
                          row={row}
                          ids={ids}
                          setIds={setIds}
                          searchParams={searchParams}
                        />
                      ))}
                  </TableBody>
                </Table>
                {candidates && handleSearch().length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "30px 0px",
                    }}
                  >
                    <section style={{ width: "400px", height: "300px" }}>
                      <img
                        src={nodata}
                        style={{ width: "100%", height: "100%" }}
                        alt=""
                      />
                    </section>
                  </div>
                )}
              </TableContainer>
            ) : (
              <div
                style={{ height: "300px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="d-flex flex-column align-items-center">
                  <CircularProgress />
                  <section className="mt-3" style={{ fontSize: "20px" }}>
                    Loading candidates...
                  </section>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default CollapsibleTable;

export function EmailDialog({ ids, setIds, bearerToken }) {
  const [open, setOpen] = useState(false);
  const [validEmails, setValidEmails] = useState(null);
  const [links, setLinks] = useState([]);
  const [link, setLink] = useState({ name: "", url: "" });
  const [openAttachement, setOpenAttachement] = useState(false);
  const [content, setContent] = useState({ subject: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailList = ids.map((ids) => ids.email);
  const emails = emailList.join(", ");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState({ open: false, error: false });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIds([]);
    setLink({ name: "", url: "" });
    setLinks([]);
    setOpen(false);
  };

  const handleSend = async () => {
    setLoading(true);

    const emails = validEmails.split(",");
    const trimmedEmails = emails.map((element) => element.trim());

    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/email`,
        {
          emails: trimmedEmails,
          subject: content.subject,
          text: value,
          links,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      setLoading(false);
      setShow((prev) => {
        return { error: false, open: true };
      });
      setLink({ name: "", url: "" });
      setLinks([]);
    } catch (err) {
      setShow((prev) => {
        return { error: true, open: true };
      });
      setLoading(false);
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      }
    }
  };
  useEffect(() => {
    setValidEmails(emails);
  }, [ids, emails]);

  const handleHide = () => {
    setShow((prev) => {
      return { ...prev, open: false };
    });
  };

  const [value, setValue] = useState("");
  var toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ];
  const module = {
    toolbar: toolbarOptions,
  };
  return (
    <div>
      <Tooltip title="Email">
        <IconButton onClick={handleClickOpen} size="large">
          <MailOutlineRoundedIcon />
        </IconButton>
      </Tooltip>
      <Snackbar open={show.open} autoHideDuration={6000} onClose={handleHide}>
        <Alert
          onClose={handleHide}
          severity={show.error ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {show.error ? (
            <span style={{ fontFamily: "sans-serif" }}>
              Failed to send email. Try again!
            </span>
          ) : (
            <span style={{ fontFamily: "sans-serif" }}>
              Email is successfully sent to
              <span className="mx-1" style={{ fontFamily: "sans-serif" }}>
                {validEmails && validEmails.split(",").length}
              </span>
              candidate
              {validEmails && validEmails.split(",").length > 1 && <>s</>}
            </span>
          )}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%", gap: "450px", backgroundColor: "#e0e0e0" }}
        >
          <section style={{ fontFamily: "sans-serif" }}>Email</section>

          <Tooltip title="Close">
            <IconButton onClick={handleClose} className="pointer" size="large">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <div className="container py-2">
            <div className="row ">
              <div className="col-12 p-0 pb-2 my-2">
                <Input
                  placeholder="To"
                  className="w-100"
                  value={validEmails ? validEmails : ""}
                  onChange={(e) => setValidEmails(e.target.value)}
                />
              </div>
              <div className="col-12 p-0 pb-2 my-2">
                <Input
                  placeholder="Subject"
                  className="w-100"
                  onChange={(e) => {
                    setContent(() => {
                      return { subject: e.target.value };
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row py-4 ">
              <div className="col-12 p-0" style={{ height: "320px" }}>
                <ReactQuill
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  style={{ height: "100%" }}
                  modules={module}
                />
              </div>
            </div>
          </div>
          <div style={{ height: "40px" }}></div>
          <div className="my-4">
            <section className="d-flex align-items-center mb-2">
              <section
                style={{ color: "black", fontSize: "18px", width: "170px" }}
              >
                Text to display:{" "}
              </section>
              <Input
                className="w-100 ml-2"
                value={link.name}
                onChange={(e) =>
                  setLink((prev) => {
                    return { ...prev, name: e.target.value };
                  })
                }
              />
            </section>
            <section className="d-flex align-items-center mb-2">
              <section
                style={{ color: "black", fontSize: "18px", width: "70px" }}
              >
                Link to:{" "}
              </section>
              <Input
                className="w-100 ml-2"
                value={link.url}
                onChange={(e) =>
                  setLink((prev) => {
                    return { ...prev, url: e.target.value };
                  })
                }
              />
            </section>
            <section className="d-flex justify-content-between align-items-center">
              <Button
                onClick={() => {
                  setLinks([...links, link]);
                  setLink({ name: "", url: "" });
                }}
                disabled={!link.url.trim() || !link.name.trim()}
              >
                Add
              </Button>
              {link.url.trim() && (
                <a
                  className="mb-2"
                  style={{ color: "blue" }}
                  href={`${link.url}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Test this link
                </a>
              )}
            </section>
          </div>
          <div className="col-12 p-0 pb-2 my-2">
            {links.map((link, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center"
              >
                <a
                  href={`${link.url}`}
                  style={{ color: "blue", cursor: "pointer" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {link.name}
                </a>
                <IconButton
                  onClick={() => {
                    const newLinks = [
                      ...links.slice(0, index),
                      ...links.slice(index + 1, links.length),
                    ];
                    setLinks(newLinks);
                  }}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            ))}
          </div>
          {/* </div> */}
          {/* </div> */}
          <section className="d-flex justify-content-end">
            {loading ? (
              <CircularProgress />
            ) : (
              <div
                className="d-flex align-items-center justify-content-start"
                style={{ gap: "10px" }}
              >
                <Button onClick={handleSend}>Send</Button>
                <Tooltip title={`Attach a link`}>
                  <IconButton
                    onClick={() => {
                      // const newLinks = [
                      //   ...links.slice(0, index),
                      //   ...links.slice(index + 1, links.length),
                      // ];
                      // setLinks(newLinks);
                    }}
                    size="large"
                  >
                    <AttachmentIcon />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </section>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export function NextStageDialog({
  ids,
  stage,
  position,
  dispatch,
  setIds,
  bearerToken,
}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  var nextStage = "";
  if (stage === "just-applied") {
    nextStage = "Exam";
  } else if (stage === "passed-for-exam") {
    nextStage = "Interview";
  } else if (stage === "passed-for-interview") {
    if (!position.skipDemo) {
      nextStage = "Demonstration";
    } else {
      nextStage = "Onboarding";
    }
  } else if (stage === "passed-for-demonstration") {
    nextStage = "Onboarding";
  }

  const [response, setResponse] = useState({ completed: false, error: "" });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDone = () => {
    setIds([]);
    setResponse({ completed: false, error: "" });
  };

  const handleClose = () => {
    setIds([]);
    setResponse({ completed: false, error: "" });

    setOpen(false);
  };

  const handleAgree = async () => {
    const collections = ids.map((item) => item.id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/positions/keep-candidate`,
        { positionId: position.id, candidateIds: collections },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 201) {
        setResponse({ completed: true, error: "" });

        dispatch(getPositionCandidates(position.id));
        dispatch(getAllCandidates());
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
      }
    }
  };

  return (
    <div>
      <Tooltip title="Next Stage">
        <IconButton
          onClick={handleClickOpen}
          disabled={stage === "onboarding"}
          size="large"
        >
          <SkipNextIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!response.completed && (
          <>
            <DialogTitle
              id="alert-dialog-title"
              className="d-flex justify-content-between"
            >
              These candidates will be moved to ({nextStage})!
              <Button onClick={handleClose} className="ml-3">
                Close
              </Button>
            </DialogTitle>
            <DialogContent>
              <ol>
                {ids.map((item) => (
                  <li key={ids.id}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {item.firstName} {item.lastName}
                    </span>
                    =&gt; {item.email}
                  </li>
                ))}
              </ol>
            </DialogContent>
            <section className="d-flex justify-content-center mt-3 mb-3">
              <Button onClick={handleAgree} className="ml-3">
                Proceed
              </Button>
            </section>
          </>
        )}
        {response.completed && (
          <>
            <DialogTitle
              id="alert-dialog-title"
              className="d-flex justify-content-between"
            >
              {!response.error && (
                <span>Candidates successfully moved to {nextStage} stage</span>
              )}
              {response.error && (
                <span>An Error has ocurred please try again!</span>
              )}
            </DialogTitle>
            <section className="mb-3 d-flex justify-content-center align-items-center">
              <Button onClick={handleDone}>Close</Button>
            </section>
          </>
        )}
      </Dialog>
    </div>
  );
}

export function RollBackDialog({
  ids,
  stage,
  position,
  dispatch,
  setIds,
  bearerToken,
}) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  var prevStage = "";
  if (stage === "passed-for-exam") {
    prevStage = "Applied";
  } else if (stage === "passed-for-interview") {
    prevStage = "Exam";
  } else if (stage === "passed-for-demonstration") {
    prevStage = "Interview";
  } else if (stage === "onboarding") {
    if (!position.skipDemo) {
      prevStage = "Demonstration";
    } else {
      prevStage = "Interview";
    }
  }

  const [response, setResponse] = useState({ completed: false, error: "" });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDone = () => {
    setIds([]);
    setResponse({ completed: false, error: "" });
  };

  const handleClose = () => {
    setResponse({ completed: false, error: "" });

    setOpen(false);
  };

  const handleAgree = async () => {
    const collections = ids.map((item) => item.id);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/positions/rollback-candidate`,
        { positionId: position.id, candidateIds: collections },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 201) {
        setResponse({ completed: true, error: "" });

        dispatch(getPositionCandidates(position.id));
        dispatch(getAllCandidates());
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
      }
    }
  };

  return (
    <div>
      <Tooltip title="Previous Stage">
        <IconButton
          onClick={handleClickOpen}
          disabled={stage === "just-applied"}
          size="large"
        >
          <SkipPreviousIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!response.completed && (
          <>
            <DialogTitle
              id="alert-dialog-title"
              className="d-flex justify-content-between"
            >
              These candidates will be moved back to ({prevStage})!
              <Button onClick={handleClose} className="ml-3">
                Close
              </Button>
            </DialogTitle>
            <DialogContent>
              <ol>
                {ids.map((item) => (
                  <li key={ids.id}>
                    <span style={{ color: "black", fontWeight: "bold" }}>
                      {item.firstName} {item.lastName}
                    </span>
                    =&gt; {item.email}
                  </li>
                ))}
              </ol>
            </DialogContent>
            <section className="d-flex justify-content-center mt-3 mb-3">
              <Button onClick={handleAgree} className="ml-3">
                Proceed
              </Button>
            </section>
          </>
        )}
        {response.completed && (
          <>
            <DialogTitle
              id="alert-dialog-title"
              className="d-flex justify-content-between"
            >
              {!response.error && (
                <span>Candidates successfully moved to {prevStage} stage</span>
              )}
              {response.error && (
                <span>An Error has ocurred please try again!</span>
              )}
            </DialogTitle>
            <section className="mb-3 d-flex justify-content-center align-items-center">
              <Button onClick={handleDone}>Close</Button>
            </section>
          </>
        )}
      </Dialog>
    </div>
  );
}
