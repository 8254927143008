import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import axios from "axios";
function Footer() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ error: false, msg: "" });
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);

  const handleSubscribe = async () => {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var isValid = emailPattern.test(email);
    if (!isValid) {
      setError({ error: true, msg: "Please enter a valid Email address" });
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/email/news-letter-email`,
          { email: email }
        );

        if (response.status === 201) {
          setSubscribeSuccess(true);
          setEmail("");
          setError({ error: false, msg: "" });
        }
      } catch (err) {
        if (err.response.status === 404) {
          setError({ error: true, msg: "Already subscribed" });
        } else if (err.response.status === 400) {
          setError({ error: true, msg: "Please enter a valid Email address" });
        }
      }
    }
  };

  const handleClose = () => {
    setSubscribeSuccess(false);
  };

  return (
    <section style={{ minWidth: "284px" }}>
      <div
        style={{
          padding: "50px 0px",
          backgroundColor: "#F6F9FF",
          borderTop: "1px solid #e1ecff",
          borderBottom: "1px solid #e1ecff",
          fontSize: "14px",
        }}
      >
        <div className="container w-100">
          <div className="row justify-content-center">
            <div
              className="col-lg-12"
              style={{
                color: "#168c9e",
              }}
            >
              <h4
                className="text-center"
                style={{ fontWeight: "bold", color: "#168c9e" }}
              >
                Subscribe to Our Newsletter
              </h4>
            </div>
            <div className="col-lg-6">
              <div
                action=""
                style={{
                  padding: "20px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                    backgroundColor: "white",
                    padding: "6px 5px",
                    border: "0.5px solid #e1ecff",
                  }}
                >
                  <section
                    style={{ width: "80%", gap: "3px" }}
                    className="d-flex flex-column "
                  >
                    <input
                      style={{
                        width: "100%",
                        padding: "5px",
                        fontSize: "15px",
                        height: "40px",
                        border: "none",
                      }}
                      type="email"
                      placeholder="Email Address(*)"
                      onChange={(e) => setEmail(e.target.value)}
                      name=""
                      id=""
                      value={email}
                    />
                    {error.error && !subscribeSuccess && (
                      <section
                        style={{
                          fontSize: "12px",
                          color: "#B30C0C",
                          lineHeight: "1",
                        }}
                      >
                        <>{error.msg}</>
                      </section>
                    )}
                  </section>
                  <button
                    style={{
                      maxHeight: "55px",

                      backgroundColor: "#168c9e",
                      color: "white",
                      border: "none",
                      fontSize: "16px",
                      padding: "0 30px",
                    }}
                    onClick={handleSubscribe}
                    className="rounded pointer"
                  >
                    Subscribe
                  </button>
                  <Snackbar
                    open={subscribeSuccess}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert
                      onClose={handleClose}
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Subscribed successfully!
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "60px 0px 30px 0px",
          borderBottom: "1px solid #e1ecff",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-md-12"
              style={{ margin: "24px 0px 30px 0px", padding: "0px 12px" }}
            >
              <a href="https://icogacc.com/">
                <img
                  src="https://icogacc.com/storage/myfiles/logo-web_sm.png"
                  alt=""
                  style={{
                    width: "80px",
                    height: "50px",
                    marginBottom: "15px",
                  }}
                />
              </a>
              <section style={{ fontSize: "14px" }}>
                <p style={{ lineHeight: "24px", color: "#333333" }}>
                  iCog Anyone Can Code, a subsidiary of iCog Labs — Ethiopia’s
                  first Artificial Intelligence and robotics lab, works on
                  community outreach and research with the focus of tech
                  education and entrepreneurship.
                </p>
                <p>
                  <strong style={{ color: "#333333", marginRight: "4px" }}>
                    Follow us on:
                  </strong>
                  <a
                    style={{ color: "#087B8D", textDecoration: "none" }}
                    href="https://www.facebook.com/icogacc"
                  >
                    Facebook |
                  </a>
                  <a
                    style={{
                      color: "#087B8D",
                      textDecoration: "none",
                      marginLeft: "3px",
                    }}
                    href="https://twitter.com/iCog_ACC"
                  >
                    Twitter |
                  </a>
                  <a
                    style={{
                      color: "#087B8D",
                      textDecoration: "none",
                      marginLeft: "3px",
                    }}
                    href="https://www.instagram.com/icogacc/"
                  >
                    Instagram |
                  </a>
                  <a
                    style={{
                      color: "#087B8D",
                      textDecoration: "none",
                      marginLeft: "3px",
                    }}
                    href="https://www.linkedin.com/company/icog-anyone-can-code"
                  >
                    Linkedin
                  </a>
                </p>
              </section>
            </div>

            <div className="col-lg-2 col-6">
              <section style={{ padding: "24px 0px 30px 0px" }}>
                <h4
                  style={{
                    color: "#168C9E",
                    fontSize: "16px",

                    fontWeight: "bold",
                  }}
                >
                  USEFUL LINKS
                </h4>
                <ul
                  style={{
                    listStyle: "none",
                    padding: "0px 10px",
                  }}
                >
                  <li style={{ marginBottom: "10px" }}>
                    <i className="bi bi-chevron-right"></i>
                    <a
                      href="https://icogacc.com/about+us"
                      style={{
                        fontSize: "14px",
                        color: "#013289",
                        textDecoration: "none",
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <i className="bi bi-chevron-right"></i>
                    <a
                      href="https://icogacc.com/impact"
                      style={{
                        fontSize: "14px",
                        color: "#013289",
                        textDecoration: "none",
                      }}
                    >
                      Impact
                    </a>
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    <i className="bi bi-chevron-right"></i>
                    <a
                      href="https://career.icogacc.com/"
                      style={{
                        fontSize: "14px",
                        color: "#013289",
                        textDecoration: "none",
                      }}
                    >
                      Career
                    </a>
                  </li>
                </ul>
              </section>
            </div>
            <div className="col-lg-3 col-md-12 text-md-start">
              <section
                style={{
                  padding: "24px 0px 30px 0px",
                }}
              >
                <h4
                  style={{
                    fontSize: "16px",
                    color: "#168C9E",
                    fontWeight: "bold",
                  }}
                >
                  CONTACT US
                </h4>
                <p style={{ color: "#333333", fontSize: "14px" }}>
                  Namibia St, Lingo Tower, 12th Floor
                </p>
                <p style={{ color: "#333333", fontSize: "14px" }}>
                  Addis Ababa, Ethiopia
                </p>

                <p style={{ color: "#333333", fontSize: "14px" }}>
                  <strong style={{ fontWeight: "bold" }}>Phone : </strong>
                  +251-(0)-904262728
                  <br />
                  <strong style={{ fontWeight: "bold" }}>Email : </strong>
                  <a
                    href="mailto:info@icog-acc.com"
                    style={{ color: "#087B8D" }}
                  >
                    info@icog-acc.com
                  </a>
                </p>
                <p style={{ fontSize: "16px", color: "#212529" }}>
                  Addis Ababa, Ethiopia
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <section
        style={{ backgroundColor: "#F6F9FF", height: "50px" }}
        className="d-flex align-items-center "
      >
        <div className="container ">
          <div
            style={{
              fontSize: "14px",

              color: "#168C9E",
            }}
            className="text-center"
          >
            © Copyright 2023
            <strong className="ml-1"> iCog Anyone Can Code</strong> . All Rights
            Reserved
          </div>
        </div>
      </section>
    </section>
  );
}

export default Footer;
