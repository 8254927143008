import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate } from "react-router-dom";
import "../styles/styles.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

function Header() {
  const navigator = useNavigate();
  const [hover, setHover] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [modalClicked, setModalClicked] = useState({
    about: false,
    impact: false,
    career: false,
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  const [hasShadow, setHasShadow] = useState(false);

  const handleScroll = () => {
    const threshold = 8;
    const isScrolling = window.scrollY > threshold;
    setHasShadow(isScrolling);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {windowSize.width > 1150 && (
        <header
          className="fixed-top d-flex justify-content-center"
          style={{
            height: "117.3px",
            width: "100%",
            zIndex: "100",
            backgroundColor: "white",
            boxShadow: `${
              hasShadow ? "0px 2px 20px rgba(1, 12, 14, 0.2)" : " "
            } `,
          }}
        >
          <div
            className="container-fluid container-xl d-flex align-items-center justify-content-between"
            style={{ width: "1290px", height: "100%" }}
          >
            <a
              href="https://icogacc.com/"
              alt="iCog-ACC Image"
              className="d-flex align-items-center"
              style={{ width: "147.92px", height: "89.98px" }}
            >
              <img
                src="https://icogacc.com/storage/myfiles/logo-web_sm.png"
                alt=""
                style={{ width: "100%", height: "100%" }}
              />
            </a>
            <nav className="navbar">
              <ul className="d-flex list-unstyled align-items-center">
                <li onMouseEnter={() => setHover((prev) => "home")}>
                  <a
                    className="nav-link header-link"
                    href="https://icogacc.com"
                    style={{ fontWeight: "600" }}
                  >
                    Home
                  </a>
                </li>

                <li
                  onMouseEnter={() => setHover("about")}
                  onMouseLeave={() => setHover("")}
                  style={{ position: "relative" }}
                >
                  <section className="nav-link pointer header-link d-flex align-items-center">
                    <span style={{ fontWeight: "600" }}>About Us</span>
                    <KeyboardArrowDownIcon />
                  </section>

                  {hover === "about" && (
                    <ul
                      className="list-unstyled rounded career-dropdown"
                      style={{
                        visibility: `${
                          hover === "about" ? "visible" : "hidden"
                        }`,
                        opacity: `${hover === "about" ? 1 : 0}`,
                      }}
                      onMouseEnter={() => setHover("about")}
                      onMouseLeave={() => setHover("")}
                    >
                      <a
                        className="nav-link pointer mb-2 header-link"
                        href="https://icogacc.com/about+us/who+are+we"
                        style={{ padding: 0 }}
                      >
                        Who Are We
                      </a>
                      <a
                        className="nav-link pointer  header-link"
                        onClick={() => navigator("internships")}
                        style={{ padding: 0 }}
                        href="https://icogacc.com/about+us/our+team"
                      >
                        Our Team
                      </a>
                    </ul>
                  )}
                </li>

                <li
                  onMouseEnter={() => setHover("about")}
                  onMouseLeave={() => setHover("")}
                  style={{ position: "relative" }}
                >
                  <section className="nav-link pointer header-link d-flex align-items-center">
                    <span style={{ fontWeight: "600" }}>Impact</span>
                    <KeyboardArrowDownIcon />
                  </section>

                  {hover === "impact" && (
                    <ul
                      className="list-unstyled rounded career-dropdown"
                      style={{
                        visibility: `${
                          hover === "impact" ? "visible" : "hidden"
                        }`,
                        opacity: `${hover === "impact" ? 1 : 0}`,
                      }}
                      onMouseEnter={() => setHover("impact")}
                      onMouseLeave={() => setHover("")}
                    >
                      <a
                        className="nav-link pointer mb-2 header-link"
                        href="https://aysrh.icogacc.com/"
                        style={{ padding: 0 }}
                      >
                        AYSRH
                      </a>
                      <a
                        className="nav-link pointer  header-link"
                        onClick={() => navigator("internships")}
                        style={{ padding: 0 }}
                        href="https://impact.icogacc.com/"
                      >
                        Social Impact
                      </a>
                      <a
                        className="nav-link pointer  header-link"
                        onClick={() => navigator("internships")}
                        style={{ padding: 0 }}
                        href="https://icogacc.com/impact/digital+literacy"
                      >
                        Digital Literacy
                      </a>
                      <a
                        className="nav-link pointer  header-link"
                        onClick={() => navigator("internships")}
                        style={{ padding: 0 }}
                        href="https://icogacc.com/impact/solve+it"
                      >
                        Solve IT
                      </a>
                    </ul>
                  )}
                </li>

                <li onMouseEnter={() => setHover((prev) => "partners")}>
                  <a
                    className="nav-link header-link"
                    href="https://icogacc.com/partners"
                    style={{ fontWeight: "600" }}
                  >
                    Partners
                  </a>
                </li>

                <li onMouseEnter={() => setHover((prev) => "blog")}>
                  <a
                    className="nav-link header-link"
                    href="https://icog.ghost.io/"
                    style={{ fontWeight: "600" }}
                  >
                    Blog
                  </a>
                </li>
                <li onMouseEnter={() => setHover((prev) => "truck")}>
                  <a
                    className="nav-link header-link"
                    href="https://digitruckethiopia.icogacc.com/"
                    style={{ fontWeight: "600" }}
                  >
                    DigiTruck ET
                  </a>
                </li>

                <li style={{ position: "relative" }}>
                  <section
                    className="nav-link pointer header-link"
                    onMouseEnter={() => setHover((prev) => "career")}
                    onMouseLeave={() => setHover((prev) => "")}
                    style={{ fontWeight: "600" }}
                  >
                    <span style={{ fontWeight: "600" }}>Career</span>
                    <KeyboardArrowDownIcon />
                  </section>
                  {hover === "career" && (
                    <ul
                      className="list-unstyled rounded career-dropdown"
                      style={{
                        visibility: `${
                          hover === "career" ? "visible" : "hidden"
                        }`,
                        opacity: `${hover === "career" ? 1 : 0}`,
                      }}
                      onMouseEnter={() => setHover((prev) => "career")}
                      onMouseLeave={() => setHover((prev) => "")}
                    >
                      <Link
                        className="nav-link pointer mb-2 header-link"
                        to="/"
                        style={{ padding: 0 }}
                      >
                        Jobs
                      </Link>

                      <Link
                        className="nav-link pointer mb-2 header-link"
                        to="/internships"
                        style={{ padding: 0 }}
                      >
                        Internships
                      </Link>
                    </ul>
                  )}
                </li>

                <li
                  style={{
                    backgroundColor: "#168C9E",
                  }}
                  className="d-flex justify-content-center align-items-center rounded ml-4"
                >
                  <a
                    className="nav-link"
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                    }}
                    href="https://register.icogacc.com/"
                  >
                    Register
                  </a>
                </li>
                {/* <li
                  style={{
                    backgroundColor: "#168C9E",
                  }}
                  className="d-flex justify-content-center align-items-center rounded ml-4"
                >
                  <a
                    className="nav-link"
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                      letterSpacing: "1px",
                    }}
                    href="https://donation.icogacc.com/register"
                  >
                    Donate
                  </a>
                </li> */}
              </ul>
            </nav>
          </div>
        </header>
      )}
      {windowSize.width <= 1150 && (
        <>
          <header
            className="fixed-top d-flex justify-content-center"
            style={{
              height: "117.3px",
              width: "100%",
              zIndex: "100",
              backgroundColor: "white",
              boxShadow: `${
                hasShadow ? "0px 2px 20px rgba(1, 12, 14, 0.2)" : " "
              } `,
            }}
          >
            {openModal && (
              <div
                style={{
                  zIndex: "110",
                  height: "100%",
                  background: "#020F1199",
                }}
                className="fixed-top w-100  p-4"
              >
                <section className="h-100">
                  <section className="d-flex justify-content-end align-items-center mb-3">
                    <CloseIcon
                      style={{ color: "white", fontSize: "30px" }}
                      className="pointer"
                      onClick={() => {
                        setModalClicked({
                          about: false,
                          impact: false,
                          career: false,
                        });
                        setOpenModal(false);
                      }}
                    />
                  </section>
                  <section
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      overflowY: "scroll",
                      height: "94%",
                    }}
                  >
                    <nav className="navbar">
                      <ul className="list-unstyled py-3 w-100">
                        <li className="py-2 d-flex align-items-center justify-content-between mr-2">
                          <a
                            className="nav-link header-link"
                            href="https://icogacc.com"
                            style={{ fontWeight: "600" }}
                          >
                            Home
                          </a>
                        </li>

                        <li className="py-2 d-flex flex-column mr-2">
                          <section
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => {
                              setModalClicked((prev) => {
                                return {
                                  ...prev,
                                  about: !modalClicked.about,
                                };
                              });
                            }}
                          >
                            <div
                              className="nav-link header-link"
                              style={{ fontWeight: "600" }}
                            >
                              About Us
                            </div>
                            <KeyboardArrowDownIcon />
                          </section>
                          {modalClicked.about && (
                            <ul
                              className="list-unstyled my-2 p-4 ml-3"
                              style={{
                                background: "#fff",
                                boxShadow:
                                  "0px 0px 30px rgba(12, 133, 151, 0.25)",
                              }}
                            >
                              <a
                                className="nav-link  pointer mb-3 header-link"
                                href="https://icogacc.com/about+us/who+are+we"
                                style={{ padding: 0, fontWeight: "500" }}
                              >
                                Who Are We
                              </a>
                              <a
                                className="nav-link  pointer  header-link"
                                onClick={() => navigator("internships")}
                                style={{ padding: 0, fontWeight: "500" }}
                                href="https://icogacc.com/about+us/our+team"
                              >
                                Our Team
                              </a>
                            </ul>
                          )}
                        </li>

                        <li className="py-2 d-flex flex-column mr-2">
                          <section
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => {
                              setModalClicked((prev) => {
                                return {
                                  ...prev,
                                  impact: !modalClicked.impact,
                                };
                              });
                            }}
                          >
                            <div
                              className="nav-link header-link"
                              style={{ fontWeight: "600" }}
                            >
                              Impact
                            </div>
                            <KeyboardArrowDownIcon />
                          </section>
                          {modalClicked.impact && (
                            <ul
                              className="list-unstyled my-2 p-4 ml-3"
                              style={{
                                background: "#fff",
                                boxShadow:
                                  "0px 0px 30px rgba(12, 133, 151, 0.25)",
                              }}
                            >
                              <a
                                className="nav-link  pointer mb-3 header-link"
                                href="https://icogacc.com/impact/public+workshops"
                                style={{ padding: 0, fontWeight: "500" }}
                              >
                                Public WorkShops
                              </a>
                              <a
                                className="nav-link  pointer mb-3  header-link"
                                onClick={() => navigator("internships")}
                                style={{ padding: 0, fontWeight: "500" }}
                                href="https://aysrh.icogacc.com/"
                              >
                                AYSRH
                              </a>
                              <a
                                className="nav-link  pointer mb-3 header-link"
                                onClick={() => navigator("internships")}
                                style={{ padding: 0, fontWeight: "500" }}
                                href="https://icogacc.com/impact/girls+can+code"
                              >
                                Girls Can Code
                              </a>
                              <a
                                className="nav-link  pointer mb-3 header-link"
                                onClick={() => navigator("internships")}
                                style={{ padding: 0, fontWeight: "500" }}
                                href="https://icogacc.com/impact/solve+it"
                              >
                                Solve IT
                              </a>
                              <a
                                className="nav-link  pointer header-link"
                                onClick={() => navigator("internships")}
                                style={{ padding: 0, fontWeight: "500" }}
                                href="https://impact.icogacc.com/"
                              >
                                Social Impact
                              </a>
                            </ul>
                          )}
                        </li>
                        <li className="py-2 d-flex align-items-center justify-content-between mr-2">
                          <a
                            className="nav-link header-link"
                            href="https://icogacc.com/partners"
                            style={{ fontWeight: "600" }}
                          >
                            Partners
                          </a>
                        </li>
                        <li className="py-2 d-flex align-items-center justify-content-between mr-2">
                          <a
                            className="nav-link header-link"
                            href="https://icogacc.com/blog"
                            style={{ fontWeight: "600" }}
                          >
                            Blog
                          </a>
                        </li>
                        <li className="py-2 d-flex align-items-center justify-content-between mr-2">
                          <a
                            className="nav-link header-link"
                            href="https://digitruckethiopia.icogacc.com/"
                            style={{ fontWeight: "600" }}
                          >
                            DigitTruck ET
                          </a>
                        </li>
                        <li className="py-2 d-flex flex-column mb-3 mr-2">
                          <section
                            className="d-flex align-items-center justify-content-between"
                            onClick={() => {
                              setModalClicked((prev) => {
                                return {
                                  ...prev,
                                  career: !modalClicked.career,
                                };
                              });
                            }}
                          >
                            <div
                              className="nav-link header-link"
                              style={{ fontWeight: "600" }}
                            >
                              Career
                            </div>
                            <KeyboardArrowDownIcon />
                          </section>
                          {modalClicked.career && (
                            <ul
                              className="list-unstyled my-2 p-4 ml-3"
                              style={{
                                // visibility: `${
                                //   hover === "about" ? "visible" : "hidden"
                                // }`,
                                // opacity: `${hover === "about" ? 1 : 0}`,
                                // backgroundColor: "red",
                                background: "#fff",
                                boxShadow:
                                  "0px 0px 30px rgba(12, 133, 151, 0.25)",
                              }}
                              onMouseEnter={() => setHover("about")}
                              onMouseLeave={() => setHover("")}
                            >
                              <Link
                                className="nav-link  pointer mb-3 header-link"
                                to="/"
                                style={{ padding: 0, fontWeight: "500" }}
                              >
                                Jobs
                              </Link>
                              <Link
                                className="nav-link  pointer  header-link"
                                style={{ padding: 0, fontWeight: "500" }}
                                to="/internships"
                              >
                                Internships
                              </Link>
                            </ul>
                          )}
                        </li>

                        <li
                          style={{
                            backgroundColor: "#168C9E",
                            fontSize: "20px",
                          }}
                          className="d-flex mb-3  mx-3 rounded  py-2"
                        >
                          <a
                            className="nav-link"
                            style={{
                              color: "white",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                            href="https://donate.icogacc.com/"
                          >
                            Register
                          </a>
                        </li>
                        <li
                          style={{
                            backgroundColor: "#168C9E",
                            fontSize: "20px",
                          }}
                          className="d-flex  mx-3 rounded  py-2"
                        >
                          <a
                            className="nav-link"
                            style={{
                              color: "white",
                              fontSize: "20px",
                              fontWeight: "600",
                            }}
                            href="https://donation.icogacc.com/"
                          >
                            Donate
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </section>
                </section>
              </div>
            )}
            <div
              className="container-fluid container-xl d-flex align-items-center justify-content-between"
              style={{ width: "1290px", height: "100%" }}
            >
              <a
                href="https://icogacc.com/"
                className="d-flex align-items-center"
                style={{ width: "147.92px", height: "89.98px" }}
              >
                <img
                  src="https://icogacc.com/storage/myfiles/logo-web_sm.png"
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </a>
              <MenuIcon
                className="pointer "
                style={{ color: "#168c9e", fontSize: "40px" }}
                onClick={() => setOpenModal(true)}
              />
            </div>
          </header>
        </>
      )}
    </>
  );
}

export default Header;
