import { createSlice } from "@reduxjs/toolkit";

const positionSlice = createSlice({
  name: "position",
  initialState: {
    allPositions: null,
    internships: null,
    serverError: null,
    jobs: null,
    allPositionsError: "",
    position: {},
    positionError: "",
    positionID: "",
    applyData: new FormData(),
    applyPositionError: "",
    applyPositionSuccess: false,
    allQuestions: null,
    adminInternship: null,
    adminJobs: null,
    adminDetail: null,
    allAdminPositions: null,
  },
  reducers: {
    getAllPositions: (state, action) => {},
    getAllAdminPositions: (state, action) => {},
    getAllAdminPositionsSuccess: (state, action) => {
      state.allAdminPositions = action.payload;
      const internship = action.payload.filter(
        (position) => position.positionType === "internship"
      );
      const jobs = action.payload.filter(
        (position) => position.positionType !== "internship"
      );
      state.adminInternship = internship;
      state.adminJobs = jobs;
    },
    getAdminDetail: (state, action) => {},
    getAdminDetailSuccess: (state, action) => {
      state.adminDetail = action.payload;
    },
    getAllPositionsSuccess: (state, action) => {
      state.allPositions = action.payload;
      const internship = action.payload.filter(
        (position) => position.positionType === "internship"
      );
      const jobs = action.payload.filter(
        (position) => position.positionType !== "internship"
      );
      state.internships = internship;
      state.jobs = jobs;
    },
    getAllPositionsFail: (state, action) => {
      state.allPositionsError = action.payload;
    },

    getPosition: (state, action) => {
      state.positionID = action.payload;
    },
    getPositionSuccess: (state, action) => {
      state.position = action.payload;
    },
    getPositionFail: (state, action) => {
      state.positionError = action.payload;
    },

    createPosition: (staet, action) => {},
    createPositionSuccess: (staet, action) => {},
    createPositionFail: (staet, action) => {},

    applyPosition: (state, action) => {
      state.applyData = action.payload;
    },
    applyPositionFail: (state, action) => {
      state.applyPositionError = action.payload;
    },

    applyPositionSuccess: (state, action) => {
      state.applyPositionSuccess = true;
      state.applyPositionError = "";
    },

    applyPositionState: (state, action) => {
      state.applyPositionSuccess = false;
      state.applyPositionFail = "";
    },
    serverErrorFound: (state, action) => {
      state.serverError = true;
    },

    getAllQuestions: (state, action) => {},
    getAllQuestionsSuccess: (state, action) => {
      state.allQuestions = action.payload;
    },
    getAllQuestionsFail: (state, action) => {
      state.serverError = true;
    },
  },
});

export default positionSlice;
export const {
  getPosition,
  getPositionFail,
  getPositionSuccess,

  getAllPositions,
  getAllPositionsSuccess,
  getAllPositionsFail,

  createPosition,
  createPositionSuccess,
  createPositionFail,

  applyPosition,
  applyPositionSuccess,
  applyPositionFail,
  applyPositionState,

  serverErrorFound,

  getAllQuestions,
  getAllQuestionsSuccess,
  getAllQuestionsFail,

  getAllAdminPositions,
  getAllAdminPositionsSuccess,
  getAdminDetail,
  getAdminDetailSuccess,
} = positionSlice.actions;
