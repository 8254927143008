import React from "react";
import { useOutletContext } from "react-router-dom";

function Requirement() {
  const { requirements } = useOutletContext();

  return (
    <>
      {requirements && (
        <div
          className="h-auto mx-2 measures"
          style={{
            marginTop: "30px",

            borderRadius: "10px",
          }}
        >
          <ul className="px-4">
            {requirements.split("||").map((requirement, index) => {
              if (requirement.trim()) {
                return (
                  <li key={index} className="mb-2 tab-content">
                    {requirement}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default Requirement;
