import React from "react";
import { useNavigate } from "react-router-dom";

function DataNotFound({ color, path }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          marginTop: "130px",
          backgroundColor: `${color === "white" ? "white" : ""}`,
          paddingTop: "60px",
          paddingBottom: "60px",
          minWidth: "280px",
          height: "400px",
          gap: "20px",
        }}
        className="container  d-flex flex-column align-items-center justify-content-center"
      >
        <div className="row justify-content-center">
          <div className="col-12">
            <section style={{ fontSize: "20px" }} className="text-center w-100">
              The data you are looking for is not found!
            </section>
          </div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className="rounded pointer back-btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DataNotFound;
