import { TextField } from "@mui/material";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import axios from "axios";
import { useDispatch } from "react-redux";
import { removeToken } from "../redux/admin/adminRedux";

function HRquestions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    questions,
    data,
    setData,
    checked,
    Questions,
    setQuestions,
    addQuestion,
  } = useOutletContext();
  const bearerToken = localStorage.getItem("token");

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/questions/${id}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 200) {
        setData((prev) => {
          return {
            ...prev,
            questions: data.questions.filter((q) => q.id !== id),
          };
        });
      }
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };

  return (
    <>
      {checked ? (
        <>
          <div
            className={`d-flex ${
              addQuestion ? "justify-content-between" : "justify-content-end"
            } mb-2 align-items-center p-0`}
            style={{ gap: "10px", backgroundColor: "", height: "56px" }}
          >
            {/* {addQuestion && ( */}
            <div
              className="d-flex justify-content-between align-items-center mt-4"
              style={{ gap: "10px", backgroundColor: "", width: "100%" }}
            >
              <TextField
                id="outlined-textarea"
                fullWidth
                label="Question"
                required
                value={Questions.question}
                inputProps={{ maxLength: 1500 }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (!Questions.question.trim()) return;
                    const currentQuestions = [
                      Questions.question,
                      ...Questions.questions,
                    ];
                    setQuestions((prev) => {
                      return { questions: currentQuestions, question: "" };
                    });
                  }
                }}
                onChange={(e) =>
                  setQuestions((prev) => {
                    return { ...prev, question: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div>
            <div className="pt-4">
              {Questions &&
                Questions.questions &&
                Questions.questions.map((question, index) => {
                  return (
                    <section
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <TextField
                        id="outlined-textarea"
                        className="mb-2"
                        fullWidth
                        required
                        value={
                          Questions && Questions.questions
                            ? Questions.questions.find((q) => q === question)
                            : ""
                        }
                        onChange={(e) => {
                          // if (!e.target.value) {
                          //   return;
                          // }
                          const index = Questions.questions.findIndex(
                            (q) => q === question
                          );

                          const currentQuestions = Questions.questions.filter(
                            (q) => q !== question
                          );
                          currentQuestions.splice(index, 0, e.target.value);

                          setQuestions((prev) => {
                            return { ...prev, questions: currentQuestions };
                          });
                        }}
                      />
                      <section
                        style={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#168c9e",
                          border: "1px solid #168c9e",
                          borderRadius: "50%",
                          // color: "#168c9e",
                          color: "white",
                        }}
                        onClick={() => {
                          const currentQuestions = Questions.questions.filter(
                            (q) => q !== question
                          );
                          setQuestions((prev) => {
                            return { ...prev, questions: currentQuestions };
                          });
                        }}
                        className="d-flex justify-content-center align-items-center pointer mb-2"
                      >
                        <DeleteSharpIcon />
                      </section>
                    </section>
                  );
                })}
            </div>
            {data.questions &&
              data.questions.map((question, index) => {
                const q = data.questions.find((q) => q.id === question.id);

                return (
                  <section
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <TextField
                      id="outlined-textarea"
                      className="mb-2"
                      fullWidth
                      required
                      value={q.question}
                      onChange={(e) => {
                        const currentQuestion = data.questions.find(
                          (question) => question.id === q.id
                        );
                        const currentQuestions = data.questions.filter(
                          (q) => q.id !== question.id
                        );
                        currentQuestions.splice(index, 0, {
                          ...currentQuestion,
                          question: e.target.value,
                        });

                        setData((prev) => {
                          return { ...prev, questions: currentQuestions };
                        });
                      }}
                    />
                    <section
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#168c9e",
                        border: "1px solid #168c9e",
                        borderRadius: "50%",
                        // color: "#168c9e",
                        color: "white",
                      }}
                      onClick={() => handleDelete(q.id)}
                      className="d-flex justify-content-center align-items-center pointer mb-2"
                    >
                      <DeleteSharpIcon />
                    </section>
                  </section>
                );
              })}
          </div>
          {Questions &&
            Questions.questions &&
            data.questions &&
            data.questions.length === 0 &&
            Questions.questions.length === 0 && (
              <div className="d-flex flex-column align-items-center">
                <div
                  style={{
                    fontSize: "20px",
                    color: "black",
                    marginTop: "70px",
                  }}
                  className="mb-4"
                >
                  No Available Question Found.
                </div>
                <div>
                  To add questions, write your question on the input field
                  provided above and press Enter key.
                </div>
              </div>
            )}
        </>
      ) : (
        <>
          {questions.length > 0 && (
            <div
              className="h-auto p-4 measures"
              style={{
                borderRadius: "10px",
                textAlign: "start",
              }}
            >
              <ul className="px-4 mt-2">
                {questions.map((q, index) => (
                  <li key={index}>
                    <section
                      className="text-justify mb-3"
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                      {q.question}
                    </section>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {questions.length === 0 && (
            <div className="d-flex flex-column  align-items-center">
              <div
                style={{ fontSize: "20px", color: "black" }}
                className="mb-4 mt-5"
              >
                No Available Question Found.
              </div>
              <div>
                Add questions by clicking edit button on the top right corner.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HRquestions;
