import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPositions,
  getAllQuestions,
} from "./redux/position/positionRedux";
import Footer from "./components/Footer";
function Main() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllPositions());
    dispatch(getAllQuestions());
  }, [dispatch]);

  const { serverError } = useSelector((state) => state.position);

  return (
    <>
      {!serverError && <Header />}
      {serverError && (
        <section style={{ height: "100vh", margin: "auto" }}>
          <img
            src="https://user-images.githubusercontent.com/37357447/222389981-caef05f4-746b-44f5-9e6d-6b22f4d6245d.png"
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        </section>
      )}

      {!serverError && <Outlet />}
      {!serverError && <Footer />}
    </>
  );
}

export default Main;
