import React from "react";
import "../styles/mainstyles.css";
import SingleJob from "./SingleJob";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

function MainJob() {
  const { jobs } = useSelector((state) => state.position);

  return (
    <div
      style={{
        marginTop: "130px",
        backgroundColor: "#f8f9fa",
        paddingTop: "60px",
        paddingBottom: "60px",
        minHeight: "500px",
        minWidth: "284px",
      }}
    >
      <Helmet>
        <title>Jobs</title>
      </Helmet>
      {jobs && jobs.length === 0 && (
        <section
          style={{
            fontSize: "20px",
            fontFamily: "Raleway, sans-serif",

            padding: "120px 0px",
          }}
          className="w-100 m-auto  text-center"
        >
          <div className="container">
            <section className="mb-3">Thank you for visiting our page.</section>
            Unfortunately, we don’t have any open positions available at this
            time. Be sure to follow up on our website and social media pages for
            possible future openings.
          </div>
        </section>
      )}

      <section className="container  h-100" style={{ maxWidth: "1300px" }}>
        <div className="row  h-100">
          {jobs && jobs.length !== 0 && (
            <>
              <div className="col-lg-4 h-100 d-flex flex-column job-center mb-4">
                <h2
                  style={{
                    fontWeight: "bolder",
                    fontSize: "32px",
                    fontFamily: "Raleway, sans-serif",
                  }}
                >
                  JOBS
                </h2>
                <section className="job-line"></section>
              </div>
              <div className="col-lg-8">
                <section className="row">
                  {jobs
                    ?.filter((job) => job.status === "active")
                    .map((job) => {
                      return (
                        <SingleJob
                          key={job.id}
                          id={job.id}
                          title={job.title}
                          overview={job.overview}
                          type={job.positionType}
                          requirement={job.requirements}
                          responsibility={job.responsibility}
                          benefits={job.benefits}
                          avatar={job.avatar}
                        />
                      );
                    })}
                </section>
              </div>
            </>
          )}
        </div>
      </section>
      {!jobs && (
        <div
          style={{ height: "300px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-column align-items-center">
            <CircularProgress />
            <section className="mt-3" style={{ fontSize: "20px" }}>
              Loading jobs. Please wait.
            </section>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainJob;
