import React from "react";
import { useOutletContext } from "react-router-dom";

function Benefits() {
  const { benefits } = useOutletContext();
  return (
    <>
      {benefits && (
        <div
          className="h-auto  mx-2 measures"
          style={{
            marginTop: "30px",

            borderRadius: "10px",
          }}
        >
          <ul style={{ backgroundColor: "" }} className="px-4">
            {benefits.split("||").map((benefit, index) => {
              if (benefit.trim()) {
                return (
                  <li key={index} className="mb-2 tab-content">
                    {benefit}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </>
  );
}

export default Benefits;
