import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import { Button } from "antd";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import { TextField, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllAdminPositions,
  getAllQuestions,
} from "../../../redux/position/positionRedux";
import { Navigate, useNavigate } from "react-router-dom";

import { Checkbox } from "antd";
import ImageUpload from "./ImageUpload";
import { removeToken } from "../../../redux/admin/adminRedux";
import { Helmet } from "react-helmet";

const Detail = ({ data }) => {
  return (
    <>
      <div className="container">
        <div className="row mt-2 mb-3" style={{ minHeight: "48%" }}>
          <section className="col">
            <section
              className="mb-3"
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              Responsibility
            </section>
            <div
              className="mb-4 pointer px-4"
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "18px",
                userSelect: "none",
              }}
            >
              <ul>
                {data.responsibility.map((res, index) => (
                  <li key={index}>{res}</li>
                ))}
              </ul>
            </div>
          </section>
        </div>
        <div className="row mt-2 " style={{ minHeight: "48%" }}>
          <section className="col">
            <section
              className="mb-3"
              style={{ fontWeight: "bold", fontSize: "20px" }}
            >
              Requirement
            </section>
            <div
              className="mb-4 pointer px-4"
              style={{
                color: "black",
                fontWeight: "500",
                fontSize: "18px",
                userSelect: "none",
              }}
            >
              <ul>
                {data.requirement.map((req, index) => (
                  <li key={index}>{req}</li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
const More = ({ data, questions }) => {
  return (
    <>
      {data.benefits.length > 0 && (
        <div className="container">
          <div className="row mt-2 mb-3" style={{ minHeight: "300px" }}>
            <section className="col">
              <section
                className="mb-3"
                style={{ fontWeight: "bold", fontSize: "20px" }}
              >
                Benefits
              </section>
              <div
                className="mb-4 pointer px-4"
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "18px",
                  userSelect: "none",
                }}
              >
                {data.benefits.map((benefit, index) => (
                  <>
                    <ul>
                      <li className="mb-3" key={index}>
                        {benefit}
                      </li>
                    </ul>
                  </>
                ))}
              </div>
            </section>
          </div>
        </div>
      )}
      {questions.length > 0 && (
        <div className="container">
          <>
            <div className="row mt-2 ">
              <div
                className="col-12 mb-3"
                style={{
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Questions
              </div>
            </div>
            <div className="row">
              <div
                className="col-12 mb-4 pointer px-4"
                style={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "18px",
                  userSelect: "none",
                }}
              >
                <ul className="px-3">
                  {questions.map((question, index) => (
                    <li className="mb-3" key={index}>
                      {question}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};
const Overview = ({ data }) => {
  return (
    <>
      <div className="container">
        <div className="row py-2">
          <div
            className="col-8 mb-2 d-flex justify-content-between pr-5"
            style={{
              fontSize: "18px",
              flexWrap: "wrap",
            }}
          >
            <section>
              <span style={{ fontWeight: "bold" }}>Title -</span> {data.title}
            </section>
            {/* <div className="row mt-2"> */}
            {/* <div className="col-lg-3 justify-content-center align-items-center d-flex"> */}
            {data.image && (
              <div>
                <img
                  src={URL.createObjectURL(data.image)}
                  alt=""
                  style={{ width: "100%", height: "120px" }}
                />
              </div>
            )}
            {/* </div> */}
            {/* </div> */}
          </div>
          <div className="col-lg-6 mt-4" style={{ fontSize: "18px" }}>
            <span style={{ fontWeight: "bold" }}>Type - </span>
            {data.type}
          </div>
          <div className="col-lg-6 mt-4" style={{ fontSize: "18px" }}>
            {data.demo && "With Demonstration"}
            {!data.demo && "WithOut Demonstration"}
          </div>
        </div>
        <div className="row mt-4">
          <section className="col" style={{ minHeight: "250px" }}>
            <section
              style={{
                fontWeight: "bold",
                fontSize: "18px",
              }}
            >
              Overview
            </section>
            <div className="text-justify">{data.overview}</div>
          </section>
        </div>
      </div>
    </>
  );
};

const Summary = ({ data, questions }) => {
  const [active, setActive] = useState(1);

  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-3 text-center p-2 mr-2 pointer tab"
            style={{
              // border: "1px solid red",
              fontSize: "18px",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "6px",
              backgroundColor: `${active === 1 ? "#f5f5f5" : ""}`,
              // backgroundColor: "#f5f5f5",
            }}
            onClick={() => setActive(1)}
          >
            Overview
          </div>
          <div
            className="col-lg-3 text-center p-2 mr-2 pointer tab"
            style={{
              // border: "1px solid red",
              fontSize: "18px",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "6px",
              borderBottom: "none",
              backgroundColor: `${active === 2 ? "#f5f5f5" : ""}`,
            }}
            onClick={() => setActive(2)}
          >
            Detail
          </div>
          {(questions.length > 0 || data.benefits.length > 0) && (
            <div
              className="col-lg-3 text-center p-2 mr-2 pointer tab"
              style={{
                // border: "1px solid red",
                fontSize: "18px",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "6px",
                borderBottom: "none",
                backgroundColor: `${active === 3 ? "#f5f5f5" : ""}`,
              }}
              onClick={() => setActive(3)}
            >
              More
            </div>
          )}
        </div>
        <div
          className="row"
          style={{ backgroundColor: "#f5f5f5", minHeight: "430px" }}
        >
          {active === 1 && <Overview data={data} />}
          {active === 2 && <Detail data={data} />}
          {active === 3 && <More data={data} questions={questions} />}
        </div>
      </div>
    </>
  );
};

const PostingPage = () => {
  const { adminDetail } = useSelector((state) => state.position);
  if (!adminDetail?.superAdmin & !adminDetail?.canPost) {
    return <Navigate to="/admin" replace={true} />;
  }
  if (!adminDetail?.canPost) {
    return <Navigate to="/admin" replace={true} />;
  }
  return (
    <div style={{ minWidth: "790px" }}>
      <Checkout />
    </div>
  );
};

export default PostingPage;

const steps = [
  "Position Overview",
  "Position Details",
  "Questions",
  "Photo Upload",
  "Summary",
];

function getStepContent(
  step,
  setData,
  data,
  error,
  setError,
  questions,
  setQuestions
) {
  switch (step) {
    case 0:
      return (
        <>
          <div className="container-fluid" style={{ minHeight: "510px" }}>
            <div className="row">
              <div className="col">
                <div className="mb-2 row justify-content-center align-items-center">
                  <div className="col-md-6 mt-4">
                    <TextField
                      placeholder="Enter Position Title"
                      label="Position Title"
                      variant="outlined"
                      fullWidth
                      required
                      value={data.title}
                      inputProps={{ maxLength: 40 }}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, title: e.target.value };
                        })
                      }
                      error={error.title ? true : false}
                    />
                  </div>

                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                      <FormHelperText>Position Type</FormHelperText>
                      <Select
                        value={data.type}
                        displayEmpty
                        required
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) =>
                          setData((prev) => {
                            return { ...prev, type: e.target.value };
                          })
                        }
                        error={error.type ? true : false}
                      >
                        <MenuItem value={"internship"}>Internship</MenuItem>
                        <MenuItem value={"part-time"}>Part-Time</MenuItem>
                        <MenuItem value={"full-time"}>Full-Time</MenuItem>
                        <MenuItem value={"contractual"}>Contractual</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-3 d-flex align-items-center justify-content-center">
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                      <FormHelperText>Position Category</FormHelperText>
                      <Select
                        value={data.category}
                        displayEmpty
                        required
                        inputProps={{ "aria-label": "Without label" }}
                        error={error.category ? true : false}
                        onChange={(e) =>
                          setData((prev) => {
                            return { ...prev, category: e.target.value };
                          })
                        }
                      >
                        <MenuItem value={"onsite"}>Onsite</MenuItem>
                        <MenuItem value={"remote"}>Remote</MenuItem>
                        <MenuItem value={"hybrid"}>Hybrid</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="mb-2 row justify-content-center ">
                  <div className="col-md-12 mt-3 mb-3">
                    <TextField
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, overview: e.target.value };
                        })
                      }
                      inputProps={{ maxLength: 450 }}
                      value={data.overview}
                      label="Overview"
                      multiline
                      rows={4}
                      placeholder="Position Overview"
                      variant="outlined"
                      fullWidth
                      required
                      sx={{
                        "& textarea": {
                          backgroundColor: "#f8f9fa",

                          borderRadius: "4px",
                          padding: "8px",
                        },
                      }}
                      error={error.overview ? true : false}
                    />
                    <section
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        color: `${
                          data.overview.length === 450 ? "red" : "black"
                        }`,
                      }}
                      className="d-flex justify-content-end px-2"
                    >
                      {data.overview.length}/450
                    </section>
                  </div>
                  <div className="col-md-12 mb-3">
                    <TextField
                      value={data.benefit}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, benefit: e.target.value };
                        })
                      }
                      inputProps={{ maxLength: 1500 }}
                      label="Benefits"
                      placeholder="Position Benefits"
                      variant="outlined"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (!e.target.value.trim()) {
                            return;
                          }
                          const benefits = [e.target.value, ...data.benefits];
                          setData((prev) => {
                            return {
                              ...prev,
                              benefits: benefits,
                              benefit: "",
                            };
                          });
                        }
                      }}
                      sx={{
                        "& textarea": {
                          backgroundColor: "#f8f9fa",
                          borderRadius: "4px",
                          padding: "8px",
                        },
                      }}
                    />
                    <section
                      style={{
                        backgroundColor: "#F5F5F5",
                        overflowY: "auto",
                        height: "400px",
                      }}
                      className="d-flex px-5 py-3   mt-3"
                    >
                      <ul>
                        {data.benefits.map((benefit, index) => {
                          return (
                            <li
                              className="mb-4 pointer"
                              style={{
                                userSelect: "none",
                                fontWeight: "500",
                                color: "black",
                                fontSize: "18px",
                              }}
                              key={index}
                              onDoubleClick={() =>
                                setData((prev) => {
                                  const benefits = data.benefits.filter(
                                    (item) => item !== benefit
                                  );
                                  return {
                                    ...prev,
                                    benefits: benefits,
                                  };
                                })
                              }
                            >
                              {benefit}
                            </li>
                          );
                        })}
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div>
                  <Checkbox
                    onChange={(e) => {
                      setData((prev) => {
                        return { ...prev, demo: e.target.checked };
                      });
                    }}
                    checked={data.demo}
                    style={{ fontSize: "20px", color: "black" }}
                  >
                    Demo
                  </Checkbox>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case 1:
      return (
        <>
          <div className="container-fluid" style={{ minHeight: "510px" }}>
            <div className="row">
              <div className="col">
                <div className="mb-2 row justify-content-center ">
                  <div className="col-sm-12 mb-3">
                    <TextField
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, req: e.target.value };
                        })
                      }
                      value={data.req}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (!e.target.value.trim()) {
                            return;
                          }
                          const newRequirements = [
                            e.target.value,
                            ...data.requirement,
                          ];
                          setData((prev) => {
                            return {
                              ...prev,
                              requirement: newRequirements,
                              req: "",
                            };
                          });
                        }
                      }}
                      inputProps={{ maxLength: 1500 }}
                      label="Requirement"
                      // multiline
                      // rows={16}

                      placeholder="Position Requirement"
                      variant="outlined"
                      fullWidth
                      required
                      error={error.requirement ? true : false}
                      sx={{
                        "& textarea": {
                          backgroundColor: "#f8f9fa",

                          borderRadius: "4px",
                          padding: "8px",
                        },
                      }}
                    />
                    <section
                      style={{
                        // backgroundColor: "red",
                        height: "400px",
                        overflowY: "auto",
                        backgroundColor: "#F5F5F5",
                      }}
                      className="d-flex justify-content-start pl-3 mt-3"
                    >
                      <ul className="p-3">
                        {data.requirement.map((req, index) => {
                          return (
                            <li
                              className="mb-4 pointer"
                              style={{
                                userSelect: "none",
                                fontWeight: "500",
                                color: "black",
                                fontSize: "18px",
                              }}
                              key={index}
                              onDoubleClick={() =>
                                setData((prev) => {
                                  const newRequirement =
                                    data.requirement.filter(
                                      (item) => item !== req
                                    );
                                  return {
                                    ...prev,
                                    requirement: newRequirement,
                                  };
                                })
                              }
                            >
                              {req}
                            </li>
                          );
                        })}
                      </ul>
                    </section>
                  </div>
                  <div className="col-sm-12 mb-3">
                    <TextField
                      // value={data.responsibility}
                      onChange={(e) =>
                        setData((prev) => {
                          return { ...prev, res: e.target.value };
                        })
                      }
                      value={data.res}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          if (!e.target.value.trim()) {
                            return;
                          }
                          const newResponsibility = [
                            e.target.value,
                            ...data.responsibility,
                          ];
                          setData((prev) => {
                            return {
                              ...prev,
                              responsibility: newResponsibility,
                              res: "",
                            };
                          });
                        }
                      }}
                      label="Responsibility"
                      placeholder="Position Responsibility"
                      variant="outlined"
                      fullWidth
                      required
                      error={error.responsibility ? true : false}
                      inputProps={{ maxLength: 1500 }}
                      sx={{
                        "& textarea": {
                          backgroundColor: "#f8f9fa",

                          borderRadius: "4px",
                          padding: "8px",
                        },
                      }}
                    />
                    <section
                      className="d-flex justify-content-start pl-3 mt-3"
                      style={{
                        height: "400px",
                        overflowY: "auto",
                        backgroundColor: "#F5F5F5",
                      }}
                    >
                      <ul className="p-3">
                        {data.responsibility.map((res, index) => {
                          return (
                            <li
                              className="mb-4 pointer"
                              style={{
                                userSelect: "none",
                                fontWeight: "500",
                                color: "black",
                                fontSize: "18px",
                              }}
                              key={index}
                              onDoubleClick={() =>
                                setData((prev) => {
                                  const newResponsibility =
                                    data.responsibility.filter(
                                      (item) => item !== res
                                    );
                                  return {
                                    ...prev,
                                    responsibility: newResponsibility,
                                  };
                                })
                              }
                            >
                              {res}
                            </li>
                          );
                        })}
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <div className="container-fluid" style={{ height: "510px" }}>
            <div className="row">
              <div
                className="col-md-12 col-sm-6 d-flex justify-content-center"
                style={{ gap: "20px" }}
              >
                <TextField
                  id="outlined-textarea"
                  label="Questions"
                  placeholder="Position Questions"
                  fullWidth
                  required
                  inputProps={{ maxLength: 1500 }}
                  value={data.question}
                  onChange={(e) => {
                    setData((prev) => {
                      return { ...prev, question: e.target.value };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!data.question.trim()) {
                        return;
                      }
                      const currentQuestions = [data.question, ...questions];
                      setQuestions(currentQuestions);
                      setData((prev) => {
                        return { ...prev, question: "" };
                      });
                    }
                  }}
                />
              </div>
              <div
                className="my-3 w-100 px-4 py-2 rounded"
                style={{
                  // minHeight: "300px",
                  backgroundColor: "#F5F5F5",
                  // maxHeight: "430px",
                  height: "430px",
                  overflowY: "scroll",
                }}
              >
                <ul>
                  {questions.map((question, index) => (
                    <>
                      <li
                        key={index}
                        className=" p-2  pointer"
                        onDoubleClick={() => {
                          var currentQuestions = [...questions];
                          currentQuestions = currentQuestions.filter(
                            (q) => q !== question
                          );

                          setQuestions(currentQuestions);
                        }}
                        style={{
                          userSelect: "none",
                          color: "black",
                          fontWeight: "500",
                          fontSize: "18px",
                          // color: "black",
                          // border: "1px solid #168c9e",
                        }}
                      >
                        {/* {questions.indexOf(question) + 1}. */}
                        <section className="ml-1">{question}</section>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <div className="container-fluid" style={{ height: "510px" }}>
          <ImageUpload setData={setData} data={data} uploadError={error} />
        </div>
      );
    case 4:
      return (
        <div style={{ minHeight: "510px" }}>
          Summary
          <Summary data={data} questions={questions} />
        </div>
      );
    default:
      throw new Error("Unknown step");
  }
}

const defaultTheme = createTheme();

export function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState({
    title: "",
    type: "",
    category: "",
    overview: "",
    requirement: "",
    responsibility: "",
    image: "",
  });
  const bearerToken = localStorage.getItem("token");

  const [data, setData] = useState({
    title: "",
    type: "",
    category: "",
    overview: "",
    benefits: [],
    benefit: "",
    req: "",
    res: "",
    requirement: [],
    responsibility: [],
    question: "",
    demo: false,
    image: null,
  });

  const [applyResponse, setApplyResponse] = useState({
    error: "",
    success: false,
  });

  const handleNext = () => {
    const {
      title,
      type,
      overview,
      requirement,
      responsibility,
      image,
      category,
    } = data;

    if (
      activeStep === 0 &&
      title.trim() &&
      type.trim() &&
      overview.trim() &&
      category.trim()
    ) {
      if (title.trim()) {
        setError((prev) => {
          return { ...prev, title: "" };
        });
      }
      if (overview.trim()) {
        setError((prev) => {
          return { ...prev, overview: "" };
        });
      }
      if (type.trim()) {
        setError((prev) => {
          return { ...prev, type: "" };
        });
      }
      if (category.trim()) {
        setError((prev) => {
          return { ...prev, category: "" };
        });
      }

      setActiveStep(activeStep + 1);
    } else if (
      activeStep === 0 &&
      (!title.trim() || !type.trim() || !overview.trim() || !category.trim())
    ) {
      if (title.trim()) {
        setError((prev) => {
          return { ...prev, title: "" };
        });
      }
      if (overview.trim()) {
        setError((prev) => {
          return { ...prev, overview: "" };
        });
      }
      if (type.trim()) {
        setError((prev) => {
          return { ...prev, type: "" };
        });
      }
      if (category.trim()) {
        setError((prev) => {
          return { ...prev, category: "" };
        });
      }
      if (!title.trim()) {
        setError((prev) => {
          return { ...prev, title: "Position title is Required!" };
        });
      }
      if (!type.trim()) {
        setError((prev) => {
          return { ...prev, type: "Position type is Required!" };
        });
      }
      if (!overview.trim()) {
        setError((prev) => {
          return { ...prev, overview: "Position overview is Required!" };
        });
      }
      if (!category.trim()) {
        setError((prev) => {
          return { ...prev, category: "Position category is Required!" };
        });
      }
      if (title.trim() && type.trim() && overview.trim() && category.trim()) {
        setError((prev) => {
          return {
            ...prev,
            title: "",
            type: "",
            overview: "",
            category: "",
          };
        });
      }
    } else if (
      activeStep === 1 &&
      requirement.length === 0 &&
      responsibility.length === 0
    ) {
      if (requirement.length === 0) {
        setError((prev) => {
          return { ...prev, requirement: "required" };
        });
      }
      if (responsibility.length === 0) {
        setError((prev) => {
          return { ...prev, responsibility: "required" };
        });
      }
      // setActiveStep(activeStep + 1);
    } else if (
      activeStep === 1 &&
      (requirement.length !== 0 || responsibility.length !== 0)
    ) {
      if (requirement.length === 0) {
        setError((prev) => {
          return { ...prev, requirement: "required" };
        });
      }
      if (responsibility.length === 0) {
        setError((prev) => {
          return { ...prev, responsibility: "required" };
        });
      }
      if (requirement.length !== 0) {
        setError((prev) => {
          return { ...prev, requirement: "" };
        });
      }
      if (responsibility.length !== 0) {
        setError((prev) => {
          return {
            ...prev,
            responsibility: "",
          };
        });
      }

      if (responsibility.length !== 0 && requirement.length !== 0) {
        setError((prev) => {
          return {
            ...prev,
            requirement: "",
            responsibility: "",
          };
        });
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      setActiveStep(activeStep + 1);
    } else if (activeStep === 3 && !image) {
      setError((prev) => {
        return { ...prev, image: "Image is required" };
      });
    } else if (activeStep === 3 && image) {
      setError({
        title: "",
        type: "",
        category: "",
        overview: "",
        requirement: "",
        responsibility: "",
        image: "",
      });
      setActiveStep(activeStep + 1);
    }
  };

  const handlePost = async (dispatch) => {
    const requirement = data.requirement.join("||");
    const benefits = data.benefits.join("||");
    const responsibility = data.responsibility.join("||");
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("positionType", data.type);
    formData.append("positionCategory", data.category);
    formData.append("overview", data.overview);
    formData.append("responsibility", responsibility);
    formData.append("requirements", requirement);
    formData.append("benefits", benefits);
    formData.append("skipDemo", data.demo ? false : true);
    formData.append("avatar", data.image);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/positions`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 201) {
        if (questions.length !== 0) {
          const tempQuestions = questions.map((question) => {
            return {
              question: question,
              choices: [],
              positionId: response.data.id,
            };
          });
          try {
            await axios.post(
              `${process.env.REACT_APP_URL}/questions/add-questions`,
              {
                questions: tempQuestions,
              },
              {
                headers: {
                  Authorization: `Bearer ${bearerToken}`,
                },
              }
            );
          } catch (error) {
            if (error.response && error.response.status === 401) {
              dispatch(removeToken());
              localStorage.removeItem("token");
              localStorage.removeItem("email");

              navigate("/login");
            }
          }
        }
        dispatch(getAllQuestions());
        dispatch(getAllAdminPositions());
        setActiveStep(activeStep + 1);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else if (err.response && err.status === 400) {
        setApplyResponse({
          error: err.response.data.message[0],
          success: false,
        });
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/admin");
    } else {
      if (activeStep === 1) {
        setError((prev) => {
          return { ...prev, requirement: "", responsibility: "" };
        });
      } else if (activeStep === 3) {
        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
      setActiveStep(activeStep - 1);
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <Helmet>
        <title>Posting Page</title>
      </Helmet>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Toolbar></Toolbar>
        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography component="h1" variant="h4" align="center">
              Position Posting
            </Typography>

            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  You have successfully Posted a position({data.title}) at iCog
                  ACC
                </Typography>
                <Typography variant="subtitle1">
                  Posted positions are available for users
                </Typography>

                <Button
                  onClick={() => {
                    setData({
                      title: "",
                      type: "",
                      category: "",
                      overview: "",
                      benefits: [],
                      benefit: "",
                      req: "",
                      res: "",
                      requirement: [],
                      responsibility: [],
                      question: "",
                      demo: false,
                      image: null,
                    });
                    setQuestions([]);

                    setActiveStep(0);
                  }}
                  variant="contained"
                  sx={{ mt: 3, ml: 1 }}
                >
                  Done
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(
                  activeStep,
                  setData,
                  data,
                  error,
                  setError,
                  questions,
                  setQuestions
                )}
                <Box sx={{ display: "flex", justifyContent: "between" }}>
                  <div className="container mt-3">
                    <div className="row d-flex justify-content-end">
                      <div className="col-lg-3 d-flex justify-content-end">
                        <section>
                          <Button
                            onClick={handleBack}
                            sx={{ mt: 3, ml: 1 }}
                            className="mr-3"
                          >
                            Back
                          </Button>

                          {activeStep === steps.length - 1 ? (
                            <Button
                              variant="contained"
                              onClick={() => handlePost(dispatch)}
                              sx={{ mt: 3, ml: 1 }}
                            >
                              Post
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 3, ml: 1 }}
                            >
                              Next
                            </Button>
                          )}
                        </section>
                      </div>
                    </div>
                  </div>
                </Box>
              </React.Fragment>
            )}
          </Paper>
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
