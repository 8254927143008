import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    token: localStorage.getItem("token"),
    isLogged: localStorage.getItem("token") ? true : false,
    adminsList: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      state.isLogged = true;
    },
    removeToken: (state, action) => {
      localStorage.removeItem("token");
      state.token = null;
      state.isLogged = false;
    },
    getAdmins: (state, action) => {},
    getAdminsSuccess: (state, action) => {
      state.adminsList = action.payload;
    },
    removeAdmin: (state, action) => {
      state.adminsList = state.adminsList.filter(
        (admin) => admin.id !== action.payload
      );
    },
    addAdmin: (state, action) => {
      state.adminsList = [...state.adminsList, action.payload];
    },
    updateAdminList: (state, action) => {
      const { id } = action.payload;

      const newList = state.adminsList.map((admin) => {
        if (admin.id === id) {
          return action.payload;
        }
        return admin;
      });

      state.adminsList = newList;
    },
    setAdminList: (state, action) => {
      state.adminsList = action.payload;
    },
  },
});

export default adminSlice;
export const {
  removeToken,
  setToken,
  getAdmins,
  getAdminsSuccess,
  removeAdmin,
  addAdmin,
  updateAdminList,
  setAdminList,
} = adminSlice.actions;
