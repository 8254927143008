import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { Button } from "antd";

const ImageUpload = ({ data, setData, uploadError }) => {
  const [error, setError] = useState(null);
  return (
    <div className="d-flex justify-content-center h-100 align-items-center">
      {!data.image && (
        <section className="d-flex flex-column align-items-center">
          <input
            type="file"
            name="imageFile"
            id="imageFile"
            style={{ display: "none" }}
            onChange={(e) => {
              const fileType = ["image/png", "image/jpeg"];

              if (e.target.files[0]) {
                if (!fileType.includes(e.target.files[0].type)) {
                  setError("Only png and jpeg images are allowed");
                  setData((prev) => {
                    return {
                      ...prev,
                      image: null,
                    };
                  });
                } else {
                  setData((prev) => {
                    return { ...prev, image: e.target.files[0] };
                  });
                  setError(null);
                }
              } else {
                setData((prev) => {
                  return {
                    ...prev,
                    image: null,
                  };
                });
                setError("This is a required filed");
              }
            }}
          />
          <label
            htmlFor="imageFile"
            style={{ border: `${uploadError.image && "1px solid red"}` }}
            className="d-flex  justify-content-center align-items-center  pointer image-upload"
          >
            <AddIcon /> <span style={{ color: "black" }}> Image</span>
          </label>
          <section style={{ height: "30px", color: "red" }}>{error}</section>
        </section>
      )}
      {data.image && (
        <div>
          <section
            style={{ width: "200px", height: "200px" }}
            className="img-container"
          >
            <img
              src={URL.createObjectURL(data.image)}
              alt={data.title}
              style={{ width: "100%", height: "100%" }}
            />
          </section>
          <section className="d-flex justify-content-center">
            <Button
              onClick={() =>
                setData((prev) => {
                  return {
                    ...prev,
                    image: null,
                  };
                })
              }
              sx={{ mt: 3, ml: 1 }}
              className="mt-3"
            >
              Cancel
            </Button>
          </section>
        </div>
      )}
    </div>
  );
};
export default ImageUpload;
