import React from "react";
import { Alert, AlertTitle } from "@mui/material";

function DeleteError({ setDeleteResponse }) {
  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          top: "0",
          bottom: "0",
          right: "0",
          left: "0",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          zIndex: "1300",
        }}
      >
        <section className="container w-75">
          <div className="row justify-content-center ">
            <div className="col-md-5 col-sm-10">
              <Alert
                severity="error"
                style={{
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  fontSize: "16px",
                }}
                className="d-flex justify-content-center p-2"
              >
                <section>
                  <AlertTitle>Failed</AlertTitle>

                  <strong>An Error has ocurred. Please try again!</strong>
                </section>
                <section className="d-flex justify-content-center mt-4">
                  <button
                    onClick={() => setDeleteResponse(false)}
                    relative="path"
                    className=" py-2 px-3 d-flex justify-content-center align-items-center rounded pointer"
                    style={{
                      border: "none",
                      fontWeight: "600",
                      letterSpacing: "2px",
                      outline: "none",
                      textDecoration: "none",
                      color: "black",
                      backgroundColor: "white",
                    }}
                  >
                    Try again
                  </button>
                </section>
              </Alert>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DeleteError;
