// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  display: flex;
  background-color: #f8f9fa;
  min-height: 100vh;
  /* width: "" */
  /* background-color: red; */

  margin-top: 130px;
}

.edu-exp-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* background-color: rgba(0, 0, 0, 0.5); */

  /* justify-content: space-between; */
}

.education {
  max-width: 190px;
}

.experience {
  max-width: 170px;
}
@media (min-width: 550px) and (max-width: 991px) {
  .edu-exp-container {
    gap: 20px;
    justify-content: flex-start;
  }
}

.upload {
  font-size: 15px;
  /* font-family: sans-serif; */
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/applyForm/styles/form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;EACd,2BAA2B;;EAE3B,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,0CAA0C;;EAE1C,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,SAAS;IACT,2BAA2B;EAC7B;AACF;;AAEA;EACE,eAAe;EACf,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".form-container {\n  display: flex;\n  background-color: #f8f9fa;\n  min-height: 100vh;\n  /* width: \"\" */\n  /* background-color: red; */\n\n  margin-top: 130px;\n}\n\n.edu-exp-container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 10px;\n  /* background-color: rgba(0, 0, 0, 0.5); */\n\n  /* justify-content: space-between; */\n}\n\n.education {\n  max-width: 190px;\n}\n\n.experience {\n  max-width: 170px;\n}\n@media (min-width: 550px) and (max-width: 991px) {\n  .edu-exp-container {\n    gap: 20px;\n    justify-content: flex-start;\n  }\n}\n\n.upload {\n  font-size: 15px;\n  /* font-family: sans-serif; */\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
