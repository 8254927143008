// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.measures {
  font-size: 18px;
  line-height: 1.6;
  color: black;
  padding: 20px;
}

.position-title {
  font-size: 32px;
}
.position-type {
  font-size: 25px;
}

@media (max-width: 350px) {
  .measures {
    font-size: 13px;
    padding: 10px;
  }
}
@media (max-width: 450px) {
  .position-title {
    font-size: 28px;
  }

  .position-type {
    font-size: 22px;
  }
}
.pointer {
  cursor: pointer;
}

.header-link {
  font-size: 16px;
  color: black;
  font-family: sans-serif;
}

.header-tab-link {
  text-decoration: none;
}
.header-tab-link:hover {
  color: #168c9e;
  transition: all linear 0.2s;
}
.header-link:hover {
  color: #168c9e;
  transition: all linear 0.2s;
}

.career-dropdown {
  position: absolute;
  left: 0px;
  padding: 20px 20px;
  background-color: white;

  box-shadow: 1px 0px 14px -5px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 1px 0px 14px -5px rgba(0, 0, 0, 1);
  -moz-box-shadow: 1px 0px 14px -5px rgba(0, 0, 0, 1);
  width: 200px;
  height: 100px;
}

section.career-link:hover ul.career-dropdown {
  background-color: red;
  width: 400px;
}

.login-btn {
  width: 50%;
  background-color: #168c9e;
}

.fuzz {
  margin-top: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;IACf,aAAa;EACf;AACF;AACA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF;AACA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,2BAA2B;AAC7B;AACA;EACE,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,kBAAkB;EAClB,uBAAuB;;EAEvB,8CAA8C;EAC9C,sDAAsD;EACtD,mDAAmD;EACnD,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".measures {\n  font-size: 18px;\n  line-height: 1.6;\n  color: black;\n  padding: 20px;\n}\n\n.position-title {\n  font-size: 32px;\n}\n.position-type {\n  font-size: 25px;\n}\n\n@media (max-width: 350px) {\n  .measures {\n    font-size: 13px;\n    padding: 10px;\n  }\n}\n@media (max-width: 450px) {\n  .position-title {\n    font-size: 28px;\n  }\n\n  .position-type {\n    font-size: 22px;\n  }\n}\n.pointer {\n  cursor: pointer;\n}\n\n.header-link {\n  font-size: 16px;\n  color: black;\n  font-family: sans-serif;\n}\n\n.header-tab-link {\n  text-decoration: none;\n}\n.header-tab-link:hover {\n  color: #168c9e;\n  transition: all linear 0.2s;\n}\n.header-link:hover {\n  color: #168c9e;\n  transition: all linear 0.2s;\n}\n\n.career-dropdown {\n  position: absolute;\n  left: 0px;\n  padding: 20px 20px;\n  background-color: white;\n\n  box-shadow: 1px 0px 14px -5px rgba(0, 0, 0, 1);\n  -webkit-box-shadow: 1px 0px 14px -5px rgba(0, 0, 0, 1);\n  -moz-box-shadow: 1px 0px 14px -5px rgba(0, 0, 0, 1);\n  width: 200px;\n  height: 100px;\n}\n\nsection.career-link:hover ul.career-dropdown {\n  background-color: red;\n  width: 400px;\n}\n\n.login-btn {\n  width: 50%;\n  background-color: #168c9e;\n}\n\n.fuzz {\n  margin-top: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
