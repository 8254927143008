import React from "react";
import Stages from "./Stages";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DataNotFound from "../../../components/DataNotFound";
function StagesMain() {
  const { id } = useParams();

  const { allPositions } = useSelector((state) => state.position);
  const position = allPositions
    ? allPositions.find((position) => position.id === id)
      ? allPositions.find((position) => position.id === id)
      : null
    : [];
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div style={{ minWidth: "800px" }}>
      {position ? (
        <>
          <Stages position={position} searchParams={searchParams} />
          <Outlet context={[searchParams, setSearchParams]} />
        </>
      ) : (
        <>
          <DataNotFound />
        </>
      )}
    </div>
  );
}

export default StagesMain;
