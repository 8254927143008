// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shade {
  background-color: white;

  transition: all ease-in-out 0.4s;
}
.black-white {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.hr-black-white {
  filter: grayscale(100%);
  transition: all ease-in-out 0.2s;
}

.hr-position {
}
.hr-position:hover .hr-black-white {
  filter: none;
}
.shade:hover .black-white {
  filter: none;
}
.shade:hover {
  box-shadow: "0px 0px 30px rgba(12, 133, 151, 0.25)";
}

.job-content {
  text-align: center;
}

.job-line {
  width: 50px;
  height: 3px;
  background-color: #168c9e;
}

@media (max-width: 991px) {
  .job-center {
    align-items: center;
  }

  .job-line {
    width: 80px;
  }
}

@media (max-width: 290px) {
  .job-center {
    margin-left: 35px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/jobs/styles/mainstyles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;;EAEvB,gCAAgC;AAClC;AACA;EACE,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;AACA;EACE,uBAAuB;EACvB,gCAAgC;AAClC;;AAEA;AACA;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,mDAAmD;AACrD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".shade {\n  background-color: white;\n\n  transition: all ease-in-out 0.4s;\n}\n.black-white {\n  width: 100%;\n  height: 100%;\n  filter: grayscale(100%);\n}\n.hr-black-white {\n  filter: grayscale(100%);\n  transition: all ease-in-out 0.2s;\n}\n\n.hr-position {\n}\n.hr-position:hover .hr-black-white {\n  filter: none;\n}\n.shade:hover .black-white {\n  filter: none;\n}\n.shade:hover {\n  box-shadow: \"0px 0px 30px rgba(12, 133, 151, 0.25)\";\n}\n\n.job-content {\n  text-align: center;\n}\n\n.job-line {\n  width: 50px;\n  height: 3px;\n  background-color: #168c9e;\n}\n\n@media (max-width: 991px) {\n  .job-center {\n    align-items: center;\n  }\n\n  .job-line {\n    width: 80px;\n  }\n}\n\n@media (max-width: 290px) {\n  .job-center {\n    margin-left: 35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
