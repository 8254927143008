import React, { useEffect, useState } from "react";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import CollapsibleTable from "./CandidatesTable";
import { useDispatch, useSelector } from "react-redux";
import { getPositionCandidates } from "../../../redux/candidate/candidateRedux";
import { SelectLabels } from "./JobCandidates";
import "../styles/hr.css";
import DataNotFound from "../../../components/DataNotFound";
import { Helmet } from "react-helmet";
function InternshipCandidates({ stage }) {
  const { positionCandidates } = useSelector((state) => state.candidate);
  const { adminInternship } = useSelector((state) => state.position);
  const internships = adminInternship;
  const [searchParams, setSearchParams] = useOutletContext();
  const location = useLocation();
  const path = location.pathname.split("/");

  localStorage.setItem("path", `/${path[1]}/${path[2]}/${path[3]}`);
  var candidates = [];
  if ((stage !== "all") & (stage !== "potential")) {
    candidates = positionCandidates
      ? positionCandidates.filter(
          (candidate) =>
            (candidate.applicationStatus === stage) &
            (candidate.visibility === "visible")
        )
      : [];
  } else {
    if (stage === "all") {
      candidates = positionCandidates ? positionCandidates : [];
    }
    if (stage === "potential") {
      candidates = positionCandidates
        ? positionCandidates.filter(
            (candidate) => candidate.visibility === "potential"
          )
        : [];
    }
  }

  const dispatch = useDispatch();
  const { id } = useParams();
  const [ids, setIds] = useState([]);

  const internship = internships
    ? internships.find((internship) => internship.id === id)
      ? internships.find((internship) => internship.id === id)
      : null
    : {};

  const handleSearch = () => {
    var result;
    if (searchParams.has("age")) {
      result = candidates.filter((row) => row.age === searchParams.get("age"));
    }

    if (searchParams.has("experience")) {
      if (searchParams.has("age")) {
        result = result.filter(
          (row) => row.yearsOfExperience === searchParams.get("experience")
        );
      } else {
        result = candidates.filter(
          (row) => row.yearsOfExperience === searchParams.get("experience")
        );
      }
    }
    if (searchParams.has("education")) {
      if (searchParams.has("age") || searchParams.has("experience")) {
        result = result.filter(
          (row) => row.educationLevel === searchParams.get("education")
        );
      } else {
        result = candidates.filter(
          (row) => row.educationLevel === searchParams.get("education")
        );
      }
    }

    if (searchParams.has("sex")) {
      if (
        searchParams.has("age") ||
        searchParams.has("experience") ||
        searchParams.has("education")
      ) {
        result = result.filter((row) => row.sex === searchParams.get("sex"));
      } else {
        result = candidates.filter(
          (row) => row.sex === searchParams.get("sex")
        );
      }
    }

    if (searchParams.has("email")) {
      if (
        searchParams.has("age") ||
        searchParams.has("education") ||
        searchParams.has("experience")
      ) {
        result = result.filter((row) =>
          row.email
            .toLowerCase()
            .includes(searchParams.get("email").toLowerCase())
        );
      } else {
        result = candidates.filter((row) =>
          row.email
            .toLowerCase()
            .includes(searchParams.get("email").toLowerCase())
        );
      }
    }

    if (
      !searchParams.get("age") &&
      !searchParams.get("education") &&
      !searchParams.get("experience") &&
      !searchParams.get("email") &&
      !searchParams.get("sex")
    ) {
      result = candidates;
    }
    return result;
  };

  useEffect(() => {
    dispatch(getPositionCandidates(id));
  }, [dispatch, id]);

  return (
    <div style={{ minWidth: "790px" }}>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <section style={{ margin: "0px 10px" }}>
        {internship ? (
          <>
            {" "}
            <SelectLabels
              searchParams={searchParams}
              handleSearch={handleSearch}
              candidates={candidates}
              setSearchParams={setSearchParams}
              ids={ids}
              setIds={setIds}
            />
            <CollapsibleTable
              searchParams={searchParams}
              candidates={candidates}
              handleSearch={handleSearch}
              position={internship}
              ids={ids}
              setIds={setIds}
              dispatch={dispatch}
              stage={stage}
              positionCandidates={positionCandidates}
            />
          </>
        ) : (
          <>
            <DataNotFound />
          </>
        )}
      </section>
    </div>
  );
}

export default InternshipCandidates;
