import { TextField } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";

function HRrequirement() {
  const { data, setData, checked } = useOutletContext();

  return (
    <>
      <>
        {checked ? (
          <div style={{ marginTop: "30px" }}>
            <TextField
              value={data.req}
              onChange={(e) =>
                setData((prev) => {
                  return { ...prev, req: e.target.value };
                })
              }
              onKeyDown={(e) => {
                if (!e.target.value.trim()) {
                  return;
                }
                if (e.key === "Enter") {
                  const requirements = data.requirements.split("||");
                  const newRequirements = [e.target.value, ...requirements];

                  setData((prev) => {
                    return {
                      ...prev,
                      requirements: newRequirements.join("||"),
                      req: "",
                    };
                  });
                }
              }}
              inputProps={{ maxLength: 1500 }}
              label="Requirement"
              placeholder="Position Requirement"
              variant="outlined"
              fullWidth
              required
              sx={{
                "& textarea": {
                  borderRadius: "10px",
                  padding: "8px",
                },
              }}
            />

            <div
              className="h-auto p-4 "
              style={{
                borderRadius: "10px",

                textAlign: "justify",
              }}
            >
              <ul className="px-4">
                {data.requirements &&
                  data.requirements.split("||").map((req, index) => {
                    if (req.trim()) {
                      return (
                        <li
                          key={index}
                          className="mb-3   pointer"
                          onDoubleClick={() => {
                            var currentRequirements =
                              data.requirements.split("||");
                            currentRequirements = currentRequirements.filter(
                              (item) => item !== req
                            );

                            setData((prev) => {
                              return {
                                ...prev,
                                requirements: currentRequirements.join("||"),
                              };
                            });
                          }}
                          style={{
                            userSelect: "none",
                            fontWeight: "500",
                            color: "black",
                            fontSize: "18px",
                          }}
                        >
                          <section className="ml-1">{req}</section>
                        </li>
                      );
                    }
                    return null;
                  })}
              </ul>
            </div>
            {!data.requirements && (
              <div className="d-flex flex-column  align-items-center">
                <div
                  style={{ fontSize: "20px", color: "black" }}
                  className="mb-4 mt-4"
                >
                  No available requirements Found.
                </div>
                <div>
                  To add requirements, write on the input provided above and
                  press Enter key.
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {data.requirements && data.requirements.trim() && (
              <div
                className="h-auto  measures"
                style={{
                  borderRadius: "10px",

                  textAlign: "justify",
                }}
              >
                <ul className="px-4">
                  {data.requirements.split("||").map((req, index) => {
                    if (req.trim()) {
                      return (
                        <li
                          key={index}
                          className="mb-3 mt-3  pointer"
                          style={{
                            fontWeight: "500",
                            color: "black",
                            fontSize: "18px",
                          }}
                        >
                          <section
                            className="ml-1"
                            // style={{ fontFamily: "sans-serif" }}
                          >
                            {req}
                          </section>
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              </div>
            )}
          </>
        )}
      </>
    </>
  );
}

export default HRrequirement;
