import Main from "./Main";
import HRmain from "./pages/HR/HRmain";
import HRjobDetail from "./pages/HR/components/HRjobDetail";
import HRJobs from "./pages/HR/components/HRJobs";

import JobDetail from "./pages/jobDetail/JobDetail";
import Jobs from "./pages/jobs/Jobs";
import Error from "./pages/HR/components/ErrorPage";
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useOutletContext,
  Navigate,
} from "react-router-dom";
import Internships from "./pages/Internships/Internships";
import InternshipDetail from "./pages/internshipDetail/InternshipDetail";
import HRInternships from "./pages/HR/components/HRInternships";
import HRInternshipDetail from "./pages/HR/components/HRInternshipDetail";
import JobCandidates from "./pages/HR/components/JobCandidates";
import PostingPage from "./pages/HR/components/PostingPage";
import InternshipCandidates from "./pages/HR/components/InternshipCandidates";
import CandidatesList from "./pages/HR/components/CandidatesList";
import Requirement from "./components/Requirement";
import Responsibility from "./components/Responsibility";
import Benefits from "./components/Benefits";
import Questions from "./components/Questions";
import HRrequirement from "./components/HRrequirement";
import HRresponsibility from "./components/HRresponsibility";
import CandidateDetail from "./pages/HR/components/CandidateDetail";

import StagesMain from "./pages/HR/components/StagesMain";
import HRbenefits from "./components/HRbenefits";
import HRquestions from "./components/HRquestions";
import ApplyForm from "./pages/applyForm/components/ApplyForm";
import ApplyMain from "./pages/applyForm/ApplyMain";
import QuestionsForm from "./pages/applyForm/components/QuestionsForm";

import LoginMain from "./pages/HR/components/Login";
import { useSelector } from "react-redux";
import ChangePassword from "./pages/HR/components/ChangePassword";
import AddAccount from "./pages/HR/components/AddAccount";
import AdminsList from "./pages/HR/components/AdminsList";
import AdminDetail from "./pages/HR/components/AdminDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="*" element={<Error />} />
          <Route index element={<Jobs />} />
          <Route path="jobs/:id" element={<JobDetail />}>
            <Route index element={<Responsibility />} />
            <Route path="requirement" element={<Requirement />} />
            <Route path="benefits" element={<Benefits />} />
            <Route path="questions" element={<Questions />} />
          </Route>
          <Route path="internships" element={<Internships />} />
          <Route path="internships/:id" element={<InternshipDetail />}>
            <Route index element={<Responsibility />} />
            <Route path="requirement" element={<Requirement />} />
            <Route path="benefits" element={<Benefits />} />
            <Route path="questions" element={<Questions />} />
          </Route>
          <Route path="form/:id" element={<ApplyMain />}>
            <Route index element={<ApplyForm />} />
            <Route path="questions" element={<QuestionAuth />}>
              <Route index element={<QuestionsForm />} />
            </Route>
          </Route>
        </Route>

        <Route path="/admin" element={<HRmain />}>
          <Route index element={<HRJobs />} />
          <Route path="list" element={<AdminsList />} />
          <Route path="list/:id" element={<AdminDetail />} />
          <Route path="jobs/:id" element={<HRjobDetail />}>
            <Route index element={<HRrequirement />} />
            <Route path="responsibility" element={<HRresponsibility />} />
            <Route path="benefits" element={<HRbenefits />} />
            <Route path="questions" element={<HRquestions />} />
          </Route>
          <Route path="internships" element={<HRInternships />} />
          <Route path="internships/:id" element={<HRInternshipDetail />}>
            <Route index element={<HRrequirement />} />
            <Route path="responsibility" element={<HRresponsibility />} />
            <Route path="benefits" element={<HRbenefits />} />
            <Route path="questions" element={<HRquestions />} />
          </Route>
          <Route path="jobcandidates/:id" element={<StagesMain />}>
            <Route index element={<JobCandidates stage={"all"} />} />
            <Route
              path="just-applied"
              element={<JobCandidates stage={"just-applied"} />}
            />
            <Route
              path="exam"
              element={<JobCandidates stage={"passed-for-exam"} />}
            />
            <Route
              path="interview"
              element={<JobCandidates stage={"passed-for-interview"} />}
            />
            <Route
              path="demonstration"
              element={<JobCandidates stage={"passed-for-demonstration"} />}
            />
            <Route
              path="onboarding"
              element={<JobCandidates stage={"onboarding"} />}
            />
            <Route
              path="potential"
              element={<JobCandidates stage={"potential"} />}
            />
          </Route>

          <Route path="internshipcandidates/:id" element={<StagesMain />}>
            <Route index element={<InternshipCandidates stage={"all"} />} />
            <Route
              path="just-applied"
              element={<InternshipCandidates stage={"just-applied"} />}
            />
            <Route
              path="exam"
              element={<InternshipCandidates stage={"passed-for-exam"} />}
            />
            <Route
              path="interview"
              element={<InternshipCandidates stage={"passed-for-interview"} />}
            />
            <Route
              path="demonstration"
              element={
                <InternshipCandidates stage={"passed-for-demonstration"} />
              }
            />
            <Route
              path="onboarding"
              element={<InternshipCandidates stage={"onboarding"} />}
            />
            <Route
              path="potential"
              element={<InternshipCandidates stage={"potential"} />}
            />
          </Route>
          <Route path="posting" element={<PostingPage />} />
          <Route path="candidatelist" element={<CandidatesList />} />
          <Route path="candidate/:id" element={<CandidateDetail />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="addaccount" element={<AddAccount />} />
        </Route>
        <Route path="/login" element={<AuthenticationAuth />}>
          <Route index element={<LoginMain />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

const QuestionAuth = () => {
  const context = useOutletContext();
  const {
    address,
    age,
    applicationDescription,
    educationLevel,
    email,
    firstName,
    lastName,
    phoneNumber,
    sex,
    yearsOfExperience,
  } = context.data;

  const truth =
    !address ||
    !age ||
    !applicationDescription ||
    !educationLevel ||
    !email ||
    !firstName ||
    !lastName ||
    !phoneNumber ||
    !sex ||
    !yearsOfExperience;

  if (truth) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <>
      <Outlet context={context} />
    </>
  );
};

const AuthenticationAuth = () => {
  const { isLogged } = useSelector((state) => state.admin);

  if (isLogged) {
    return <Navigate to="/admin" replace={true} />;
  }
  return (
    <>
      <Outlet />
    </>
  );
};
