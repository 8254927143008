import MainJob from "./components/MainJob";
function Jobs() {
  return (
    <>
      <MainJob />
    </>
  );
}

export default Jobs;
