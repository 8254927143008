import { TextField } from "@mui/material";
import React from "react";
import { useOutletContext } from "react-router-dom";

function HRresponsibility() {
  const { data, setData, checked } = useOutletContext();

  return (
    <>
      {checked ? (
        <div style={{ marginTop: "30px" }}>
          <TextField
            value={data.res}
            onChange={(e) =>
              setData((prev) => {
                return { ...prev, res: e.target.value };
              })
            }
            onKeyDown={(e) => {
              if (!e.target.value.trim()) {
                return;
              }
              if (e.key === "Enter") {
                const responsibility = data.responsibility.split("||");
                const newResponsibility = [e.target.value, ...responsibility];

                setData((prev) => {
                  return {
                    ...prev,
                    responsibility: newResponsibility.join("||"),
                    res: "",
                  };
                });
              }
            }}
            inputProps={{ maxLength: 1500 }}
            label="Responsibility"
            placeholder="Position Responsibility"
            variant="outlined"
            fullWidth
            required
            sx={{
              "& textarea": {
                borderRadius: "10px",
                padding: "8px",
              },
            }}
          />

          <div
            className="h-auto p-4 "
            style={{
              borderRadius: "10px",

              textAlign: "justify",
            }}
          >
            <ul className="px-4">
              {data.responsibility &&
                data.responsibility.split("||").map((res, index) => {
                  if (res.trim()) {
                    return (
                      <li
                        key={index}
                        className="mb-3   pointer"
                        onDoubleClick={() => {
                          var currentResponsibility =
                            data.responsibility.split("||");
                          currentResponsibility = currentResponsibility.filter(
                            (item) => item !== res
                          );

                          setData((prev) => {
                            return {
                              ...prev,
                              responsibility: currentResponsibility.join("||"),
                            };
                          });
                        }}
                        style={{
                          userSelect: "none",

                          fontWeight: "500",
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        <section className="ml-1">{res}</section>
                      </li>
                    );
                  }

                  return null;
                })}
            </ul>
          </div>
          {!data.responsibility && (
            <div className="d-flex flex-column  align-items-center">
              <div
                style={{ fontSize: "20px", color: "black" }}
                className="mb-4 mt-4"
              >
                No Available responsibility Found.
              </div>
              <div>
                To add responsibility, write on the input provided above and
                press Enter key.
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {data.responsibility && data.responsibility.trim() && (
            <div
              className="h-auto measures"
              style={{
                borderRadius: "10px",

                textAlign: "justify",
              }}
            >
              <ul className="px-4">
                {data.responsibility.split("||").map((res, index) => {
                  if (res.trim()) {
                    return (
                      <li
                        key={index}
                        className="mb-3 mt-3  pointer"
                        style={{
                          fontWeight: "500",
                          color: "black",
                          fontSize: "18px",
                        }}
                      >
                        <section className="ml-1">{res}</section>
                      </li>
                    );
                  }

                  return null;
                })}
              </ul>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HRresponsibility;
