import { put, takeEvery, call, select } from "@redux-saga/core/effects";
import axios from "axios";
import {
  getAllCandidatesFail,
  getAllCandidatesSuccess,
  getPositionCandidatesSuccess,
} from "../redux/candidate/candidateRedux";
import {
  applyPositionFail,
  applyPositionSuccess,
  getAdminDetailSuccess,
  getAllAdminPositionsSuccess,
  getAllPositionsSuccess,
  getAllQuestionsSuccess,
  getPositionSuccess,
} from "../redux/position/positionRedux";
import { getAdminsSuccess, removeToken } from "../redux/admin/adminRedux";

// CANDIDATES

function* getAllCandidatesRequest() {
  const token = localStorage.getItem("token");

  try {
    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/candidates`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );

    if (response.status === 200) {
      yield put(getAllCandidatesSuccess(response.data));
    }
  } catch (err) {
    if (err.response.status === 401) {
      yield put(removeToken());
      localStorage.removeItem("token");
      localStorage.removeItem("email");

      // return redirect("/login");
    }
    yield put(getAllCandidatesFail("Unable to get data"));
  }
}
export function* getAllCandidatesSaga() {
  yield takeEvery("candidate/getAllCandidates", getAllCandidatesRequest);
}

function* getPositionCandidatesRequest() {
  const { positionID } = yield select((state) => state.candidate);
  const token = localStorage.getItem("token");

  try {
    const response = yield call(() =>
      axios.get(
        `${process.env.REACT_APP_URL}/positions/candidates/${positionID}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );

    if (response.status === 200) {
      yield put(getPositionCandidatesSuccess(response.data));
    }
  } catch (err) {
    if (err.response.status === 401) {
      yield put(removeToken());
      localStorage.removeItem("token");
      localStorage.removeItem("email");

      // return redirect("/login");
    } else if (err.response.status === 404) {
      yield put(getPositionCandidatesSuccess([]));
    }
  }
}
export function* getPositionCandidatesSaga() {
  yield takeEvery(
    "candidate/getPositionCandidates",
    getPositionCandidatesRequest
  );
}
// CANDIDATES

// POSITIONS
function* getAllPositionsRequest() {
  try {
    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/positions`)
    );

    if (response.status === 200) {
      yield put(getAllPositionsSuccess(response.data));
    }
  } catch (err) {}
}
export function* getAllPositionsSaga() {
  yield takeEvery("position/getAllPositions", getAllPositionsRequest);
}
function* getAllAdminPositionsRequest() {
  try {
    const token = localStorage.getItem("token");

    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/positions/admin`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    if (response.status === 200) {
      yield put(getAllAdminPositionsSuccess(response.data));
    }
  } catch (err) {
    if (err.response.status === 401) {
      yield put(removeToken());
      localStorage.removeItem("token");
      localStorage.removeItem("email");
    }
  }
}
export function* getAllAdminPositionsSaga() {
  yield takeEvery("position/getAllAdminPositions", getAllAdminPositionsRequest);
}

function* getAdminDetailRequest() {
  try {
    const token = localStorage.getItem("token");

    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/auth/admin-detail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );

    if (response.status === 200) {
      yield put(getAdminDetailSuccess(response.data));
    }
  } catch (err) {
    if (err.response.status === 401) {
      yield put(removeToken());
      localStorage.removeItem("token");
      localStorage.removeItem("email");
    }
  }
}
export function* getAdminDetailSaga() {
  yield takeEvery("position/getAdminDetail", getAdminDetailRequest);
}

function* getPositionRequest() {
  try {
    const { positionID } = yield select((state) => state.position);
    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/positions/${positionID}`)
    );
    if (response.status === 200) {
      yield put(getPositionSuccess(response.data));
    }
  } catch (err) {}
}
export function* getPositionSaga() {
  yield takeEvery("position/getPosition", getPositionRequest);
}

function* createPositionRequest() {
  const token = localStorage.getItem("token");

  try {
    yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/positions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
  } catch (err) {
    if (err.response.status === 401) {
      yield put(removeToken());
      localStorage.removeItem("token");
      localStorage.removeItem("email");

      // return redirect("/login");
    }
  }
}
export function* createPositionSaga() {
  yield takeEvery("position/createPosition", createPositionRequest);
}

function* applyPositionRequest() {
  const { applyData } = yield select((state) => state.position);

  try {
    const response = yield call(() =>
      axios.post(`${process.env.REACT_APP_URL}/candidates/apply`, applyData)
    );

    if (response.status === 201) {
      yield put(applyPositionSuccess(true));
    }
  } catch (err) {
    yield put(applyPositionFail(err.response.data.message));
  }
}
export function* applyPositionSaga() {
  yield takeEvery("position/applyPosition", applyPositionRequest);
}

function* getAllQuestionsRequest() {
  try {
    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/questions`)
    );

    if (response.status === 200) {
      yield put(getAllQuestionsSuccess(response.data));
    }
  } catch (err) {}
}
export function* getAllQuestionsSaga() {
  yield takeEvery("position/getAllQuestions", getAllQuestionsRequest);
}

function* getAllAdminsRequest() {
  const token = localStorage.getItem("token");
  try {
    const response = yield call(() =>
      axios.get(`${process.env.REACT_APP_URL}/auth/admins`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );

    yield put(getAdminsSuccess(response.data));
  } catch (err) {}
}
export function* getAllAdminsSaga() {
  yield takeEvery("admin/getAdmins", getAllAdminsRequest);
}

// POSITIONS
